/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import ReactLoading from 'react-loading';
import { useNavigate, useParams } from 'react-router-dom';

import { DiagnosisDto } from '../../../api/models/DiagnosisDto';
import { getDiagnosis } from '../../../api/services/diagnosis';
import manBack from '../../../assets/images/pics/diagnoza/man_back.svg';
import manFront from '../../../assets/images/pics/diagnoza/man_front.svg';
import normy_bmi from '../../../assets/images/pics/diagnoza/normy_bmi.jpg';
import normy_cisnienie from '../../../assets/images/pics/diagnoza/normy_cisnienie.jpg';
import normy_dusznosc from '../../../assets/images/pics/diagnoza/normy_dusznosc.jpg';
import normy_serce from '../../../assets/images/pics/diagnoza/normy_serce.jpg';
import normy_tetna from '../../../assets/images/pics/diagnoza/normy_tetna.jpg';
// import normy_WHR from '../../../assets/images/pics/diagnoza/normy_WHR.jpg';
import normy_WHtR from '../../../assets/images/pics/diagnoza/normy_WHtR.jpg';
import { Path } from '../../../router/paths';
import globalStyles from '../../../styles/global.module.scss';
import {
  dyspnoeaAnswer,
  functionAnswer,
  heartAnswer,
  sexAnswer,
} from '../../../utils/answerIdToText';
import {
  calculateBmi,
  checkBmi,
  checkDiastolicPressure,
  checkPulse,
  checkSystolicPressure,
  // checkWhr,
  checkWhtr,
} from '../../../utils/checkNorms';
import { padTo2Digits } from '../../../utils/padTo2Digits';
import parseStringToFloat from '../../../utils/parseStringToFloat';
import { FormikPart } from '../../Diagnosis/DiagnosisQuestions/DiagnosisQuestions.types';
import styles from './PatientAnswers.module.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const PatientAnswers = () => {
  const navigate = useNavigate();

  const [open, openModal] = useState(false);
  const [currentPart, setCurrentPart] = useState<FormikPart>();

  // interface IDictionary {
  //   [index: string]: IDictionary1;
  // }
  interface IDictionary1 {
    [index: string]: string;
  }
  // const pain = {} as IDictionary;
  const painClass = {} as IDictionary1;
  painClass.head = ' ';
  painClass.neck = ' ';
  painClass.chest = ' ';
  painClass.belly = ' ';
  painClass.pelvis = ' ';
  painClass.leftHand = ' ';
  painClass.rightHand = ' ';
  painClass.leftArm = ' ';
  painClass.rightArm = ' ';
  painClass.headBack = ' ';

  painClass.leftFoot = ' ';
  painClass.rightFoot = ' ';
  painClass.lumbar = ' ';
  painClass.thoracic = ' ';

  painClass.leftPeace = ' ';
  painClass.rightPeace = ' ';

  painClass.leftThigh = ' ';
  painClass.rightThigh = ' ';

  painClass.leftTibia = ' ';
  painClass.rightTibia = ' ';

  const { id, status } = useParams();
  const { isLoading, error, data } = useQuery<DiagnosisDto, AxiosError>({
    queryKey: ['diagnosis', id],
    // @ts-ignore
    queryFn: () => getDiagnosis(id).then((res) => res.data),
    enabled: !!id,
  });

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '100px auto',
        }}
      >
        <ReactLoading type="spin" color="#76446B" height={100} width={100} />
      </div>
    );
  }

  if (error) {
    return <div>Wystąpił błąd... {error.message} </div>;
  }

  const checkPatientBmi = checkBmi(
    calculateBmi(
      parseStringToFloat(`${data.weight}`),
      parseStringToFloat(`${data.height}`)
    )
  );

  const checkPatientPulse = checkPulse(
    parseStringToFloat(`${data.pulse}`),
    Number(data.age),
    Number(data.sex)
  );

  const checkPatientSystolicPressure = checkSystolicPressure(
    parseStringToFloat(`${data.systolicPressure}`)
  );

  const checkPatientDiastolicPressure = checkDiastolicPressure(
    parseStringToFloat(`${data.diastolicPressure}`)
  );

  const checkPatientWhtr = checkWhtr(
    parseStringToFloat(`${data.waist}`) / Number(data.height),
    data.sex
  );

  // const checkPatientWhr = checkWhr(
  //   Number(data.waist),
  //   Number(data.hip),
  //   data.sex
  // );

  const openDiagnosisModal = (activePart: string, parts: FormikPart) => {
    console.log('parts', parts);
    setCurrentPart(parts);
    openModal(true);
  };

  const closeDiagnosisModal = () => {
    openModal(false);
  };

  const newDate = new Date(data.create_time);
  console.log('d', data);
  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      {status && (
        <div className={styles.linkBox}>
          <div
            className={styles.link}
            onClick={() => navigate(`${Path.PATIENT_PROGRESS_STATS}/${status}`)}
          >
            Powrót do {status}
          </div>
        </div>
      )}

      <div className={styles.unique}>{data.unique_id}</div>
      <div className={styles.filledBy}>
        Wypełniona przez: {data.filledBy === 1 ? 'Pacjent' : 'Fizjoterapeuta'}
      </div>
      <div className={styles.date}>{`${newDate.getDate()}-${padTo2Digits(
        newDate.getMonth() + 1
      )}-${newDate.getFullYear()}`}</div>

      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.name}>Płeć</div>
          <div className={styles.data}>{sexAnswer(data.sex)}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Wiek</div>
          <div className={styles.data}>{data.age}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Masa ciała</div>
          <div className={styles.data}>{data.weight} kg</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Wysokość ciała</div>
          <div className={styles.data}>{data.height} cm</div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Obwód talii</div>
          <div className={styles.data}>{data.waist} cm</div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.name}>Obwód bioder</div>
          <div className={styles.data}>{data.hip} cm</div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.name}>Tętno spoczynkowe</div>
          <div
            className={`${styles.data} ${
              checkPatientPulse.result === 'good' ? styles.dataGood : ''
            } ${checkPatientPulse.result === 'bad' ? styles.dataBad : ''} ${
              checkPatientPulse.result === 'average' ? styles.dataAverage : ''
            }`}
          >
            {data.pulse} ({checkPatientPulse.name})
          </div>
          <div className={styles.norms}>
            <a href={normy_tetna} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Ciśnienie skurczowe</div>
          <div
            className={`${styles.data} ${
              checkPatientSystolicPressure.result === 'good'
                ? styles.dataGood
                : ''
            } ${
              checkPatientSystolicPressure.result === 'bad'
                ? styles.dataBad
                : ''
            } ${
              checkPatientSystolicPressure.result === 'average'
                ? styles.dataAverage
                : ''
            }`}
          >
            {data.systolicPressure} ({checkPatientSystolicPressure.name})
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_cisnienie} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Ciśnienie rozkurczowe</div>
          <div
            className={`${styles.data} ${
              checkPatientDiastolicPressure.result === 'good'
                ? styles.dataGood
                : ''
            } ${
              checkPatientDiastolicPressure.result === 'bad'
                ? styles.dataBad
                : ''
            } ${
              checkPatientDiastolicPressure.result === 'average'
                ? styles.dataAverage
                : ''
            }`}
          >
            {data.diastolicPressure} ({checkPatientDiastolicPressure.name})
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_cisnienie} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>BMI</div>
          <div
            className={`${styles.data} ${
              checkPatientBmi.result === 'good' ? styles.dataGood : ''
            } ${checkPatientBmi.result === 'bad' ? styles.dataBad : ''} ${
              checkPatientBmi.result === 'average' ? styles.dataAverage : ''
            }`}
          >
            {calculateBmi(Number(data.weight), Number(data.height)).toFixed(2)}{' '}
            ({checkPatientBmi.name})
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_bmi} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.name}>WHtR</div>
          <div
            className={`${styles.data} ${
              checkPatientWhtr.result === 'good' ? styles.dataGood : ''
            } ${checkPatientWhtr.result === 'bad' ? styles.dataBad : ''} ${
              checkPatientWhtr.result === 'average' ? styles.dataAverage : ''
            }`}
          >
            {(Number(data.waist) / Number(data.height)).toFixed(2)} (
            {checkPatientWhtr.name})
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_WHtR} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.name}>WHR</div>
          <div
            className={`${styles.data} ${
              checkPatientWhr.name === 'good' ? styles.dataGood : ''
            } ${checkPatientWhr.name === 'bad' ? styles.dataBad : ''} ${
              checkPatientWhr.name === 'average' ? styles.dataAverage : ''
            }`}
          >
            {(data.waist / data.hip).toFixed(1)} ({checkPatientWhr.name})
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_WHR} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.name}>Serce</div>
          <div
            className={`${styles.data} ${
              data.heart === 1 ? styles.dataGood : ''
            } ${data.heart === 2 ? styles.dataAverage : ''} ${
              data.heart === 3 || data.heart === 4 ? styles.dataBad : ''
            }`}
          >
            {heartAnswer(data.heart)}
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_serce} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>{' '}
        </div>
        <div className={styles.row}>
          <div className={styles.name}>Duszność</div>
          <div
            className={`${styles.data} ${
              data.dyspnoea === 1 ? styles.dataGood : ''
            } ${
              data.dyspnoea === 2 || data.dyspnoea === 3
                ? styles.dataAverage
                : ''
            } ${
              data.dyspnoea === 4 || data.dyspnoea === 5 || data.dyspnoea === 6
                ? styles.dataBad
                : ''
            }`}
          >
            {dyspnoeaAnswer(data.dyspnoea)}
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_dusznosc} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div>
        {/* <div className={styles.row}>
          <div className={styles.name}>Wydolność</div>
          <div
            className={`${styles.data} ${
              data.efficiency < 10 ? styles.dataGood : ''
            } ${
              data.efficiency > 10 && data.efficiency <= 19
                ? styles.dataAverage
                : ''
            } ${data.efficiency > 19 ? styles.dataBad : ''}`}
          >
            {data.efficiency} sekund
          </div>
          <div className={styles.norms}>
            {' '}
            <a href={normy_wydolnosc} target="_blank" rel="noreferrer">
              Zobacz normy
            </a>
          </div>
        </div> */}
        <div className={styles.row}>
          <div className={styles.name}>Funkcja</div>
          <div
            className={`${styles.data} ${
              data.functionality === 1 ? styles.dataGood : ''
            } ${data.functionality === 2 ? styles.dataAverage : ''} ${
              data.functionality === 3 || data.functionality === 4
                ? styles.dataBad
                : ''
            }`}
          >
            {functionAnswer(data.functionality)}
          </div>
          <div className={styles.norms} />
        </div>
        <div className={styles.last} />

        <div>
          <div className={styles.diagnosisPain}>
            <div className={styles.diagnosisPain__man}>
              <img src={manFront} alt="man_front" />
              <div className={styles.diagnosisPain__man_bg}>
                <div
                  id="head"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_head + painClass.head
                  }${
                    data.painIntensityHead > 0 ||
                    data.painTimeHead > 0 ||
                    data.painTime2Head > 0 ||
                    data.painMedicinesHead > 0 ||
                    data.painLimitsHead > 0 ||
                    data.painLengthHead > 0 ||
                    data.painTypeHead > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityHead > 0 ||
                      data.painTimeHead > 0 ||
                      data.painTime2Head > 0 ||
                      data.painMedicinesHead > 0 ||
                      data.painLimitsHead > 0 ||
                      data.painLengthHead > 0 ||
                      data.painTypeHead > 0
                    ) {
                      openDiagnosisModal('head', {
                        painIntensity: 'painIntensityHead',
                        painTime: 'painTimeHead',
                        painTime2: 'painTime2Head',
                        painMedicines: 'painMedicinesHead',
                        painLimits: 'painLimitsHead',
                        painLength: 'painLengthHead',
                        painType: 'painTypeHead',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="neck"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_neck + painClass.neck
                  }${
                    data.painIntensityNeck > 0 ||
                    data.painTimeNeck > 0 ||
                    data.painTime2Neck > 0 ||
                    data.painMedicinesNeck > 0 ||
                    data.painLimitsNeck > 0 ||
                    data.painLengthNeck > 0 ||
                    data.painTypeNeck > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityNeck > 0 ||
                      data.painTimeNeck > 0 ||
                      data.painTime2Neck > 0 ||
                      data.painMedicinesNeck > 0 ||
                      data.painLimitsNeck > 0 ||
                      data.painLengthNeck > 0 ||
                      data.painTypeNeck > 0
                    ) {
                      openDiagnosisModal('nest', {
                        painIntensity: 'painIntensityNeck',
                        painTime: 'painTimeNeck',
                        painTime2: 'painTime2Neck',
                        painMedicines: 'painMedicinesNeck',
                        painLimits: 'painLimitsNeck',
                        painLength: 'painLengthNeck',
                        painType: 'painTypeNeck',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
                <div
                  id="chest"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_chest + painClass.chest
                  }${
                    data.painIntensityChest > 0 ||
                    data.painTimeChest > 0 ||
                    data.painTime2Chest > 0 ||
                    data.painMedicinesChest > 0 ||
                    data.painLimitsChest > 0 ||
                    data.painLengthChest > 0 ||
                    data.painTypeChest > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityChest > 0 ||
                      data.painTimeChest > 0 ||
                      data.painTime2Chest > 0 ||
                      data.painMedicinesChest > 0 ||
                      data.painLimitsChest > 0 ||
                      data.painLengthChest > 0 ||
                      data.painTypeChest > 0
                    ) {
                      openDiagnosisModal('chest', {
                        painIntensity: 'painIntensityChest',
                        painTime: 'painTimeChest',
                        painTime2: 'painTime2Chest',
                        painMedicines: 'painMedicinesChest',
                        painLimits: 'painLimitsChest',
                        painLength: 'painLengthChest',
                        painType: 'painTypeChest',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
                <div
                  id="belly"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_belly + painClass.belly
                  }${
                    data.painIntensityBelly > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (data.painIntensityBelly > 0) {
                      openDiagnosisModal('belly', {
                        painIntensity: 'painIntensityBelly',
                        painTime: 'painTimeBelly',
                        painTime2: 'painTime2Belly',
                        painMedicines: 'painMedicinesBelly',
                        painLimits: 'painLimitsBelly',
                        painLength: 'painLengthBelly',
                        painType: 'painTypeBelly',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="leftArm"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftArm + painClass.leftArm
                  }${
                    data.painIntensityLeftArm > 0 ||
                    data.painTimeLeftArm > 0 ||
                    data.painTime2LeftArm > 0 ||
                    data.painMedicinesLeftArm > 0 ||
                    data.painLimitsLeftArm > 0 ||
                    data.painLengthLeftArm > 0 ||
                    data.painTypeLeftArm > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftArm > 0 ||
                      data.painTimeLeftArm > 0 ||
                      data.painTime2LeftArm > 0 ||
                      data.painMedicinesLeftArm > 0 ||
                      data.painLimitsLeftArm > 0 ||
                      data.painLengthLeftArm > 0 ||
                      data.painTypeLeftArm > 0
                    ) {
                      openDiagnosisModal('leftArm', {
                        painIntensity: 'painIntensityLeftArm',
                        painTime: 'painTimeLeftArm',
                        painTime2: 'painTime2LeftArm',
                        painMedicines: 'painMedicinesLeftArm',
                        painLimits: 'painLimitsLeftArm',
                        painLength: 'painLengthLeftArm',
                        painType: 'painTypeLeftArm',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="rightArm"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightArm + painClass.rightArm
                  }${
                    data.painIntensityRightArm > 0 ||
                    data.painTimeRightArm > 0 ||
                    data.painTime2RightArm > 0 ||
                    data.painMedicinesRightArm > 0 ||
                    data.painLimitsRightArm > 0 ||
                    data.painLengthRightArm > 0 ||
                    data.painTypeRightArm > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightArm > 0 ||
                      data.painTimeRightArm > 0 ||
                      data.painTime2RightArm > 0 ||
                      data.painMedicinesRightArm > 0 ||
                      data.painLimitsRightArm > 0 ||
                      data.painLengthRightArm > 0 ||
                      data.painTypeRightArm > 0
                    ) {
                      openDiagnosisModal('rightArm', {
                        painIntensity: 'painIntensityRightArm',
                        painTime: 'painTimeRightArm',
                        painTime2: 'painTime2RightArm',
                        painMedicines: 'painMedicinesRightArm',
                        painLimits: 'painLimitsRightArm',
                        painLength: 'painLengthRightArm',
                        painType: 'painTypeRightArm',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="leftHand"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftHand + painClass.leftHand
                  }${
                    data.painIntensityLeftHand > 0 ||
                    data.painTimeLeftHand > 0 ||
                    data.painTime2LeftHand > 0 ||
                    data.painMedicinesLeftHand > 0 ||
                    data.painLimitsLeftHand > 0 ||
                    data.painLengthLeftHand > 0 ||
                    data.painTypeLeftHand > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftHand > 0 ||
                      data.painTimeLeftHand > 0 ||
                      data.painTime2LeftHand > 0 ||
                      data.painMedicinesLeftHand > 0 ||
                      data.painLimitsLeftHand > 0 ||
                      data.painLengthLeftHand > 0 ||
                      data.painTypeLeftHand > 0
                    ) {
                      openDiagnosisModal('leftHand', {
                        painIntensity: 'painIntensityLeftHand',
                        painTime: 'painTimeLeftHand',
                        painTime2: 'painTime2LeftHand',
                        painMedicines: 'painMedicinesLeftHand',
                        painLimits: 'painLimitsLeftHand',
                        painLength: 'painLengthLeftHand',
                        painType: 'painTypeLeftHand',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="rightHand"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightHand + painClass.rightHand
                  }${
                    data.painIntensityRightHand > 0 ||
                    data.painTimeRightHand > 0 ||
                    data.painTime2RightHand > 0 ||
                    data.painMedicinesRightHand > 0 ||
                    data.painLimitsRightHand > 0 ||
                    data.painLengthRightHand > 0 ||
                    data.painTypeRightHand > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightHand > 0 ||
                      data.painTimeRightHand > 0 ||
                      data.painTime2RightHand > 0 ||
                      data.painMedicinesRightHand > 0 ||
                      data.painLimitsRightHand > 0 ||
                      data.painLengthRightHand > 0 ||
                      data.painTypeRightHand > 0
                    ) {
                      openDiagnosisModal('rightHand', {
                        painIntensity: 'painIntensityRightHand',
                        painTime: 'painTimeRightHand',
                        painTime2: 'painTime2RightHand',
                        painMedicines: 'painMedicinesRightHand',
                        painLimits: 'painLimitsRightHand',
                        painLength: 'painLengthRightHand',
                        painType: 'painTypeRightHand',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="pelvis"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_pelvis + painClass.pelvis
                  }${
                    data.painIntensityPelvis > 0 ||
                    data.painTimePelvis > 0 ||
                    data.painTime2Pelvis > 0 ||
                    data.painMedicinesPelvis > 0 ||
                    data.painLimitsPelvis > 0 ||
                    data.painLengthPelvis > 0 ||
                    data.painTypePelvis > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityPelvis > 0 ||
                      data.painTimePelvis > 0 ||
                      data.painTime2Pelvis > 0 ||
                      data.painMedicinesPelvis > 0 ||
                      data.painLimitsPelvis > 0 ||
                      data.painLengthPelvis > 0 ||
                      data.painTypePelvis > 0
                    ) {
                      openDiagnosisModal('pelvis', {
                        painIntensity: 'painIntensityPelvis',
                        painTime: 'painTimePelvis',
                        painTime2: 'painTime2Pelvis',
                        painMedicines: 'painMedicinesPelvis',
                        painLimits: 'painLimitsPelvis',
                        painLength: 'painLengthPelvis',
                        painType: 'painTypePelvis',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="leftTibia"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftTibia + painClass.leftTibia
                  }${
                    data.painIntensityLeftTibia > 0 ||
                    data.painTimeLeftTibia > 0 ||
                    data.painTime2LeftTibia > 0 ||
                    data.painMedicinesLeftTibia > 0 ||
                    data.painLimitsLeftTibia > 0 ||
                    data.painLengthLeftTibia > 0 ||
                    data.painTypeLeftTibia > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftTibia > 0 ||
                      data.painTimeLeftTibia > 0 ||
                      data.painTime2LeftTibia > 0 ||
                      data.painMedicinesLeftTibia > 0 ||
                      data.painLimitsLeftTibia > 0 ||
                      data.painLengthLeftTibia > 0 ||
                      data.painTypeLeftTibia > 0
                    ) {
                      openDiagnosisModal('leftTibia', {
                        painIntensity: 'painIntensityLeftTibia',
                        painTime: 'painTimeLeftTibia',
                        painTime2: 'painTime2LeftTibia',
                        painMedicines: 'painMedicinesLeftTibia',
                        painLimits: 'painLimitsLeftTibia',
                        painLength: 'painLengthLeftTibia',
                        painType: 'painTypeLeftTibia',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="rightTibia"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightTibia +
                    painClass.rightTibia
                  }${
                    data.painIntensityRightTibia > 0 ||
                    data.painTimeRightTibia > 0 ||
                    data.painTime2RightTibia > 0 ||
                    data.painMedicinesRightTibia > 0 ||
                    data.painLimitsRightTibia > 0 ||
                    data.painLengthRightTibia > 0 ||
                    data.painTypeRightTibia > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightTibia > 0 ||
                      data.painTimeRightTibia > 0 ||
                      data.painTime2RightTibia > 0 ||
                      data.painMedicinesRightTibia > 0 ||
                      data.painLimitsRightTibia > 0 ||
                      data.painLengthRightTibia > 0 ||
                      data.painTypeRightTibia > 0
                    ) {
                      openDiagnosisModal('rightTibia', {
                        painIntensity: 'painIntensityRightTibia',
                        painTime: 'painTimeRightTibia',
                        painTime2: 'painTime2RightTibia',
                        painMedicines: 'painMedicinesRightTibia',
                        painLimits: 'painLimitsRightTibia',
                        painLength: 'painLengthRightTibia',
                        painType: 'painTypeRightTibia',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="leftThigh"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftThigh + painClass.leftThigh
                  }${
                    data.painIntensityLeftThigh > 0 ||
                    data.painTimeLeftThigh > 0 ||
                    data.painTime2LeftThigh > 0 ||
                    data.painMedicinesLeftThigh > 0 ||
                    data.painLimitsLeftThigh > 0 ||
                    data.painLengthLeftThigh > 0 ||
                    data.painTypeLeftThigh > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftThigh > 0 ||
                      data.painTimeLeftThigh > 0 ||
                      data.painTime2LeftThigh > 0 ||
                      data.painMedicinesLeftThigh > 0 ||
                      data.painLimitsLeftThigh > 0 ||
                      data.painLengthLeftThigh > 0 ||
                      data.painTypeLeftThigh > 0
                    ) {
                      openDiagnosisModal('leftThigh', {
                        painIntensity: 'painIntensityLeftThigh',
                        painTime: 'painTimeLeftThigh',
                        painTime2: 'painTime2LeftThigh',
                        painMedicines: 'painMedicinesLeftThigh',
                        painLimits: 'painLimitsLeftThigh',
                        painLength: 'painLengthLeftThigh',
                        painType: 'painTypeLeftThigh',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="rightThigh"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightThigh +
                    painClass.rightThigh
                  }${
                    data.painIntensityRightThigh > 0 ||
                    data.painTimeRightThigh > 0 ||
                    data.painTime2RightThigh > 0 ||
                    data.painMedicinesRightThigh > 0 ||
                    data.painLimitsRightThigh > 0 ||
                    data.painLengthRightThigh > 0 ||
                    data.painTypeRightThigh > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightThigh > 0 ||
                      data.painTimeRightThigh > 0 ||
                      data.painTime2RightThigh > 0 ||
                      data.painMedicinesRightThigh > 0 ||
                      data.painLimitsRightThigh > 0 ||
                      data.painLengthRightThigh > 0 ||
                      data.painTypeRightThigh > 0
                    ) {
                      openDiagnosisModal('rightThigh', {
                        painIntensity: 'painIntensityRightThigh',
                        painTime: 'painTimeRightThigh',
                        painTime2: 'painTime2RightThigh',
                        painMedicines: 'painMedicinesRightThigh',
                        painLimits: 'painLimitsRightThigh',
                        painLength: 'painLengthRightThigh',
                        painType: 'painTypeRightThigh',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
            <div className={styles.diagnosisPain__man}>
              <img src={manBack} alt="man_back" />
              <div className={styles.diagnosisPain__man_bg}>
                <div
                  id="headBack"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_headBack + painClass.headBack
                  }${
                    data.painIntensityHeadBack > 0 ||
                    data.painTimeHeadBack > 0 ||
                    data.painTime2HeadBack > 0 ||
                    data.painMedicinesHeadBack > 0 ||
                    data.painLimitsHeadBack > 0 ||
                    data.painLengthHeadBack > 0 ||
                    data.painTypeHeadBack > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityHeadBack > 0 ||
                      data.painTimeHeadBack > 0 ||
                      data.painTime2HeadBack > 0 ||
                      data.painMedicinesHeadBack > 0 ||
                      data.painLimitsHeadBack > 0 ||
                      data.painLengthHeadBack > 0 ||
                      data.painTypeHeadBack > 0
                    ) {
                      openDiagnosisModal('headBack', {
                        painIntensity: 'painIntensityHeadBack',
                        painTime: 'painTimeHeadBack',
                        painTime2: 'painTime2HeadBack',
                        painMedicines: 'painMedicinesHeadBack',
                        painLimits: 'painLimitsHeadBack',
                        painLength: 'painLengthHeadBack',
                        painType: 'painTypeHeadBack',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
                <div
                  id="thoracic"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_thoracic + painClass.thoracic
                  }${
                    data.painIntensityThoracic > 0 ||
                    data.painTimeThoracic > 0 ||
                    data.painTime2Thoracic > 0 ||
                    data.painMedicinesThoracic > 0 ||
                    data.painLimitsThoracic > 0 ||
                    data.painLengthThoracic > 0 ||
                    data.painTypeThoracic > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityThoracic > 0 ||
                      data.painTimeThoracic > 0 ||
                      data.painTime2Thoracic > 0 ||
                      data.painMedicinesThoracic > 0 ||
                      data.painLimitsThoracic > 0 ||
                      data.painLengthThoracic > 0 ||
                      data.painTypeThoracic > 0
                    ) {
                      openDiagnosisModal('thoracic', {
                        painIntensity: 'painIntensityThoracic',
                        painTime: 'painTimeThoracic',
                        painTime2: 'painTime2Thoracic',
                        painMedicines: 'painMedicinesThoracic',
                        painLimits: 'painLimitsThoracic',
                        painLength: 'painLengthThoracic',
                        painType: 'painTypeThoracic',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="lumbar"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_lumbar + painClass.lumbar
                  }${
                    data.painIntensityLumbar > 0 ||
                    data.painTimeLumbar > 0 ||
                    data.painTime2Lumbar > 0 ||
                    data.painMedicinesLumbar > 0 ||
                    data.painLimitsLumbar > 0 ||
                    data.painLengthLumbar > 0 ||
                    data.painTypeLumbar > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLumbar > 0 ||
                      data.painTimeLumbar > 0 ||
                      data.painTime2Lumbar > 0 ||
                      data.painMedicinesLumbar > 0 ||
                      data.painLimitsLumbar > 0 ||
                      data.painLengthLumbar > 0 ||
                      data.painTypeLumbar > 0
                    ) {
                      openDiagnosisModal('lumbar', {
                        painIntensity: 'painIntensityLumbar',
                        painTime: 'painTimeLumbar',
                        painTime2: 'painTime2Lumbar',
                        painMedicines: 'painMedicinesLumbar',
                        painLimits: 'painLimitsLumbar',
                        painLength: 'painLengthLumbar',
                        painType: 'painTypeLumbar',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="leftPeace"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftPeace + painClass.leftPeace
                  }${
                    data.painIntensityLeftPeace > 0 ||
                    data.painTimeLeftPeace > 0 ||
                    data.painTime2LeftPeace > 0 ||
                    data.painMedicinesLeftPeace > 0 ||
                    data.painLimitsLeftPeace > 0 ||
                    data.painLengthLeftPeace > 0 ||
                    data.painTypeLeftPeace > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftPeace > 0 ||
                      data.painTimeLeftPeace > 0 ||
                      data.painTime2LeftPeace > 0 ||
                      data.painMedicinesLeftPeace > 0 ||
                      data.painLimitsLeftPeace > 0 ||
                      data.painLengthLeftPeace > 0 ||
                      data.painTypeLeftPeace > 0
                    ) {
                      openDiagnosisModal('leftPeace', {
                        painIntensity: 'painIntensityLeftPeace',
                        painTime: 'painTimeLeftPeace',
                        painTime2: 'painTime2LeftPeace',
                        painMedicines: 'painMedicinesLeftPeace',
                        painLimits: 'painLimitsLeftPeace',
                        painLength: 'painLengthLeftPeace',
                        painType: 'painTypeLeftPeace',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>
                <div
                  id="rightPeace"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightPeace +
                    painClass.rightPeace
                  }${
                    data.painIntensityRightPeace > 0 ||
                    data.painTimeRightPeace > 0 ||
                    data.painTime2RightPeace > 0 ||
                    data.painMedicinesRightPeace > 0 ||
                    data.painLimitsRightPeace > 0 ||
                    data.painLengthRightPeace > 0 ||
                    data.painTypeRightPeace > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightPeace > 0 ||
                      data.painTimeRightPeace > 0 ||
                      data.painTime2RightPeace > 0 ||
                      data.painMedicinesRightPeace > 0 ||
                      data.painLimitsRightPeace > 0 ||
                      data.painLengthRightPeace > 0 ||
                      data.painTypeRightPeace > 0
                    ) {
                      openDiagnosisModal('rightPeace', {
                        painIntensity: 'painIntensityRightPeace',
                        painTime: 'painTimeRightPeace',
                        painTime2: 'painTime2RightPeace',
                        painMedicines: 'painMedicinesRightPeace',
                        painLimits: 'painLimitsRightPeace',
                        painLength: 'painLengthRightPeace',
                        painType: 'painTypeRightPeace',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
                <div
                  id="leftFoot"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_leftFoot + painClass.leftFoot
                  }${
                    data.painIntensityLeftFoot > 0 ||
                    data.painTimeLeftFoot > 0 ||
                    data.painTime2LeftFoot > 0 ||
                    data.painMedicinesLeftFoot > 0 ||
                    data.painLimitsLeftFoot > 0 ||
                    data.painLengthLeftFoot > 0 ||
                    data.painTypeLeftFoot > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityLeftFoot > 0 ||
                      data.painTimeLeftFoot > 0 ||
                      data.painTime2LeftFoot > 0 ||
                      data.painMedicinesLeftFoot > 0 ||
                      data.painLimitsLeftFoot > 0 ||
                      data.painLengthLeftFoot > 0 ||
                      data.painTypeLeftFoot > 0
                    ) {
                      openDiagnosisModal('leftFoot', {
                        painIntensity: 'painIntensityLeftFoot',
                        painTime: 'painTimeLeftFoot',
                        painTime2: 'painTime2LeftFoot',
                        painMedicines: 'painMedicinesLeftFoot',
                        painLimits: 'painLimitsLeftFoot',
                        painLength: 'painLengthLeftFoot',
                        painType: 'painTypeLeftFoot',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
                <div
                  id="rightFoot"
                  role="button"
                  tabIndex={0}
                  className={`${
                    styles.diagnosisPain__man_bg_rightFoot + painClass.rightFoot
                  }${
                    data.painIntensityRightFoot > 0 ||
                    data.painTimeRightFoot > 0 ||
                    data.painTime2RightFoot > 0 ||
                    data.painMedicinesRightFoot > 0 ||
                    data.painLimitsRightFoot > 0 ||
                    data.painLengthRightFoot > 0 ||
                    data.painTypeRightFoot > 0
                      ? styles.DiagnosisQuestions_diagnosis_man_active
                      : ''
                  }`}
                  onClick={() => {
                    if (
                      data.painIntensityRightFoot > 0 ||
                      data.painTimeRightFoot > 0 ||
                      data.painTime2RightFoot > 0 ||
                      data.painMedicinesRightFoot > 0 ||
                      data.painLimitsRightFoot > 0 ||
                      data.painLengthRightFoot > 0 ||
                      data.painTypeRightFoot > 0
                    ) {
                      openDiagnosisModal('rightFoot', {
                        painIntensity: 'painIntensityRightFoot',
                        painTime: 'painTimeRightFoot',
                        painTime2: 'painTime2RightFoot',
                        painMedicines: 'painMedicinesRightFoot',
                        painLimits: 'painLimitsRightFoot',
                        painLength: 'painLengthRightFoot',
                        painType: 'painTypeRightFoot',
                      });
                    }
                  }}
                >
                  &nbsp;
                </div>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentPart && data && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="mui-fixed">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Odpowiedzi pacjenta
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div className={styles.form}>
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painIntensity">Natężenie bólu</InputLabel>
                  <Select
                    name="painIntensity"
                    id="painIntensity"
                    label="Intensywność"
                    // @ts-ignore
                    value={data[currentPart.painIntensity]}
                    disabled
                  >
                    {Array.from(Array(10))
                      .map((e, i) => i + 1)
                      .map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painTime">
                    Występowanie bólu w ciągu doby
                  </InputLabel>
                  <Select
                    name="painTime"
                    id="painTime"
                    label="Intensywność"
                    // @ts-ignore
                    value={data[currentPart.painTime]}
                    disabled
                  >
                    <MenuItem key="1" value="1">
                      w ciągu dnia
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      w nocy
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      dzień i noc
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painTime">
                    Występowanie bólu w trakcie aktywności
                  </InputLabel>
                  <Select
                    name="painTime"
                    id="painTime"
                    label="Intensywność"
                    // @ts-ignore
                    value={data[currentPart.painTime]}
                    disabled
                  >
                    <MenuItem key="1" value="1">
                      ból spoczynkowy, w czasie odpoczynku lub snu
                    </MenuItem>
                    <MenuItem key="2" value="2">
                      ból występuje podczas zwyczajnych czynności dnia
                      codziennego
                    </MenuItem>
                    <MenuItem key="3" value="3">
                      ból w trakcie aktywności fizycznej
                    </MenuItem>
                    <MenuItem key="4" value="4">
                      nie wiążę występowania bólu z aktywnością lub odpoczynkiem
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painMedicines">
                    Ustępowanie po lekach
                  </InputLabel>
                  <Select
                    name="painMedicines"
                    id="painMedicines"
                    label="Intensywność"
                    // @ts-ignore
                    value={data[currentPart.painMedicines]}
                    disabled
                  >
                    <MenuItem key="Tak" value="1">
                      Tak
                    </MenuItem>
                    <MenuItem key="Nie" value="2">
                      Nie
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painType">
                    Ograniczenie funkcjonowania
                  </InputLabel>
                  <Select
                    name="painLimits"
                    id="painLimits"
                    label="Ograniczenie funkcjonowania"
                    // @ts-ignore
                    value={data[currentPart.painLimits]}
                    disabled
                  >
                    <MenuItem
                      key="Nie mam ograniczeń, moja sprawność jest pełna"
                      value="1"
                    >
                      Nie mam ograniczeń, moja sprawność jest pełna
                    </MenuItem>
                    <MenuItem key="Mam nieznaczne ograniczenia" value="2">
                      Mam nieznaczne ograniczenia{' '}
                    </MenuItem>
                    <MenuItem key="Mam znaczne ograniczenia" value="3">
                      Mam znaczne ograniczenia
                    </MenuItem>
                    <MenuItem key="Wymagam pomocy osób trzecich" value="4">
                      Wymagam pomocy osób trzecich
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painType">Czas występowania bólu</InputLabel>
                  <Select
                    name="painLength"
                    id="painLength"
                    label="Czas występowania bólu"
                    // @ts-ignore
                    value={data[currentPart.painLength]}
                    disabled
                  >
                    <MenuItem key="Od dzisiaj" value="1">
                      Od dzisiaj
                    </MenuItem>
                    <MenuItem key="Od kilku dni" value="2">
                      Od kilku dni
                    </MenuItem>
                    <MenuItem key="Od kilku tygodni" value="3">
                      Od kilku tygodni
                    </MenuItem>
                    <MenuItem key="Od kilku miesięcy" value="4">
                      Od kilku miesięcy
                    </MenuItem>
                    <MenuItem key="Od kilku lat" value="5">
                      Od kilku lat
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl sx={{ minWidth: '100%' }} size="small">
                  <InputLabel id="painType">Rodzaj bólu</InputLabel>
                  <Select
                    name="painType"
                    id="painType"
                    label="Rodzaj bólu"
                    // @ts-ignore
                    value={data[currentPart.painType]}
                    disabled
                  >
                    <MenuItem key="Ból samoistny" value="1">
                      Ból samoistny
                    </MenuItem>
                    <MenuItem key="Ból po urazie/przeciążeniu" value="2">
                      Ból po urazie/przeciążeniu
                    </MenuItem>
                  </Select>
                </FormControl>
                <br />
                <br />

                <Button
                  size="small"
                  sx={{ marginLeft: '10px' }}
                  onClick={() => {
                    closeDiagnosisModal();
                  }}
                  variant="outlined"
                >
                  Zamknij okno
                </Button>
              </div>
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default PatientAnswers;
