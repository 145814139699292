import axios, { AxiosPromise } from 'axios';

import { baseAPIUrl } from '../config';

const baseExternalApiUrl = `${baseAPIUrl}/znajdzfizjoterapeute/api/search`;

interface AllCategories {
  Value: {
    DiseaseCategories: any[];
    Therapies: any[];
  };
}

interface Cities {
  Value: any[];
}

export const getAllCategories = async (): AxiosPromise<AllCategories> => {
  return axios.get(`${baseExternalApiUrl}/getAllCategories`);
};

export const getDefaultPlacesData = (): AxiosPromise<Cities> => {
  return axios.get(`${baseExternalApiUrl}/getdefaultplacesdata`);
};

export const getAdvancedSearchResult = (
  values: any,
  findPlace: any,
  findDisease: any,
  findTherapy: any
): AxiosPromise<any> => {
  return axios.post(`${baseExternalApiUrl}/getadvancedsearchresult`, {
    SelectedDiseases: [],
    SelectedTherapies: [],
    CriteriaInputValue: values.specialization.toLowerCase(),
    PlaceInputValue: findPlace || {
      name: '',
      administrativeArea: null,
      id: '',
      useOnlyViewportForSearch: false,
      location: {
        lat: 51.919438,
        lng: 19.145136,
      },
      viewport: {
        northeast: {
          lat: 54.9054761,
          lng: 24.1458931,
        },
        southwest: {
          lat: 49.002025,
          lng: 14.1228641,
        },
      },
    },
    CurrentPage: 1,
    SortOrder: -1,
    CriteriaInputSelectedItem: findDisease || findTherapy,
  });
};
