export enum Path {
  // shared
  ROOT = '/',
  ACCESS_DENIED = '/wstep-zabroniony',
  NOT_AUTHORIZED = '/niezautoryzowany',
  INFOPAGE = '/o-projekcie',
  VIDEO_CALL = '/rozmowa',
  JOIN_VIDEO_CALL = '/dolacz',

  // patient
  PATIENT = '/pacjent',
  PHYSIOTHERAPISTS_BASE = '/baza-fizjoterapeutow',
  THERAPY_PLAN = '/plan-terapii',
  FIND_THERAPY_PLAN = '/wyszukaj-plan-terapii',
  DIAGNOSIS = '/diagnoza',
  DIAGNOSIS_RESULT = '/wynik-diagnozy',

  // physiotherapist
  PHYSIOTHERAPIST = '/fizjoterapeuta',
  PREPARE_THERAPY_PLAN = '/zaplanuj-plan-terapii',
  SUMMARY_OF_PLAN = '/podsumowanie-planu',
  PLAN_GENERATED = '/plan-wygenerowany',
  EXERCISES_LIST = '/baza-cwiczen',
  FIND_DIAGNOSIS_RESULT = '/sprawdz-plan-pacjenta',
  PATIENT_PROGRESS_STATS = '/status-cwiczen-pacjenta',
  PATIENT_ANSWERS = '/odpowiedzi-pacjenta',
  DIAGNOSIS_BY_PHYSIO = '/diagnoza-przez-fizjoterapeute',
  GETCODE = '/kodweryfikacyjny',
}
