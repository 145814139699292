/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Button, Pagination, Stack, ThemeProvider } from '@mui/material';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Loading from 'react-loading';
import { Link, useParams } from 'react-router-dom';
import { usePDF } from 'react-to-pdf';

import { MediaType } from '../../api/models/ExercisesDto';
import { TherapyPlanResponse } from '../../api/models/TherapyPlanDto';
import {
  getTherapyByPatientUuid,
  updateTherapy,
} from '../../api/services/therapy';
import {
  getTherapyPlan,
  updateTherapyExercise,
} from '../../api/services/therapy_plan';
import { HeaderWithTriangles } from '../../components/HeaderWithTriangles';
import { PdfTherapyPlan } from '../../components/PdfTherapyPlan';
import { Path } from '../../router/paths';
import { customButtonTheme } from '../../styles/buttons.mui';
import globalStyles from '../../styles/global.module.scss';
import { formatDate } from '../../utils/date';
import styles from './TherapyPlan.module.scss';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
    answer: number;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon fontSize="large" color="error" />,
    label: 'Very Dissatisfied',
    answer: -2,
  },
  2: {
    icon: <SentimentDissatisfiedIcon fontSize="large" color="error" />,
    label: 'Dissatisfied',
    answer: -1,
  },
  3: {
    icon: <SentimentSatisfiedIcon fontSize="large" color="warning" />,
    label: 'Neutral',
    answer: 0,
  },
  4: {
    icon: <SentimentSatisfiedAltIcon fontSize="large" color="success" />,
    label: 'Satisfied',
    answer: 1,
  },
  5: {
    icon: <SentimentVerySatisfiedIcon fontSize="large" color="success" />,
    label: 'Very Satisfied',
    answer: 2,
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return (
    <div
      {...other}
      style={{
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0 10px',
      }}
    >
      <div>{customIcons[value].answer}</div>
      <div>{customIcons[value].icon}</div>
    </div>
  );
};

const TherapyPlan = () => {
  const queryClient = useQueryClient();
  const { id: patientUuid } = useParams();
  const [day, setDay] = useState(1);
  const [maxDay, setMaxDay] = useState(1);
  const [dayEx, setDayEx] = useState(1);
  const [doneEx, setDoneEx] = useState(0);
  const [currentVideo, setCurrentVideo] = useState<number>();
  const { toPDF, targetRef } = usePDF({ filename: 'plan-terapii.pdf' });
  const dayRef = useRef(null);

  // @ts-ignore
  const executeScroll = () => dayRef.current.scrollIntoView();

  const { mutate: mutateTherapyStarted } = useMutation({
    mutationFn: updateTherapy,
    onSuccess: (exercises: any) => {
      console.log('updateTherapy', exercises);
      return queryClient.invalidateQueries(['therapy']);
    },
  });

  const { mutate: mutateExercise } = useMutation({
    mutationFn: updateTherapyExercise,
    onSuccess: (exercise: any) => {
      console.log('updateTherapyPlan', exercise);
      return queryClient.invalidateQueries(['therapy-plan']);
    },
  });

  const {
    data: therapy,
    status,
    isLoading,
  } = useQuery(
    ['therapy', patientUuid],
    // @ts-ignore
    () => getTherapyByPatientUuid(patientUuid).then((res) => res.data),
    { enabled: !!patientUuid }
  );

  const { data: therapyExercises, status: statusTherapyExercises } = useQuery(
    ['therapy-plan', therapy],
    // @ts-ignore
    () => getTherapyPlan(therapy.id).then((res) => res.data),
    { enabled: !!therapy }
  );

  const startTherapyExercises = () => {
    mutateTherapyStarted({
      started_time: formatDate(new Date()),
      patient_link: patientUuid,
    });
  };

  const markExerciseAsFinished = (id: number) => {
    executeScroll();
    mutateExercise({
      id,
      done: formatDate(new Date()),
    });
  };

  useEffect(() => {
    if (therapyExercises?.length) {
      setDoneEx(0);
      const days = [
        ...new Set(therapyExercises.map((item) => item.day_number)),
      ];
      let currentDay = 1;
      let dayeEx = 0;
      let doneeEx = 0;

      days.forEach((d) => {
        if (
          therapyExercises
            .filter((item) => item.day_number === d)
            .map((i) => {
              if (i.exeId && i.day_number === currentDay) {
                dayeEx += 1;
              }
              if (i.done && i.exeId) {
                doneeEx += 1;
              }
              return i;
            })
            .every((item) => {
              const today = new Date().toISOString().slice(0, 10);
              const doneDate = item.done?.toString().slice(0, 10)
                ? item.done?.toString().slice(0, 10)
                : today;

              return item.done && doneDate < today;
            })
        ) {
          if (days.includes(currentDay + 1)) {
            currentDay += 1;
          }
        }
      });
      setDayEx(dayeEx);
      setDoneEx(doneeEx);

      setDay(currentDay);
      setMaxDay(Math.max(...days));
    }
  }, [therapyExercises]);

  useEffect(() => {
    const percents = (doneEx / dayEx) * 100;
    const activePage = document.getElementsByClassName('Mui-selected')[0];
    if (activePage) {
      const activeSpan = document.getElementsByClassName(
        'TherapyPlan_progressSpan'
      )[0];
      if (activeSpan) {
        activeSpan.setAttribute(
          'style',
          `position: absolute;
          left: -2px;
          bottom: -2px;
          right: -2px;
          border-radius:100%;
          top:-2px;
            background: 
          radial-gradient(closest-side, white 79%, transparent 80% 100%),
          conic-gradient(green ${percents}%, white 0);    
          content: ' ';
          z-index: -1; transition:all .5s ease`
        );
      } else {
        document.getElementsByClassName(
          'Mui-selected'
        )[0].innerHTML += `<span class="TherapyPlan_progressSpan" style=" position: absolute;
      left: -2px;
      bottom: -2px;
      right: -2px;
      border-radius:100%;
      top:-2px;
        background: 
      radial-gradient(closest-side, white 79%, transparent 80% 100%),
      conic-gradient(green ${percents}%, white 0);    
      content: ' ';
      z-index: -1; transition:all .5s ease"></span>`;
      }
    }
  }, [doneEx, dayEx]);

  const days = therapyExercises?.reduce((prev, current) => {
    return prev.day_number > current.day_number ? prev : current;
  });

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '100px auto',
        }}
      >
        <Loading type="spin" color="#76446B" height={100} width={100} />
      </div>
    );
  }

  if (statusTherapyExercises === 'error' || status === 'error') {
    return (
      <div className={`${globalStyles.container} ${styles.container}`}>
        Wystąpił problem z tym planem ćwiczeń, skontaktuj się z administratorem
      </div>
    );
  }

  if (therapy && !therapy?.started_time) {
    return (
      <>
        {' '}
        <Helmet>
          <title>Plan ćwiczeń - Zdalne Konsultacje KIF</title>
        </Helmet>
        <div className={`${globalStyles.container} ${styles.container}`}>
          <HeaderWithTriangles title="Plan ćwiczeń" className={styles.header} />
          <div className={styles.topHeader}>Witaj w Twoim planie ćwiczeń</div>
          <div className={styles.desc}>
            Znajdziesz tutaj przygotowany przez Twojego fizjoterapeutę
            <br /> zestaw ćwiczeń, które wykonasz samodzielnie. <br />
            Wszystkie ćwiczenia mają niezbędne opisy oraz materiały wideo,{' '}
            <br />
            możesz je odtworzyć na swoim komputerze lub telefonie.
            <br /> Plan ćwiczeń podzielony jest na dni, wykonuj je w zadanej
            kolejności i czasie. <br />
            <br />
            Po ukończeniu partii ćwiczeń będziemy Cię prosić o wypełnienie
            prostych ankiet monitorujących, <br />
            które pozwolą fizjoterapeucie zdalnie badać postępy i skuteczność
            zadanych ćwiczeń.
          </div>
          <div className={styles.physioData}>
            <div className={styles.label}>TWÓJ FIZJOTERAPEUTA:</div>
            <div className={styles.value}>{therapy?.phy_name}</div>
            <div className={styles.label}>
              PWZFZ: <span className={styles.value}>{therapy?.phy_pwzfz}</span>
            </div>
            <div className={styles.label}>
              TELEFON:{' '}
              <span className={styles.value}>{therapy?.phy_phone}</span>
            </div>
          </div>

          <div>{patientUuid}</div>

          <div className={styles.textForPatientHeader}>
            Dodatkowe uwagi do planu:
          </div>
          <div className={styles.textForPatient}>
            {therapy?.text_for_patient}
          </div>
          <div className={styles.footerStats}>
            Twoja terapia będzie trwała {days?.day_number} dni i zawiera{' '}
            {therapyExercises?.length} ćwiczenia oraz{' '}
            {therapyExercises?.filter((item) => item.monitoring).length}{' '}
            ankiety. <br />
            <br />
            Po zakończeniu planu ćwiczeń spotkaj się ponownie z fizjoterapeutą
            aby podsumować jej wyniki.
          </div>
          <div className={styles.buttonContainer}>
            <ThemeProvider theme={customButtonTheme}>
              <Button
                variant="contained"
                onClick={() => startTherapyExercises()}
              >
                Zaczynajmy
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </>
    );
  }

  const therapyFinished = () => {
    const areTherapyFinished = therapyExercises?.every((e) => e.done);
    return areTherapyFinished;
  };

  const checkIfExercisesForCurrentDayAreReady = () => {
    const areReady = therapyExercises
      ?.filter((item) => item.day_number === day)
      .every((e) => e.done);
    return areReady;
  };

  const exerciseType = (item: TherapyPlanResponse) => (
    <div className={styles.exercise}>
      <div className={styles.exerciseName}>
        Ćwiczenie {item.exercise_number}
      </div>
      <div className={styles.settings}>
        {!!item.exe_minutes && `Czas: ${item.exe_minutes} minut. `}
        {!!item.exe_count && `Powtórzenia: ${item.exe_count}.`}
      </div>
      <div className={styles.description}>{item?.exe?.description}</div>
      <div className={styles.media}>
        {item?.exe?.media_type === MediaType.VIDEO && (
          <div className={styles.videoContainer}>
            {currentVideo && currentVideo === item.exe.id ? (
              <video
                width="360"
                height="540"
                autoPlay
                controls
                poster={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe.id}_1.jpg`}
              >
                <source
                  src={`${process.env.REACT_APP_API_HOST}/media/video/video_${item?.exe.id}.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div
                className={styles.imgBox}
                onClick={() => setCurrentVideo(item?.exe?.id)}
              >
                <img
                  className={styles.videoImg}
                  src={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe.id}_1.jpg`}
                  alt=""
                />
                <PlayCircleIcon
                  fontSize="large"
                  className={styles.playButton}
                />
              </div>
            )}
          </div>
        )}
      </div>
      {item.done ? (
        <div className={styles.isFinished}>
          <div className={styles.isFinishedQuestion}>Super!</div>
          <div className={styles.isFinishedDesc}>
            Ćwiczenie {item?.exe?.id} zostało oznaczone jako wykonane
          </div>
        </div>
      ) : (
        <div className={styles.isFinished}>
          <div className={styles.isFinishedQuestion}>Ćwiczenie skończone?</div>
          <div className={styles.isFinishedDesc}>
            Jeśli zakończono wykonywanie ćwiczenia kliknij poniżej
          </div>
          <ThemeProvider theme={customButtonTheme}>
            <Button
              variant="contained"
              onClick={() => markExerciseAsFinished(item.id)}
            >
              Zakończono
            </Button>
          </ThemeProvider>
        </div>
      )}
    </div>
  );

  const answer = (id: number, rating: string, item: TherapyPlanResponse) => {
    if (rating !== null) {
      mutateExercise({
        id,
        monitoring_answer: Number(rating) - 3,
        done: item.monitoring_pain ? formatDate(new Date()) : undefined,
      });
    }
  };

  const levelOfPain = (
    id: number,
    rating: number,
    item: TherapyPlanResponse
  ) => {
    if (rating !== null) {
      mutateExercise({
        id,
        monitoring_pain: Number(rating),
        done: item.monitoring_answer ? formatDate(new Date()) : undefined,
      });
    }
  };

  const monitoringType = (item: TherapyPlanResponse) => {
    return (
      <div className={styles.exercise}>
        <div className={styles.exerciseName}>Monitorowanie postępów</div>
        <div className={styles.description}>
          {item?.done &&
          item?.monitoring_answer !== null &&
          item?.monitoring_pain !== null ? (
            <>Dziękujemy za odpowiedź</>
          ) : (
            <div className={styles.monitoringBox}>
              {item?.monitoring_answer === null && (
                <>
                  <div>Określ w skali -2 do 2 jak się czujesz</div>
                  <div className={styles.ratings}>
                    <StyledRating
                      name="highlight-selected-only"
                      IconContainerComponent={IconContainer}
                      getLabelText={(value: number) => customIcons[value].label}
                      highlightSelectedOnly
                      onClick={(el) =>
                        answer(
                          item?.id,
                          (el.target as HTMLInputElement).value,
                          item
                        )
                      }
                    />
                  </div>
                </>
              )}
              {item?.monitoring_pain === null && (
                <>
                  <div>
                    Prosze ocenić natężenie dolegliwości bólowych w skali od 0
                    do 10, gdzie 0 wskazuje na brak bólu, zaś 10, na ból nie do
                    wytrzymania
                  </div>
                  <div className={styles.ratings}>
                    {[...Array(11).keys()].map((i) => (
                      <div
                        className={styles.vote}
                        onClick={() => levelOfPain(item.id, i, item)}
                      >
                        {i}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (therapyFinished()) {
    return (
      <div className={styles.exercises}>
        <div className={styles.allReady}>
          <div className={styles.allReadyOne}>
            Twój plan ćwiczeń został zakończony
          </div>
          <div className={styles.allReadyTwo}>
            Skontaktuj się z fizjoterapeutą podając ID planu ćwiczeń: <br />
            {therapy.physio_link}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={`${globalStyles.container} ${styles.container}`}>
        <div className={styles.box}>
          <div className={styles.therapyInfoTop}>
            <div className={styles.therapyInfoTopLeft}>
              <div className={styles.therapyInfoHeader}>Plan ćwiczeń</div>
              <div className={styles.downloadPdf} onClick={() => toPDF()}>
                Pobierz PDF
              </div>
              <div
                style={{
                  opacity: 0,
                  position: 'absolute',
                  width: '900px',
                  right: '200%',
                }}
              >
                <div className={styles.pdfFile} ref={targetRef}>
                  {therapyExercises?.map((item) => {
                    return item.monitoring ? '' : <PdfTherapyPlan {...item} />;
                  })}
                </div>
              </div>
              <div className={styles.therapyInfoId}>{patientUuid}</div>
            </div>
            <div className={styles.therapyInfoTopRight}>
              <div className={styles.physioDataActive}>
                <div className={styles.label}>TWÓJ FIZJOTERAPEUTA:</div>
                <div className={styles.value}>{therapy?.phy_name}</div>
                <div className={styles.label}>
                  PWZFZ:{' '}
                  <span className={styles.value}>{therapy?.phy_pwzfz}</span>
                </div>
                <div className={styles.label}>
                  TELEFON:{' '}
                  <span className={styles.value}>{therapy?.phy_phone}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div style={{ textAlign: 'right' }}>
              <Button
                size="small"
                sx={{ marginLeft: '10px' }}
                variant="outlined"
                component={Link}
                to={`${Path.JOIN_VIDEO_CALL}/${patientUuid}`}
                color="error"
              >
                Kontakt z fizjoterapeutą
              </Button>
            </div>
          </div>
          <br />
          <br />
          <div className={styles.dayHeader} ref={dayRef}>
            Dzień
          </div>

          <Stack spacing={2}>
            <Pagination
              count={maxDay}
              page={day}
              onChange={() => {}}
              hidePrevButton
              style={{ textAlign: 'center', zIndex: 5 }}
              hideNextButton
            />
          </Stack>
          <div className={styles.exercises}>
            {checkIfExercisesForCurrentDayAreReady() ? (
              <div className={styles.allReady}>
                <div className={styles.allReadyOne}>
                  To już wszystko na dzisiaj
                </div>
                <div className={styles.allReadyTwo}>Wróć do ćwiczeń jutro</div>
              </div>
            ) : (
              therapyExercises
                ?.filter((item) => item.day_number === day && !item.done)
                .map((item, index) => {
                  if (index === 0) {
                    return item.monitoring
                      ? monitoringType(item)
                      : exerciseType(item);
                  }
                  return false;
                })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TherapyPlan;
