const AddCircleIcon = ({ color }: any) => {
  return (
    <svg
      pointerEvents="none"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        pointerEvents="none"
        d="M9 0C4.02823 0 0 4.02823 0 9C0 13.9718 4.02823 18 9 18C13.9718 18 18 13.9718 18 9C18 4.02823 13.9718 0 9 0ZM14.2258 10.0161C14.2258 10.2556 14.0298 10.4516 13.7903 10.4516H10.4516V13.7903C10.4516 14.0298 10.2556 14.2258 10.0161 14.2258H7.98387C7.74435 14.2258 7.54839 14.0298 7.54839 13.7903V10.4516H4.20968C3.97016 10.4516 3.77419 10.2556 3.77419 10.0161V7.98387C3.77419 7.74435 3.97016 7.54839 4.20968 7.54839H7.54839V4.20968C7.54839 3.97016 7.74435 3.77419 7.98387 3.77419H10.0161C10.2556 3.77419 10.4516 3.97016 10.4516 4.20968V7.54839H13.7903C14.0298 7.54839 14.2258 7.74435 14.2258 7.98387V10.0161Z"
        fill={color}
      />
    </svg>
  );
};

export default AddCircleIcon;
