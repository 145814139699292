const padTo2Digits = (num: number) => num.toString().padStart(2, '0');

export const formatDate = (date: Date | string) => {
  let newDate: Date;
  if (typeof date === 'string') {
    newDate = new Date(date);
  } else {
    newDate = date;
  }

  return `${[
    newDate.getFullYear(),
    padTo2Digits(newDate.getMonth() + 1),
    padTo2Digits(newDate.getDate()),
  ].join('-')} ${[
    padTo2Digits(newDate.getHours()),
    padTo2Digits(newDate.getMinutes()),
    padTo2Digits(newDate.getSeconds()),
  ].join(':')}`;
};
