import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { DiagnosisDto } from '../models/DiagnosisDto';

export const getDiagnosis = (id: string): AxiosPromise<DiagnosisDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/diagnosis/${id}`,
    method: 'GET',
  });
};

export const createDiagnosis = (
  therapy: DiagnosisDto
): AxiosPromise<DiagnosisDto> => {
  return axiosInstance({
    url: `${baseAPIUrl}/diagnosis`,
    method: 'POST',
    data: therapy,
  });
};
