import styles from './InfoWithBigBackground.module.scss';
import {
  InfoWithBigBackgroundProps,
  Version,
} from './InfoWithBigBackground.types';

const InfoWithBigBackground = ({
  littleHeader,
  header,
  description,
  version,
}: InfoWithBigBackgroundProps) => {
  return (
    <div
      className={styles.container}
      style={{
        background: version === Version.PATIENT ? `#E65F5C` : `#0D4D8A`,
        backgroundSize: 'cover',
      }}
    >
      <div
        className={`${styles.box} ${
          version === Version.PATIENT ? styles.boxPatient : styles.boxPhysio
        }`}
      >
        <div className={styles.littleHeader}>{littleHeader}</div>
        <div className={styles.header}>{header}</div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {/* <ThemeProvider theme={customButtonTheme}>
          <Button variant="contained" component={Link} to={Path.INFOPAGE}>
            Dowiedz się więcej
          </Button>
        </ThemeProvider> */}
      </div>
    </div>
  );
};

export default InfoWithBigBackground;
