/* eslint-disable @typescript-eslint/naming-convention */

import { MediaType } from '../../api/models/ExercisesDto';
import { TherapyPlanResponse } from '../../api/models/TherapyPlanDto';
import styles from './PdfTherapyPlan.module.scss';

const PdfTherapyPlan = (item: TherapyPlanResponse) => {
  const { name, exercise_number, exe_minutes, exe_count, exe } = item;

  return (
    <div className={styles.exercise}>
      <div className={styles.exerciseName}>
        Ćwiczenie {exercise_number} {name}
      </div>
      <div className={styles.settings}>
        {!!exe_minutes && `Czas: ${exe_minutes} minut. `}
        {!!exe_count && `Powtórzenia: ${exe_count}.`}
      </div>
      <div className={styles.description}>{item?.exe?.description}</div>
      <div className={styles.media}>
        {exe?.media_type === MediaType.VIDEO && (
          <>
            <img
              style={{ width: 252, height: 336 }}
              src={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe?.id}_1.jpg`}
              alt=""
            />
            <img
              style={{ width: 252, height: 336 }}
              src={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe?.id}_2.jpg`}
              alt=""
            />
            <img
              style={{ width: 252, height: 336 }}
              src={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe?.id}_3.jpg`}
              alt=""
            />
            <img
              style={{ width: 252, height: 336 }}
              src={`${process.env.REACT_APP_API_HOST}/media/video/screens/video_${item?.exe?.id}_4.jpg`}
              alt=""
            />
          </>
        )}
      </div>
      {/* <QRCodeCanvas value="https://adresdofilmu/" /> */}
    </div>
  );
};

export default PdfTherapyPlan;
