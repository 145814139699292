import axios, { AxiosInstance } from 'axios';

const axiosInstance: AxiosInstance = axios.create({
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  transformResponse: (data) => {
    if (data === '') return '';
    if (data === undefined) return undefined;
    return JSON.parse(data, (key, value) => {
      if (['update_time', 'create_time'].includes(key)) {
        return new Date(value);
      }

      return value;
    });
  },

  // axios issue: https://github.com/axios/axios/issues/89
  data: {},
});

export default axiosInstance;
