import { Helmet } from 'react-helmet';

import { calendarIcon, laptopIcon, personIcon } from '../../../assets/icons';
import { TherapyPlanImg } from '../../../assets/images/pics/therapy-plan';
import { TrainingsImg } from '../../../assets/images/pics/trainings';
import {
  InfoWithBigBackground,
  LinkBoxWithButton,
  LinkBoxWithImage,
  ProsMainBox,
} from '../../../components';
import { Version } from '../../../components/InfoWithBigBackground/InfoWithBigBackground.types';
import { Path } from '../../../router/paths';
import globalStyles from '../../../styles/global.module.scss';
import styles from './Physiotherapist.module.scss';

const mockedInfoWithBigBackgroundData = {
  littleHeader: 'Witaj w strefie fizjoterapeuty',
  header: 'Zdalny plan ćwiczeń dla Twoich pacjentów',
  description:
    'Dzięki naszej aplikacji przygotujesz dla swoich pacjentów plany terapii zawierające gotowe, zwizualizowowane ćwiczenia wraz z opisem.<br/><span style="color: #cc0000">Prowadzenie pacjenta za pomocą modułu powinno być poprzedzone przeprowadzeniem diagnostyki podczas wizyty fizjoterapeutycznej.  Zdalne konsultacje nie zastępują wizyt u fizjoterapeuty</span>',
};

const mockedProsData = [
  {
    icon: laptopIcon,
    header: 'Całkowicie online',
    description:
      'Ankieta wydolności, plan ćwiczeń i monitoring postępów i kontakt z pacjentem - wszystko z poziomu WebApp',
  },
  {
    icon: personIcon,
    header: 'Blisko 100 ćwiczeń',
    description:
      'Blisko 100 wyselekcjonowanych ćwiczeń wraz z wideo i opisami umożliwiających pacjentowi samodzielne ćwiczenia',
  },
  {
    icon: calendarIcon,
    header: 'Zdalne monitorowanie postępów',
    description:
      'W czasie rzeczywistym możesz monitorować postępy pacjenta i w razie potrzeby zmodyfikować plan ćwiczeń.',
  },
];

const mockLinkBoxWithImage = [
  {
    text_up: 'Zobacz',
    text_down: 'bazę ćwiczeń',
    path: '/baza-cwiczen',
    image: TrainingsImg,
    bgColor: '#A9FFF7',
    color: '#0D4D8A',
  },
  {
    text_up: 'Sprawdź',
    text_down: 'plan pacjenta',
    path: '/sprawdz-plan-pacjenta',
    image: TherapyPlanImg,
    bgColor: '#A9FFF7',
    color: '#0D4D8A',
  },
];

const Physiotherapist = () => {
  return (
    <>
      {' '}
      <Helmet>
        <title>Dla Fizjoterapeuty - Zdalne Konsultacje KIF</title>
      </Helmet>
      <div className={styles.container}>
        <InfoWithBigBackground
          version={Version.PHYSIOTHERAPIST}
          littleHeader={mockedInfoWithBigBackgroundData.littleHeader}
          header={mockedInfoWithBigBackgroundData.header}
          description={mockedInfoWithBigBackgroundData.description}
        />

        <ProsMainBox data={mockedProsData} color="#0D4D8A" />

        <div className={`${globalStyles.container} ${styles.linkBoxes}`}>
          <div className={styles.linkBox}>
            <LinkBoxWithButton
              bgColor="#A9FFF7"
              color="#0D4D8A"
              header="Przygotuj"
              title="Plan ćwiczeń online"
              description={
                'Skorzystaj z naszego narzędzia \ni przygotuj zestaw ćwiczeń, który przekażesz \npacjentowi'
              }
              path={Path.PREPARE_THERAPY_PLAN}
            />
          </div>
          <div className={styles.boxes}>
            {mockLinkBoxWithImage.map((props) => (
              /* eslint-disable-next-line react/prop-types */
              <LinkBoxWithImage {...props} key={props.text_up!} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Physiotherapist;
