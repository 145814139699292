const InProgressIcon = ({ color }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      viewBox="0 0 19 25"
      fill="none"
    >
      <path
        d="M17.5781 0H1.17188C0.524658 0 0 0.524658 0 1.17188V1.95312C0 2.60034 0.524658 3.125 1.17188 3.125C1.17188 7.56665 3.66289 11.3151 7.07236 12.5C3.66289 13.6849 1.17188 17.4333 1.17188 21.875C0.524658 21.875 0 22.3997 0 23.0469V23.8281C0 24.4753 0.524658 25 1.17188 25H17.5781C18.2253 25 18.75 24.4753 18.75 23.8281V23.0469C18.75 22.3997 18.2253 21.875 17.5781 21.875C17.5781 17.4333 15.0871 13.6849 11.6776 12.5C15.0871 11.3151 17.5781 7.56665 17.5781 3.125C18.2253 3.125 18.75 2.60034 18.75 1.95312V1.17188C18.75 0.524658 18.2253 0 17.5781 0ZM13.9122 18.75H4.83789C5.67085 16.465 7.38164 14.8438 9.375 14.8438C11.3682 14.8438 13.0792 16.4646 13.9122 18.75ZM13.9131 6.25H4.83779C4.4916 5.3002 4.29688 4.23574 4.29688 3.125H14.4531C14.4531 4.23853 14.2584 5.3021 13.9131 6.25Z"
        fill={color}
      />
    </svg>
  );
};

export default InProgressIcon;
