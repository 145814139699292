import {
  ContentCopy as ContentCopyIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { Button, CircularProgress, ThemeProvider } from '@mui/material';
import { useFormik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { CustomTextField } from '../../../components';
import { Path } from '../../../router/paths';
import {
  customButtonTheme,
  customButtonThemeBigger,
  simpleButtonTheme,
} from '../../../styles/buttons.mui';
import globalStyles from '../../../styles/global.module.scss';
import copyToClipboard from '../../../utils/copyToClipboard';
import htmlToPdf from '../../../utils/htmlToPdf';
import styles from './DiagnosisResult.module.scss';
import {
  DiagnosisResultProp,
  DiagnosisResultType,
} from './DiagnosisResult.types';

const diagnosisResult: DiagnosisResultProp[] = [
  {
    type: DiagnosisResultType.OK,
    result: 'KONSULTACJA Z FIZJOTERAPEUTĄ NIE JEST KONIECZNA',
    advice: `Wstępne wyniki ankiety wskazują, że nie jest konieczna wizyta u fizjoterapeuty.
    Pamiętaj jednak, że nasza ocena stanu zdrowia nie może być ostateczną i jeżeli czujesz, że coś jest nie tak
    to warto to skonsultować.`,
    adviceForPhysio:
      'Wstępne wyniki ankiety wskazują, że pacjent nie wymaga pomocy.',
  },
  {
    type: DiagnosisResultType.NOT_OK,
    result: 'SUGERUJEMY KONSULTACJĘ Z FIZJOTERAPEUTĄ',
    advice: `Wstępne wyniki ankiety wskazują, że konieczne jest przeprowadzenie
    dalszej diagnozy. Sugerujemy kontakt z fizjoterapeutą.`,
    adviceForPhysio:
      'Wstępne wyniki ankiety wskazują, że pacjent wymaga pomocy',
  },
];

const DiagnosisResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [result, setResult] = useState<DiagnosisResultProp>();
  const [doctor, setDoctor] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    const getResult = diagnosisResult.find(
      (item) => item.type === location.state.type
    );
    console.log(location.state);
    setResult(getResult);
    if (location.state.needsDoctor === true) {
      setDoctor(true);
    }
  }, []);

  const goToPlanPreparation = (diagnosisUuid: string) => {
    navigate(`${Path.PREPARE_THERAPY_PLAN}/${diagnosisUuid}`);
  };
  const onCodeClick = useCallback(() => {
    copyToClipboard(location.state.diagnosisUuid)
      .then(() => {
        toast.success('Skopiowano numer do schowka', {
          position: 'bottom-left',
        });
      })
      .catch(() => {
        toast.error('Wystąpił błąd podczas kopiowania numeru', {
          position: 'bottom-left',
        });
      });
  }, [location.state.diagnosisUuid]);
  const elementToPdfPatient = `<h4>Numer ankiety do Zdalnych Konsultacji KIF:<br/></h4><strong>${location.state.diagnosisUuid}</strong><br/><br/><h6>Przekaz go fizjoterapeucie w trakcie wizyty wraz z adresem strony praktyka.kif.info.pl</h6>`;
  const elementToPdfPhysio = `<h4>Numer ankiety do Zdalnych Konsultacji KIF:<br/></h4><strong>${location.state.diagnosisUuid}</strong><br/><br/>`;
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const onPDFClickPatient = useCallback(async () => {
    if (!elementToPdfPatient) return;
    setIsLoadingPdf(true);

    const filename = `numer_ankiety.pdf`;
    const html = elementToPdfPatient;

    await htmlToPdf({
      filename,
      html,
    });
    setIsLoadingPdf(false);
  }, [elementToPdfPatient]);

  const onPDFClickPhysio = useCallback(async () => {
    if (!elementToPdfPhysio) return;
    setIsLoadingPdf(true);

    const filename = `numer_ankiety.pdf`;
    const html = elementToPdfPhysio;

    await htmlToPdf({
      filename,
      html,
    });
    setIsLoadingPdf(false);
  }, [elementToPdfPhysio]);
  return (
    <>
      <div className={`${globalStyles.container} ${styles.container}`}>
        {result && !doctor && (
          <>
            <div
              className={styles.result}
              style={{
                color:
                  result.type === DiagnosisResultType.NOT_OK
                    ? '#EF4161'
                    : '#00B815',
              }}
            >
              {result.result}
            </div>
            <div
              className={styles.advice}
              style={{
                color:
                  result.type === DiagnosisResultType.NOT_OK
                    ? '#000'
                    : '#164C8A',
              }}
            >
              {location?.state?.filledBy === 'physio'
                ? result.adviceForPhysio
                : result.advice}
            </div>
          </>
        )}
        {doctor && location?.state?.filledBy === 'patient' && (
          <div
            className={styles.result}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#EF4161',
            }}
          >
            Koniecznie zgłoś się do lekarza pierwszego kontaktu
          </div>
        )}
        {doctor && location?.state?.filledBy === 'physio' && (
          <div
            className={styles.result}
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: '#EF4161',
            }}
          >
            Zaleć konsultację lekarską
          </div>
        )}
      </div>

      {result &&
        result.type === DiagnosisResultType.NOT_OK &&
        location.state.filledBy === 'patient' && (
          <>
            <div className={`${globalStyles.container} ${styles.container}`}>
              <div className={styles.idText}>
                Unikalny numer twojej ankiety dla fizjoterapeuty to: <br />
              </div>
              <div className={styles.idBox}>{location.state.diagnosisUuid}</div>
              <div className={styles.saveId}>
                Zapisz go lub wygeneruj PDF, a następnie przekaż fizjoterapeucie
                w trakcie wizyty.
              </div>
            </div>

            <div className={`${globalStyles.container} ${styles.container}`}>
              <ThemeProvider theme={simpleButtonTheme}>
                <Button variant="contained" type="button" onClick={onCodeClick}>
                  {' '}
                  <ContentCopyIcon fontSize="large" /> &nbsp; Skopiuj numer do
                  schowka
                </Button>
              </ThemeProvider>
              <br />
              <br />
              <ThemeProvider theme={simpleButtonTheme}>
                <Button
                  variant="contained"
                  type="button"
                  onClick={onPDFClickPatient}
                >
                  {' '}
                  {isLoadingPdf ? (
                    <CircularProgress color="inherit" size="24px" />
                  ) : (
                    <PictureAsPdfIcon fontSize="large" />
                  )}{' '}
                  &nbsp; Pobierz numer jako PDF
                </Button>
              </ThemeProvider>

              <form onSubmit={formik.handleSubmit} style={{ display: 'none' }}>
                <div className={styles.form}>
                  <CustomTextField
                    id="email"
                    label="Twój adres e-mail"
                    variant="outlined"
                    sx={{ margin: '0 0 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                  <ThemeProvider theme={customButtonTheme}>
                    <Button variant="contained" type="submit">
                      Wyślij
                    </Button>
                  </ThemeProvider>
                </div>
              </form>
            </div>

            <div className={styles.saveId}>
              Nie masz swojego fizjoterapeuty? Pomożemy go znaleźć. <br />
              Wyszukaj go w{' '}
              <Link to="/baza-fizjoterapeutow">bazie fizjoterapeutów.</Link>
            </div>
          </>
        )}

      {result &&
        result.type === DiagnosisResultType.NOT_OK &&
        location.state.filledBy === 'physio' && (
          <div className={`${globalStyles.container} ${styles.container}`}>
            <div className={styles.idText}>
              Unikalne ID ankiety to: <br />
            </div>
            <div className={styles.idBox}>{location.state.diagnosisUuid}</div>
            <br />
            <ThemeProvider theme={simpleButtonTheme}>
              <Button variant="contained" type="button" onClick={onCodeClick}>
                {' '}
                <ContentCopyIcon fontSize="inherit" /> &nbsp; Skopiuj numer do
                schowka
              </Button>
            </ThemeProvider>
            <br />
            <br />
            <ThemeProvider theme={simpleButtonTheme}>
              <Button
                variant="contained"
                type="button"
                onClick={onPDFClickPhysio}
              >
                {' '}
                {isLoadingPdf ? (
                  <CircularProgress color="inherit" size="16px" />
                ) : (
                  <PictureAsPdfIcon fontSize="inherit" />
                )}{' '}
                &nbsp; Pobierz numer jako PDF
              </Button>
            </ThemeProvider>
            <div className={styles.saveId}>
              Zapisz je w swojej dokumentacji. Na jego podstawie możesz
              przygotować plan ćwiczeń.
            </div>

            <div className={styles.goToPlanPreparation}>
              <ThemeProvider theme={customButtonThemeBigger}>
                <Button
                  variant="contained"
                  onClick={() =>
                    goToPlanPreparation(location.state.diagnosisUuid)
                  }
                >
                  Przejdź do tworzenia planu
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}
    </>
  );
};

export default DiagnosisResult;
