import { ExercisesTypeDto } from './ExerciseTypesDto';

export enum MediaType {
  VIDEO = 2,
  IMAGE = 1,
}

export type ExercisesDto = {
  id: number;
  // diagnosisId: number;
  description: string;
  type: ExercisesTypeDto;
  order_number: number;
  media_type: MediaType;
  media_url: string;
  image: string;
  repeats: number;
  intensity: number;
  minutes: number;
  tags?: string;
  name?: string;
};
