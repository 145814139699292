// import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import PrintIcon from '@mui/icons-material/Print';
import { QRCodeCanvas } from 'qrcode.react';
import { useLocation } from 'react-router-dom';

import { HeaderWithTriangles } from '../../../components/HeaderWithTriangles';
import globalStyles from '../../../styles/global.module.scss';
import styles from './PlanGenerated.module.scss';

const PlanGenerated = () => {
  const location = useLocation();

  if (location.state.physio_link && location.state.patient_link) {
    return (
      <div className={`${globalStyles.container} ${styles.container}`}>
        <HeaderWithTriangles
          title="SUKCES! Plan został wygenerowany"
          className={styles.header}
        />
        <div className={styles.successDesc}>
          Poniżej znajdziesz unikalny numer id, linki oraz QR kod. Przekaż
          pacjentowi plan w preferowanej
          <br /> formie i nie zapomiij zapisać danych w swojej dokumentacji
          medycznej
        </div>
        <div className={styles.linkType}>DLA CIEBIE:</div>
        <div>
          UNIKALNY LINK:{' '}
          {`${process.env.REACT_APP_API_HOST}/status-cwiczen-pacjenta/${location.state.physio_link}`}
        </div>
        <div className={styles.qrCode}>
          <QRCodeCanvas
            value={`${process.env.REACT_APP_API_HOST}/status-cwiczen-pacjenta/${location.state.physio_link}`}
          />
        </div>
        <div>UNIKALNE ID: {location.state.physio_link}</div>
        <div className={styles.qrCode}>
          <QRCodeCanvas value={location.state.physio_link} />
        </div>
        {/* <div className={styles.options}>
          <div className={styles.option}>
            <MailOutlineIcon className={styles.icon} /> Wyślij na email
          </div>
          <div className={styles.option}>
            <PrintIcon className={styles.icon} />
            Drukuj
          </div>
        </div> */}
        <div className={styles.linkType}>Link pacjenta</div>
        <div>
          UNIKALNY LINK:{' '}
          {`${process.env.REACT_APP_API_HOST}/plan-terapii/${location.state.patient_link}`}
        </div>
        <div className={styles.qrCode}>
          <QRCodeCanvas
            value={`${process.env.REACT_APP_API_HOST}/plan-terapii/${location.state.patient_link}`}
          />
        </div>
        <div>UNIKALNE ID: {location.state.patient_link}</div>
        <div className={styles.qrCode}>
          <QRCodeCanvas value={location.state.patient_link} />
        </div>
        {/* <div className={styles.options}>
          <div className={styles.option}>
            <MailOutlineIcon className={styles.icon} />
            Wyślij na email
          </div>
          <div className={styles.option}>
            <PrintIcon className={styles.icon} />
            Drukuj
          </div>
        </div> */}
      </div>
    );
  }
  return <div>Wystąpił błąd </div>;
};

export default PlanGenerated;
