/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, ThemeProvider } from '@mui/material';
import { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { CustomTextField } from '../../../components/CustomTextField';
import { customButtonThemeBig } from '../../../styles/buttons.mui';
import socket from '../socket';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 1000px) {
    flex-direction: row;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 35px;
`;

const JoinRoom = (props) => {
  const userRef = useRef();
  const navigate = useNavigate();
  const { roomId } = useParams();

  useEffect(() => {
    socket.on('FE-error-user-exist', ({ error }) => {
      if (!error) {
        const roomName = roomId;
        const userName = userRef.current.value;

        sessionStorage.setItem('user', userName);
        navigate(`/rozmowa/${roomName}`);
      } else {
        toast.error('To imię jest już zajęte');
      }
    });
  }, [props.history]);

  function clickJoin() {
    const roomName = roomId;
    const userName = userRef.current.value;
    console.log('clickJoin', process.env.REACT_APP_IO_ADDRESS, roomName);

    if (!roomName || !userName) {
      toast.error('Podaj swoje imię');
    } else {
      socket.emit('BE-check-user', { roomId: roomName, userName });
    }
  }

  return (
    <MainContainer>
      <Row>
        <CustomTextField
          id="userName"
          label="Twoje imię"
          variant="outlined"
          sx={{ margin: '0 10px 10px 0' }}
          inputRef={userRef}
        />
      </Row>
      <ThemeProvider theme={customButtonThemeBig}>
        <Button variant="contained" type="submit" onClick={clickJoin}>
          Dołącz
        </Button>
      </ThemeProvider>
    </MainContainer>
  );
};

export default JoinRoom;
