/* eslint-disable jsx-a11y/media-has-caption */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import { ExercisesDto, MediaType } from '../../../../api/models/ExercisesDto';
import styles from './DialogPreview.module.scss';

interface DialogPreviewProps {
  exercise: ExercisesDto;
  onCloseModal: any;
}

const DialogPreview = ({ exercise, onCloseModal }: DialogPreviewProps) => {
  const {
    id,
    media_type: mediaType,
    media_url: mediaUrl,
    description,
    minutes,
    repeats,
    // intensity,
  } = exercise;

  return (
    <Dialog open>
      <div className={styles.dialog}>
        <div className={styles.title}>
          {' '}
          <strong>
            {' '}
            {exercise.name ? exercise.name : `Ćwiczenie nr ${id}`}
          </strong>
        </div>
        <div className={styles.settings}>
          Minuty: {minutes}
          {` `}
          Powtórzenia: {repeats} {` `}
          {/* Intensywność: {intensity} {` `} */}
        </div>
        <div className={styles.description}>{description}</div>
        <div className={styles.video}>
          {mediaType === MediaType.VIDEO && (
            <div>
              <video width="240" height="320" controls>
                <source src={mediaUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
        </div>
      </div>
      <DialogActions>
        <Button onClick={onCloseModal}>Zamknij</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogPreview;
