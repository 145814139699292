/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { checkboxClasses } from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import { useMutation } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { DiagnosisDto } from '../../../api/models/DiagnosisDto';
import { createDiagnosis } from '../../../api/services/diagnosis';
import {
  dyspnoeaIcon,
  funcionalityIcon,
  heartIcon,
} from '../../../assets/icons';
import manBack from '../../../assets/images/pics/diagnoza/man_back.svg';
import manFront from '../../../assets/images/pics/diagnoza/man_front.svg';
import { CustomTextField } from '../../../components';
import { Path } from '../../../router/paths';
import { customButtonTheme } from '../../../styles/buttons.mui';
import globalStyles from '../../../styles/global.module.scss';
import {
  calculateBmi,
  checkBmi,
  checkDiastolicPressure,
  checkPulse,
  checkSystolicPressure,
  checkWhtr,
} from '../../../utils/checkNorms';
import parseStringToFloat from '../../../utils/parseStringToFloat';
import { DiagnosisResultType } from '../DiagnosisResult/DiagnosisResult.types';
import styles from './DiagnosisQuestions.module.scss';
import { FormikPart } from './DiagnosisQuestions.types';

const Diagnosis = () => {
  const style = {
    position: 'absolute' as 'absolute',
    left: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };
  const modalStyle = {
    overflowX: 'hidden',
    overflowY: 'auto',
  };
  const selectStyle = {
    whitespace: 'pre-wrap',
    width: '100%',
  };
  interface IDictionary {
    [index: string]: IDictionary1;
  }
  interface IDictionary1 {
    [index: string]: string;
  }
  const pain = {} as IDictionary;
  const painClass = {} as IDictionary1;
  painClass.head = ' ';
  painClass.neck = ' ';
  painClass.chest = ' ';
  painClass.belly = ' ';
  painClass.pelvis = ' ';
  painClass.leftHand = ' ';
  painClass.rightHand = ' ';
  painClass.leftArm = ' ';
  painClass.rightArm = ' ';
  painClass.headBack = ' ';

  painClass.leftFoot = ' ';
  painClass.rightFoot = ' ';
  painClass.lumbar = ' ';
  painClass.thoracic = ' ';

  painClass.leftPeace = ' ';
  painClass.rightPeace = ' ';

  painClass.leftThigh = ' ';
  painClass.rightThigh = ' ';

  painClass.leftTibia = ' ';
  painClass.rightTibia = ' ';

  const navigate = useNavigate();
  const [open, openModal] = useState(false);
  const [needsDoctor, setDoctor] = useState(false);
  const [part, setPart] = useState('');
  const [currentFormikPart, setCurrentFormikPart] = useState<FormikPart>();
  const { mutate } = useMutation({
    mutationFn: createDiagnosis,
    onSuccess: (response) => {
      navigate(`${Path.DIAGNOSIS_RESULT}`, {
        state: {
          type: DiagnosisResultType.NOT_OK,
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          needsDoctor,
          diagnosisUuid: response.data.unique_id,
          filledBy: window.location.href.includes(Path.DIAGNOSIS_BY_PHYSIO)
            ? 'physio'
            : 'patient',
        },
      });
    },
  });
  const checkIfPatientNeedsHelp = (results: any) => {
    const {
      sex,
      age,
      weight,
      height,
      waist,
      // hip,
      pulse,
      systolicPressure,
      diastolicPressure,
      heart,
      dyspnoea,
      // efficiency,
      functionality,
    } = results;

    const checkPatientPulse = checkPulse(
      parseStringToFloat(pulse),
      Number(age),
      Number(sex)
    );
    if (checkPatientPulse.result !== 'good') {
      setDoctor(true);
      return true;
    }

    const checkPatientSystolicPressure = checkSystolicPressure(
      parseStringToFloat(systolicPressure)
    );
    if (checkPatientSystolicPressure.result !== 'good') {
      setDoctor(true);
      return true;
    }

    const checkPatientDiastolicPressure = checkDiastolicPressure(
      parseStringToFloat(diastolicPressure)
    );
    if (checkPatientDiastolicPressure.result !== 'good') {
      setDoctor(true);
      return true;
    }

    const checkPatientBmi = checkBmi(
      calculateBmi(parseStringToFloat(weight), parseStringToFloat(height))
    );
    if (checkPatientBmi.result !== 'good') {
      setDoctor(true);
      return true;
    }
    if (pain.length) {
      return true;
    }

    const checkPatientWhtr = checkWhtr(
      parseStringToFloat(waist) / parseStringToFloat(height),
      sex
    );
    if (checkPatientWhtr.result !== 'good') {
      return true;
    }

    // const checkPatientWhr = checkWhr(
    //   parseStringToFloat(waist),
    //   parseStringToFloat(hip),
    //   sex
    // );
    // if (checkPatientWhr.result !== 'good') {
    //   return true;
    // }

    // const checkPatientEfficiencyNorms = checkEfficiencyNorms(
    //   Number(efficiency)
    // );
    // if (checkPatientEfficiencyNorms.result !== 'good') {
    //   return true;
    // }

    if (
      parseStringToFloat(heart) > 1 ||
      parseStringToFloat(dyspnoea) > 1 ||
      parseStringToFloat(functionality) > 1
    ) {
      return true;
    }

    return false;
  };

  const formik = useFormik({
    initialValues: {
      sex: '',
      age: '',
      weight: '',
      height: '',
      waist: '', // obwód talii
      hip: '', // obów bioder,
      pulse: '',
      systolicPressure: '',
      diastolicPressure: '',
      heart: '',
      dyspnoea: '', // duszność
      efficiency: '', // wydolność
      functionality: '',
      // pain
      // head
      painIntensityHead: '',
      painTimeHead: '',
      painTime2Head: '',
      painMedicinesHead: '',
      painLimitsHead: '',
      painLengthHead: '',
      painTypeHead: '',
      // neck
      painIntensityNeck: '',
      painTimeNeck: '',
      painTime2Neck: '',
      painMedicinesNeck: '',
      painLimitsNeck: '',
      painLengthNeck: '',
      painTypeNeck: '',
      // chest
      painIntensityChest: '',
      painTimeChest: '',
      painTime2Chest: '',
      painMedicinesChest: '',
      painLimitsChest: '',
      painLengthChest: '',
      painTypeChest: '',
      // belly
      painIntensityBelly: '',
      painTimeBelly: '',
      painTime2Belly: '',
      painMedicinesBelly: '',
      painLimitsBelly: '',
      painLengthBelly: '',
      painTypeBelly: '',
      // pelvis
      painIntensityPelvis: '',
      painTimePelvis: '',
      painTime2Pelvis: '',
      painMedicinesPelvis: '',
      painLimitsPelvis: '',
      painLengthPelvis: '',
      painTypePelvis: '',
      // leftHand
      painIntensityLeftHand: '',
      painTimeLeftHand: '',
      painTime2LeftHand: '',
      painMedicinesLeftHand: '',
      painLimitsLeftHand: '',
      painLengthLeftHand: '',
      painTypeLeftHand: '',
      // rightHand
      painIntensityRightHand: '',
      painTimeRightHand: '',
      painTime2RightHand: '',
      painMedicinesRightHand: '',
      painLimitsRightHand: '',
      painLengthRightHand: '',
      painTypeRightHand: '',
      // leftArm
      painIntensityLeftArm: '',
      painTimeLeftArm: '',
      painTime2LeftArm: '',
      painMedicinesLeftArm: '',
      painLimitsLeftArm: '',
      painLengthLeftArm: '',
      painTypeLeftArm: '',
      // rightArm
      painIntensityRightArm: '',
      painTimeRightArm: '',
      painTime2RightArm: '',
      painMedicinesRightArm: '',
      painLimitsRightArm: '',
      painLengthRightArm: '',
      painTypeRightArm: '',
      // headBack
      painIntensityHeadBack: '',
      painTimeHeadBack: '',
      painTime2HeadBack: '',
      painMedicinesHeadBack: '',
      painLimitsHeadBack: '',
      painLengthHeadBack: '',
      painTypeHeadBack: '',
      // leftFoot
      painIntensityLeftFoot: '',
      painTimeLeftFoot: '',
      painTime2LeftFoot: '',
      painMedicinesLeftFoot: '',
      painLimitsLeftFoot: '',
      painLengthLeftFoot: '',
      painTypeLeftFoot: '',
      // rightFoot
      painIntensityRightFoot: '',
      painTimeRightFoot: '',
      painTime2RightFoot: '',
      painMedicinesRightFoot: '',
      painLimitsRightFoot: '',
      painLengthRightFoot: '',
      painTypeRightFoot: '',
      // lumbar
      painIntensityLumbar: '',
      painTimeLumbar: '',
      painTime2Lumbar: '',
      painMedicinesLumbar: '',
      painLimitsLumbar: '',
      painLengthLumbar: '',
      painTypeLumbar: '',
      // thoracic
      painIntensityThoracic: '',
      painTimeThoracic: '',
      painTime2Thoracic: '',
      painMedicinesThoracic: '',
      painLimitsThoracic: '',
      painLengthThoracic: '',
      painTypeThoracic: '',
      // leftPeace
      painIntensityLeftPeace: '',
      painTimeLeftPeace: '',
      painTime2LeftPeace: '',
      painMedicinesLeftPeace: '',
      painLimitsLeftPeace: '',
      painLengthLeftPeace: '',
      painTypeLeftPeace: '',
      // rightPeace
      painIntensityRightPeace: '',
      painTimeRightPeace: '',
      painTime2RightPeace: '',
      painMedicinesRightPeace: '',
      painLimitsRightPeace: '',
      painLengthRightPeace: '',
      painTypeRightPeace: '',
      painIntensityLeftThigh: '',
      painTimeLeftThigh: '',
      painTime2LeftThigh: '',
      painMedicinesLeftThigh: '',
      painLimitsLeftThigh: '',
      painLengthLeftThigh: '',
      painTypeLeftThigh: '',
      painIntensityRightThigh: '',
      painTimeRightThigh: '',
      painTime2RightThigh: '',
      painMedicinesRightThigh: '',
      painLimitsRightThigh: '',
      painLengthRightThigh: '',
      painTypeRightThigh: '',
      painIntensityLeftTibia: '',
      painTimeLeftTibia: '',
      painTime2LeftTibia: '',
      painMedicinesLeftTibia: '',
      painLimitsLeftTibia: '',
      painLengthLeftTibia: '',
      painTypeLeftTibia: '',
      painIntensityRightTibia: '',
      painTimeRightTibia: '',
      painTime2RightTibia: '',
      painMedicinesRightTibia: '',
      painLimitsRightTibia: '',
      painLengthRightTibia: '',
      painTypeRightTibia: '',
    },
    onSubmit: () => {
      if (checkIfPatientNeedsHelp(formik.values)) {
        const postValues: DiagnosisDto = {
          age: Number(formik.values.age),
          sex: Number(formik.values.sex),
          weight: parseStringToFloat(formik.values.weight),
          height: parseStringToFloat(formik.values.height),
          waist: parseStringToFloat(formik.values.waist),
          hip: parseStringToFloat(formik.values.hip),
          pulse: parseStringToFloat(formik.values.pulse),
          systolicPressure: parseStringToFloat(formik.values.systolicPressure),
          diastolicPressure: parseStringToFloat(
            formik.values.diastolicPressure
          ),
          heart: parseStringToFloat(formik.values.heart),
          dyspnoea: parseStringToFloat(formik.values.dyspnoea),
          efficiency: parseStringToFloat(formik.values.efficiency),
          functionality: parseStringToFloat(formik.values.functionality),
          pain: [],
          create_time: new Date(),
          filledBy: window.location.href.includes(Path.DIAGNOSIS_BY_PHYSIO)
            ? 2
            : 1,
          // @TODO change to one propery per group
          // pain
          // head
          painIntensityHead: Number(formik.values.painIntensityHead),
          painTimeHead: Number(formik.values.painTimeHead),
          painTime2Head: Number(formik.values.painTime2Head),
          painMedicinesHead: Number(formik.values.painMedicinesHead),
          painLimitsHead: Number(formik.values.painLimitsHead),
          painLengthHead: Number(formik.values.painLengthHead),
          painTypeHead: Number(formik.values.painTypeHead),
          // neck
          painIntensityNeck: Number(formik.values.painIntensityNeck),
          painTimeNeck: Number(formik.values.painTimeNeck),
          painTime2Neck: Number(formik.values.painTime2Neck),
          painMedicinesNeck: Number(formik.values.painMedicinesNeck),
          painLimitsNeck: Number(formik.values.painLimitsNeck),
          painLengthNeck: Number(formik.values.painLengthNeck),
          painTypeNeck: Number(formik.values.painTypeNeck),
          // chest
          painIntensityChest: Number(formik.values.painIntensityChest),
          painTimeChest: Number(formik.values.painTimeChest),
          painTime2Chest: Number(formik.values.painTime2Chest),
          painMedicinesChest: Number(formik.values.painMedicinesChest),
          painLimitsChest: Number(formik.values.painLimitsChest),
          painLengthChest: Number(formik.values.painLengthChest),
          painTypeChest: Number(formik.values.painTypeChest),
          // belly
          painIntensityBelly: Number(formik.values.painIntensityBelly),
          painTimeBelly: Number(formik.values.painTimeBelly),
          painTime2Belly: Number(formik.values.painTime2Belly),
          painMedicinesBelly: Number(formik.values.painMedicinesBelly),
          painLimitsBelly: Number(formik.values.painLimitsBelly),
          painLengthBelly: Number(formik.values.painLengthBelly),
          painTypeBelly: Number(formik.values.painTypeBelly),
          // pelvis
          painIntensityPelvis: Number(formik.values.painIntensityPelvis),
          painTimePelvis: Number(formik.values.painTimePelvis),
          painTime2Pelvis: Number(formik.values.painTime2Pelvis),
          painMedicinesPelvis: Number(formik.values.painMedicinesPelvis),
          painLimitsPelvis: Number(formik.values.painLimitsPelvis),
          painLengthPelvis: Number(formik.values.painLengthPelvis),
          painTypePelvis: Number(formik.values.painTypePelvis),
          // leftHand
          painIntensityLeftHand: Number(formik.values.painIntensityLeftHand),
          painTimeLeftHand: Number(formik.values.painTimeLeftHand),
          painTime2LeftHand: Number(formik.values.painTime2LeftHand),
          painMedicinesLeftHand: Number(formik.values.painMedicinesLeftHand),
          painLimitsLeftHand: Number(formik.values.painLimitsLeftHand),
          painLengthLeftHand: Number(formik.values.painLengthLeftHand),
          painTypeLeftHand: Number(formik.values.painTypeLeftHand),
          // rightHand
          painIntensityRightHand: Number(formik.values.painIntensityRightHand),
          painTimeRightHand: Number(formik.values.painTimeRightHand),
          painTime2RightHand: Number(formik.values.painTime2RightHand),
          painMedicinesRightHand: Number(formik.values.painMedicinesRightHand),
          painLimitsRightHand: Number(formik.values.painLimitsRightHand),
          painLengthRightHand: Number(formik.values.painLengthRightHand),
          painTypeRightHand: Number(formik.values.painTypeRightHand),
          // leftArm
          painIntensityLeftArm: Number(formik.values.painIntensityLeftArm),
          painTimeLeftArm: Number(formik.values.painTimeLeftArm),
          painTime2LeftArm: Number(formik.values.painTime2LeftArm),
          painMedicinesLeftArm: Number(formik.values.painMedicinesLeftArm),
          painLimitsLeftArm: Number(formik.values.painLimitsLeftArm),
          painLengthLeftArm: Number(formik.values.painLengthLeftArm),
          painTypeLeftArm: Number(formik.values.painTypeLeftArm),
          // rightArm
          painIntensityRightArm: Number(formik.values.painIntensityRightArm),
          painTimeRightArm: Number(formik.values.painTimeRightArm),
          painTime2RightArm: Number(formik.values.painTime2RightArm),
          painMedicinesRightArm: Number(formik.values.painMedicinesRightArm),
          painLimitsRightArm: Number(formik.values.painLimitsRightArm),
          painLengthRightArm: Number(formik.values.painLengthRightArm),
          painTypeRightArm: Number(formik.values.painTypeRightArm),
          // headBack
          painIntensityHeadBack: Number(formik.values.painIntensityHeadBack),
          painTimeHeadBack: Number(formik.values.painTimeHeadBack),
          painTime2HeadBack: Number(formik.values.painTime2HeadBack),
          painMedicinesHeadBack: Number(formik.values.painMedicinesHeadBack),
          painLimitsHeadBack: Number(formik.values.painLimitsHeadBack),
          painLengthHeadBack: Number(formik.values.painLengthHeadBack),
          painTypeHeadBack: Number(formik.values.painTypeHeadBack),
          // leftFoot
          painIntensityLeftFoot: Number(formik.values.painIntensityLeftFoot),
          painTimeLeftFoot: Number(formik.values.painTimeLeftFoot),
          painTime2LeftFoot: Number(formik.values.painTime2LeftFoot),
          painMedicinesLeftFoot: Number(formik.values.painMedicinesLeftFoot),
          painLimitsLeftFoot: Number(formik.values.painLimitsLeftFoot),
          painLengthLeftFoot: Number(formik.values.painLengthLeftFoot),
          painTypeLeftFoot: Number(formik.values.painTypeLeftFoot),
          // rightFoot
          painIntensityRightFoot: Number(formik.values.painIntensityRightFoot),
          painTimeRightFoot: Number(formik.values.painTimeRightFoot),
          painTime2RightFoot: Number(formik.values.painTime2RightFoot),
          painMedicinesRightFoot: Number(formik.values.painMedicinesRightFoot),
          painLimitsRightFoot: Number(formik.values.painLimitsRightFoot),
          painLengthRightFoot: Number(formik.values.painLengthRightFoot),
          painTypeRightFoot: Number(formik.values.painTypeRightFoot),
          // lumbar
          painIntensityLumbar: Number(formik.values.painIntensityLumbar),
          painTimeLumbar: Number(formik.values.painTimeLumbar),
          painTime2Lumbar: Number(formik.values.painTime2Lumbar),
          painMedicinesLumbar: Number(formik.values.painMedicinesLumbar),
          painLimitsLumbar: Number(formik.values.painLimitsLumbar),
          painLengthLumbar: Number(formik.values.painLengthLumbar),
          painTypeLumbar: Number(formik.values.painTypeLumbar),
          // thoracic
          painIntensityThoracic: Number(formik.values.painIntensityThoracic),
          painTimeThoracic: Number(formik.values.painTimeThoracic),
          painTime2Thoracic: Number(formik.values.painTime2Thoracic),
          painMedicinesThoracic: Number(formik.values.painMedicinesThoracic),
          painLimitsThoracic: Number(formik.values.painLimitsThoracic),
          painLengthThoracic: Number(formik.values.painLengthThoracic),
          painTypeThoracic: Number(formik.values.painTypeThoracic),
          // leftPeace
          painIntensityLeftPeace: Number(formik.values.painIntensityLeftPeace),
          painTimeLeftPeace: Number(formik.values.painTimeLeftPeace),
          painTime2LeftPeace: Number(formik.values.painTime2LeftPeace),
          painMedicinesLeftPeace: Number(formik.values.painMedicinesLeftPeace),
          painLimitsLeftPeace: Number(formik.values.painLimitsLeftPeace),
          painLengthLeftPeace: Number(formik.values.painLengthLeftPeace),
          painTypeLeftPeace: Number(formik.values.painTypeLeftPeace),
          // rightPeace
          painIntensityRightPeace: Number(
            formik.values.painIntensityRightPeace
          ),
          painTimeRightPeace: Number(formik.values.painTimeRightPeace),
          painTime2RightPeace: Number(formik.values.painTime2RightPeace),
          painMedicinesRightPeace: Number(
            formik.values.painMedicinesRightPeace
          ),
          painLimitsRightPeace: Number(formik.values.painLimitsRightPeace),
          painLengthRightPeace: Number(formik.values.painLengthRightPeace),
          painTypeRightPeace: Number(formik.values.painTypeRightPeace),
          painIntensityLeftThigh: Number(formik.values.painIntensityLeftThigh),
          painTimeLeftThigh: Number(formik.values.painTimeLeftThigh),
          painTime2LeftThigh: Number(formik.values.painTime2LeftThigh),
          painMedicinesLeftThigh: Number(formik.values.painMedicinesLeftThigh),
          painLimitsLeftThigh: Number(formik.values.painLimitsLeftThigh),
          painLengthLeftThigh: Number(formik.values.painLengthLeftThigh),
          painTypeLeftThigh: Number(formik.values.painTypeLeftThigh),
          painIntensityRightThigh: Number(
            formik.values.painIntensityRightThigh
          ),
          painTimeRightThigh: Number(formik.values.painTimeRightThigh),
          painTime2RightThigh: Number(formik.values.painTime2RightThigh),
          painMedicinesRightThigh: Number(
            formik.values.painMedicinesRightThigh
          ),
          painLimitsRightThigh: Number(formik.values.painLimitsRightThigh),
          painLengthRightThigh: Number(formik.values.painLengthRightThigh),
          painTypeRightThigh: Number(formik.values.painTypeRightThigh),
          painIntensityLeftTibia: Number(formik.values.painIntensityLeftTibia),
          painTimeLeftTibia: Number(formik.values.painTimeLeftTibia),
          painTime2LeftTibia: Number(formik.values.painTime2LeftTibia),
          painMedicinesLeftTibia: Number(formik.values.painMedicinesLeftTibia),
          painLimitsLeftTibia: Number(formik.values.painLimitsLeftTibia),
          painLengthLeftTibia: Number(formik.values.painLengthLeftTibia),
          painTypeLeftTibia: Number(formik.values.painTypeLeftTibia),
          painIntensityRightTibia: Number(
            formik.values.painIntensityRightTibia
          ),
          painTimeRightTibia: Number(formik.values.painTimeRightTibia),
          painTime2RightTibia: Number(formik.values.painTime2RightTibia),
          painMedicinesRightTibia: Number(
            formik.values.painMedicinesRightTibia
          ),
          painLimitsRightTibia: Number(formik.values.painLimitsRightTibia),
          painLengthRightTibia: Number(formik.values.painLengthRightTibia),
          painTypeRightTibia: Number(formik.values.painTypeRightTibia),
        };
        mutate({
          ...postValues,
        });
      } else {
        navigate(`${Path.DIAGNOSIS_RESULT}`, {
          state: {
            type: DiagnosisResultType.OK,
            needsDoctor,
          },
        });
      }
      // alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {}, [formik.values]);

  const openDiagnosisModal = (activePart: string, parts: FormikPart) => {
    setPart(activePart);
    setCurrentFormikPart(parts);
    openModal(true);
  };

  const closeDiagnosisModal = (activePart: string) => {
    setPart(activePart);
    const element = document.getElementById(`${part}`);
    if (element) {
      element.classList.remove(styles.DiagnosisQuestions_diagnosis_man_active);
    }
    openModal(false);
  };

  const setFieldValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string
  ) => {
    formik.setFieldValue(field, e.target.value.replace('.', ','));
  };
  const pageType = window.location.href.includes(Path.DIAGNOSIS_BY_PHYSIO)
    ? 'physio'
    : 'patient';
  return (
    <>
      {' '}
      <Helmet>
        <title>Ankieta wydolności - Zdalne Konsultacje KIF</title>
      </Helmet>
      <div className={`${globalStyles.container} ${styles.container}`}>
        <div className={styles.header}>Ankieta wydolności</div>

        <div className={styles.textH}>
          {pageType === 'physio' ? (
            <div>
              {' '}
              Wypełnij poniższą ankietę, aby poznać ocenę wydolności pacjenta.
              <br />
              <span style={{ color: '#cc0000', fontWeight: 'bold' }}>
                UWAGA: Niniejsza ankieta wydolności nie zastępuje wizyty u
                fizjoterapeuty i nie pozwala na postawienie diagnozy
              </span>
            </div>
          ) : (
            <div>
              {' '}
              Wypełnij poniższą ankietę, aby poznać ocenę wydolności. Pamiętaj,
              że ankieta nie zastępuje wizyty u lekarza ani wizyty u
              fizjoterapeuty. Po wypełnieniu ankiety będziesz mógł umówić się na
              wizytę u fizjoterapeuty, otrzymasz unikalne ID ankiety, które
              przekażesz na spotkaniu, a fizjoterapeuta na podstawie wyników
              ankiety oraz wywiadu przeprowadzonego na wizycie przygotuje dla
              Ciebie plan ćwiczeń na naszej platformie
              <br />
              <span style={{ color: '#cc0000', fontWeight: 'bold' }}>
                UWAGA: Niniejsza ankieta wydolności nie zastępuje wizyty u
                fizjoterapeuty i nie pozwala na pełną diagnozę pacjenta
              </span>
            </div>
          )}
        </div>

        <div>Wpisz w rubrykach odpowiednie dane</div>

        <div className={`${globalStyles.container} ${styles.container}`}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.form}>
              <FormControl sx={{ minWidth: 130, margin: '0 10px 0 0' }}>
                <InputLabel id="sex">Płeć</InputLabel>
                <Select
                  name="sex"
                  id="sex"
                  value={formik.values.sex}
                  label="sex"
                  sx={{ margin: '0 10px 10px 0' }}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={1}>Kobieta</MenuItem>
                  <MenuItem value={2}>Mężczyzna</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ width: 80 }}>
                <InputLabel id="age">Wiek</InputLabel>

                <Select
                  name="age"
                  id="age"
                  value={formik.values.age}
                  label="Age"
                  sx={{ margin: '0 10px 10px 0' }}
                  onChange={formik.handleChange}
                >
                  {Array.from(Array(50))
                    .map((e, i) => i + 18)
                    .map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <div className={styles.desktopSeparator} />

              <CustomTextField
                id="weight"
                label="Masa ciała (kg)"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0', width: 140 }}
                onChange={(e) => {
                  setFieldValue(e, 'weight');
                }}
                value={formik.values.weight}
              />
              <CustomTextField
                id="height"
                label="Wysokość ciała (cm)"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0', width: 180 }}
                onChange={(e) => {
                  setFieldValue(e, 'height');
                }}
                value={formik.values.height}
              />

              <CustomTextField
                id="waist"
                label="Obwód talii (cm)"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0', width: 160 }}
                onChange={(e) => {
                  setFieldValue(e, 'waist');
                }}
                value={formik.values.waist}
              />
              {/* <CustomTextField
              id="hip"
              label="Obwód bioder (cm)"
              variant="outlined"
              sx={{ margin: '0 10px 10px 0', width: 170 }}
              onChange={(e) => {
                setFieldValue(e, 'hip');
              }}
              value={formik.values.hip}
            /> */}
              <CustomTextField
                id="pulse"
                label="Tętno spoczynkowe"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0', width: 170 }}
                onChange={(e) => {
                  setFieldValue(e, 'pulse');
                }}
                value={formik.values.pulse}
              />

              <div className={styles.desktopSeparatorAsBr} />

              <div className={styles.group}>
                <div className={styles.groupFlex}>
                  <div style={{ width: 170, marginLeft: 70 }}>
                    <div className={styles.groupResult}>
                      <div className={styles.groupResultType}>BMI</div>
                      {formik.values.height && formik.values.weight && (
                        <div
                          className={`${styles.groupResultBox} ${
                            checkBmi(
                              calculateBmi(
                                parseStringToFloat(formik.values.weight),
                                parseStringToFloat(formik.values.height)
                              )
                            )?.result
                              ? styles[
                                  checkBmi(
                                    calculateBmi(
                                      parseStringToFloat(formik.values.weight),
                                      parseStringToFloat(formik.values.height)
                                    )
                                  )?.result
                                ]
                              : ''
                          }`}
                        >
                          {checkBmi(
                            calculateBmi(
                              parseStringToFloat(formik.values.weight),
                              parseStringToFloat(formik.values.height)
                            )
                          )?.name
                            ? checkBmi(
                                calculateBmi(
                                  parseStringToFloat(formik.values.weight),
                                  parseStringToFloat(formik.values.height)
                                )
                              ).name
                            : 'Niepoprawny wynik'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ width: 170, marginLeft: 0 }}>
                    <div className={styles.groupResult}>
                      <div className={styles.groupResultType}>Whtr</div>
                      {formik.values.waist && formik.values.height && (
                        <div
                          className={`${styles.groupResultBox} ${
                            checkWhtr(
                              parseStringToFloat(formik.values.waist) /
                                parseStringToFloat(formik.values.height),
                              Number(formik.values.sex)
                            )?.result
                              ? styles[
                                  checkWhtr(
                                    parseStringToFloat(formik.values.waist) /
                                      parseStringToFloat(formik.values.height),
                                    Number(formik.values.sex)
                                  )?.result
                                ]
                              : ''
                          }`}
                        >
                          {checkWhtr(
                            parseStringToFloat(formik.values.waist) /
                              parseStringToFloat(formik.values.height),
                            Number(formik.values.sex)
                          )?.name
                            ? checkWhtr(
                                parseStringToFloat(formik.values.waist) /
                                  parseStringToFloat(formik.values.height),
                                Number(formik.values.sex)
                              ).name
                            : 'Niepoprawny wynik'}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div style={{ width: 170, marginLeft: 0 }}>
                  <div className={styles.groupResult}>
                    <div className={styles.groupResultType}>Whr</div>
                    {formik.values.waist && formik.values.hip && (
                      <div
                        className={`${styles.groupResultBox} ${
                          checkWhr(
                            parseStringToFloat(formik.values.waist),
                            parseStringToFloat(formik.values.hip),
                            Number(formik.values.sex)
                          )?.result
                            ? styles[
                                checkWhr(
                                  parseStringToFloat(formik.values.waist),
                                  parseStringToFloat(formik.values.hip),
                                  Number(formik.values.sex)
                                )?.result
                              ]
                            : ''
                        }`}
                      >
                        {checkWhr(
                          parseStringToFloat(formik.values.waist),
                          parseStringToFloat(formik.values.hip),
                          Number(formik.values.sex)
                        )?.name
                          ? checkWhr(
                              parseStringToFloat(formik.values.waist),
                              parseStringToFloat(formik.values.hip),
                              Number(formik.values.sex)
                            ).name
                          : 'Niepoprawny wynik'}
                      </div>
                    )}
                  </div>
                </div> */}
                  <div style={{ width: 170, marginLeft: 110 }}>
                    {formik.values.pulse &&
                      formik.values.age &&
                      formik.values.sex && (
                        <div className={styles.singleResult}>
                          <div className={styles.singleResultLine} />
                          <div
                            className={`${styles.groupResultBox} ${
                              checkPulse(
                                parseStringToFloat(formik.values.pulse),
                                Number(formik.values.age),
                                Number(formik.values.sex)
                              )?.result
                                ? styles[
                                    checkPulse(
                                      parseStringToFloat(formik.values.pulse),
                                      Number(formik.values.age),
                                      Number(formik.values.sex)
                                    )?.result
                                  ]
                                : ''
                            }`}
                          >
                            {checkPulse(
                              parseStringToFloat(formik.values.pulse),
                              Number(formik.values.age),
                              Number(formik.values.sex)
                            )?.name
                              ? checkPulse(
                                  parseStringToFloat(formik.values.pulse),
                                  Number(formik.values.age),
                                  Number(formik.values.sex)
                                ).name
                              : 'Niepoprawny wynik'}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>

              <div className={styles.desktopSeparator} />

              <CustomTextField
                id="systolicPressure"
                label="Ciśnienie skurczowe"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0' }}
                onChange={(e) => {
                  setFieldValue(e, 'systolicPressure');
                }}
                value={formik.values.systolicPressure}
              />

              <CustomTextField
                id="diastolicPressure"
                label="Ciśnienie rozkurczowe"
                variant="outlined"
                sx={{ margin: '0 10px 10px 0' }}
                onChange={(e) => {
                  setFieldValue(e, 'diastolicPressure');
                }}
                value={formik.values.diastolicPressure}
              />

              <br />
              <div className={styles.group}>
                <div className={styles.groupFlex}>
                  <div style={{ width: 120, marginLeft: 30 }}>
                    {formik.values.systolicPressure && (
                      <div className={styles.singleResult}>
                        <div className={styles.singleResultLine} />
                        <div
                          className={`${styles.groupResultBox} ${
                            checkSystolicPressure(
                              parseStringToFloat(formik.values.systolicPressure)
                            )?.result
                              ? styles[
                                  checkSystolicPressure(
                                    parseStringToFloat(
                                      formik.values.systolicPressure
                                    )
                                  )?.result
                                ]
                              : ''
                          }`}
                        >
                          {checkSystolicPressure(
                            parseStringToFloat(formik.values.systolicPressure)
                          )?.name
                            ? checkSystolicPressure(
                                parseStringToFloat(
                                  formik.values.systolicPressure
                                )
                              ).name
                            : 'Niepoprawny wynik'}
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ width: 120, marginLeft: 80 }}>
                    {formik.values.diastolicPressure && (
                      <div className={styles.singleResult}>
                        <div className={styles.singleResultLine} />
                        <div
                          className={`${styles.groupResultBox} ${
                            checkDiastolicPressure(
                              parseStringToFloat(
                                formik.values.diastolicPressure
                              )
                            )?.result
                              ? styles[
                                  checkDiastolicPressure(
                                    parseStringToFloat(
                                      formik.values.diastolicPressure
                                    )
                                  )?.result
                                ]
                              : ''
                          }`}
                        >
                          {checkDiastolicPressure(
                            parseStringToFloat(formik.values.diastolicPressure)
                          )?.name
                            ? checkDiastolicPressure(
                                parseStringToFloat(
                                  formik.values.diastolicPressure
                                )
                              ).name
                            : 'Poza zakresem'}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <br />
              <br />

              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <img src={heartIcon} alt="" className={styles.sectionIcon} />
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>Serce</div>
                  </div>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      className={styles.formControlLabelWithDesc}
                      control={
                        <Checkbox
                          checked={formik.values.heart === '1'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'heart',
                              value.target.checked ? '1' : ''
                            );
                          }}
                          name="heart"
                          value="1"
                          color="success"
                        />
                      }
                      label={
                        <>
                          <div
                            className={styles.optionTitle}
                            style={
                              formik.values.heart === '1'
                                ? { color: '#00B815' }
                                : {}
                            }
                          >
                            Pełna wydolność fizyczna
                          </div>
                          <div className={styles.optionDesc}>
                            Zwykły wysiłek fizyczny nie powoduje większego
                            zmęczenia, duszności ani kołatania serca
                          </div>
                        </>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabelWithDesc}
                      control={
                        <Checkbox
                          checked={formik.values.heart === '2'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'heart',
                              value.target.checked ? '2' : ''
                            );
                          }}
                          name="heart"
                          value="2"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#ffc107',
                            },
                          }}
                        />
                      }
                      label={
                        <>
                          <div
                            className={styles.optionTitle}
                            style={
                              formik.values.heart === '2'
                                ? { color: '#ffc107' }
                                : {}
                            }
                          >
                            Niewielkie ograniczenie aktywności fizycznej
                          </div>
                          <div className={styles.optionDesc}>
                            Bez dolegliwości w spoczynku, ale zmęczenie,
                            duszność lub kołatanie serca pojawia się w czasie
                            zwykłej aktywności
                          </div>
                        </>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabelWithDesc}
                      control={
                        <Checkbox
                          checked={formik.values.heart === '3'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'heart',
                              value.target.checked ? '3' : ''
                            );
                          }}
                          name="heart"
                          value="3"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <>
                          <div
                            className={styles.optionTitle}
                            style={
                              formik.values.heart === '3'
                                ? { color: 'red' }
                                : {}
                            }
                          >
                            Średnie ograniczenie aktywności fizycznej
                          </div>
                          <div className={styles.optionDesc}>
                            Bez dolegliwości w spoczynku, ale mniejsza niż
                            zwykle aktywność wyzwala duszność, zmęczenie lub
                            kołatanie serca
                          </div>
                        </>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabelWithDesc}
                      control={
                        <Checkbox
                          checked={formik.values.heart === '4'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'heart',
                              value.target.checked ? '4' : ''
                            );
                          }}
                          name="heart"
                          value="4"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <>
                          <div
                            className={styles.optionTitle}
                            style={
                              formik.values.heart === '4'
                                ? { color: 'red' }
                                : {}
                            }
                          >
                            Znaczne ograniczenie aktywności fizycznej
                          </div>
                          <div className={styles.optionDesc}>
                            Najmniejszy wysiłek nasila dolegliwości, ale objawy
                            duszności, zmęczenia pojawiają się nawet w spoczynku
                          </div>
                        </>
                      }
                    />
                  </FormGroup>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <img
                    src={dyspnoeaIcon}
                    alt=""
                    className={styles.sectionIcon}
                  />
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>Duszność</div>
                  </div>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '1'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '1' : ''
                            );
                          }}
                          name="dysponea"
                          value="1"
                          color="success"
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '1'
                              ? { color: '#00B815' }
                              : {}
                          }
                        >
                          Duży wysiłek fizyczny nie powoduje uczucia duszności
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '2'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '2' : ''
                            );
                          }}
                          name="dysponea"
                          value="2"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#ffc107',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '2'
                              ? { color: '#ffc107' }
                              : {}
                          }
                        >
                          Duszność występuje jedynie podczas dużego wysiłku
                          fizycznego
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '3'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '3' : ''
                            );
                          }}
                          name="dyspnoea"
                          value="3"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#ffc107',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '3'
                              ? { color: '#ffc107' }
                              : {}
                          }
                        >
                          Duszność występuje podczas szybkiego marszu po płaskim
                          terenie lub wchodzenia na niewielkie wzniesienie
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '4'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '4' : ''
                            );
                          }}
                          name="dyspnoea"
                          value="4"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '4'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          Z powodu duszności wymuszone jest wolniejsze chodzenie
                          w porównaniu do rówieśników, lub idąc we własnym
                          tempie po płaskim terenie musimy się zatrzymać i
                          nabrać tchu
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '5'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '5' : ''
                            );
                          }}
                          name="dyspnoea"
                          value="5"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '5'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          Po przejściu około 100 m lub po kilku minutach marszu
                          po płaskim terenie konieczne jest zatrzymanie się aby
                          nabrać tchu
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.dyspnoea === '6'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'dyspnoea',
                              value.target.checked ? '6' : ''
                            );
                          }}
                          name="dyspnoea"
                          value="6"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.dyspnoea === '6'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          Duszność uniemożliwia opuszczenie domu lub występuje
                          również podczas ubierania się lub rozbierania
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              {/* <div className={styles.section}>
              <div className={styles.sectionTop}>
                <img
                  src={efficiencyIcon}
                  alt=""
                  className={styles.sectionIcon}
                />
                <div className={styles.sectionHeader}>
                  <div className={styles.sectionTitle}>Wydolność</div>
                  <div className={styles.sectionDesc}>
                    TEST „ WSTAŃ I IDŹ” - SŁUZY DO OCENY SPRAWNOŚCI
                    FUNKCJONALNEJ I RYZYKA UPADKU. Wykonaj poniższe kroki w
                    naturalnym tempie mierząc czas. Uruchom stoper przed
                    wstaniem.
                  </div>
                </div>
              </div>
              <div>
                <ol>
                  <li>Wstań z krzesła</li>
                  <li>Pokonaj w normalnym tempie dystans 3. metrów</li>
                  <li>Przekrocz linię kończącą wyznaczony dystans</li>
                  <li>Wykonaj obrót o 180 stopni</li>
                  <li>Wróć do krzesła i ponownie przyjmij pozycję siedzącą</li>
                </ol>
                <div style={{ margin: '0 0 0 25px' }}>
                  <p>Wpisz wynik w sekundach</p>
                  <CustomTextField
                    id="efficiency"
                    label="Sekundy"
                    variant="outlined"
                    sx={{ margin: '0 10px 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.efficiency}
                  />
                </div>
              </div>
            </div> */}
              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <img
                    src={funcionalityIcon}
                    alt=""
                    className={styles.sectionIcon}
                  />
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>Funkcja</div>
                  </div>
                </div>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.functionality === '1'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'functionality',
                              value.target.checked ? '1' : ''
                            );
                          }}
                          name="functionality"
                          value="1"
                          color="success"
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.functionality === '1'
                              ? { color: '#00B815' }
                              : {}
                          }
                        >
                          Nie mam ograniczeń, moja sprawność jest pełna
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.functionality === '2'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'functionality',
                              value.target.checked ? '2' : ''
                            );
                          }}
                          name="functionality"
                          value="2"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: '#ffc107',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.functionality === '2'
                              ? { color: '#ffc107' }
                              : {}
                          }
                        >
                          Mam nieznaczne ograniczenia
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.functionality === '3'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'functionality',
                              value.target.checked ? '3' : ''
                            );
                          }}
                          name="functionality"
                          value="3"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.functionality === '3'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          Mam znaczne ograniczenia
                        </div>
                      }
                    />
                    <FormControlLabel
                      className={styles.formControlLabel}
                      control={
                        <Checkbox
                          checked={formik.values.functionality === '4'}
                          onChange={(value) => {
                            formik.setFieldValue(
                              'functionality',
                              value.target.checked ? '4' : ''
                            );
                          }}
                          name="functionality"
                          value="4"
                          sx={{
                            [`&.${checkboxClasses.checked}`]: {
                              color: 'red',
                            },
                          }}
                        />
                      }
                      label={
                        <div
                          className={styles.optionTitle}
                          style={
                            formik.values.functionality === '4'
                              ? { color: 'red' }
                              : {}
                          }
                        >
                          Wymagam pomocy osób trzecich
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.sectionTop}>
                  <img
                    src={funcionalityIcon}
                    alt=""
                    className={styles.sectionIcon}
                  />
                  <div className={styles.sectionHeader}>
                    <div className={styles.sectionTitle}>Ból</div>
                    <div className={styles.sectionDesc}>
                      Proszę zaznaczyć strefę w której występuje ból, a
                      następnie wypełnić ankietę dotyczącą strefy.
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.diagnosisPain}>
                    <div className={styles.diagnosisPain__man}>
                      <img src={manFront} alt="man_front" />
                      <div className={styles.diagnosisPain__man_bg}>
                        <div
                          aria-label="head"
                          id="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_head + painClass.head
                          }
                          onClick={() =>
                            openDiagnosisModal('head', {
                              painIntensity: 'painIntensityHead',
                              painTime: 'painTimeHead',
                              painTime2: 'painTime2Head',
                              painMedicines: 'painMedicinesHead',
                              painLimits: 'painLimitsHead',
                              painLength: 'painLengthHead',
                              painType: 'painTypeHead',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          aria-label="head"
                          id="neck"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_neck + painClass.neck
                          }
                          onClick={() =>
                            openDiagnosisModal('neck', {
                              painIntensity: 'painIntensityNeck',
                              painTime: 'painTimeNeck',
                              painTime2: 'painTime2Neck',
                              painMedicines: 'painMedicinesNeck',
                              painLimits: 'painLimitsNeck',
                              painLength: 'painLengthNeck',
                              painType: 'painTypeNeck',
                            })
                          }
                        >
                          &nbsp;
                        </div>{' '}
                        <div
                          id="chest"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_chest + painClass.chest
                          }
                          onClick={() =>
                            openDiagnosisModal('chest', {
                              painIntensity: 'painIntensityChest',
                              painTime: 'painTimeChest',
                              painTime2: 'painTime2Chest',
                              painMedicines: 'painMedicinesChest',
                              painLimits: 'painLimitsChest',
                              painLength: 'painLengthChest',
                              painType: 'painTypeChest',
                            })
                          }
                        >
                          &nbsp;
                        </div>{' '}
                        <div
                          aria-label="head"
                          id="belly"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_belly + painClass.belly
                          }
                          onClick={() =>
                            openDiagnosisModal('belly', {
                              painIntensity: 'painIntensityBelly',
                              painTime: 'painTimeBelly',
                              painTime2: 'painTime2Belly',
                              painMedicines: 'painMedicinesBelly',
                              painLimits: 'painLimitsBelly',
                              painLength: 'painLengthBelly',
                              painType: 'painTypeBelly',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftArm"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftArm +
                            painClass.leftArm
                          }
                          onClick={() =>
                            openDiagnosisModal('leftArm', {
                              painIntensity: 'painIntensityLeftArm',
                              painTime: 'painTimeLeftArm',
                              painTime2: 'painTime2LeftArm',
                              painMedicines: 'painMedicinesLeftArm',
                              painLimits: 'painLimitsLeftArm',
                              painLength: 'painLengthLeftArm',
                              painType: 'painTypeLeftArm',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightArm"
                          role="button"
                          tabIndex={0}
                          aria-label="head"
                          className={
                            styles.diagnosisPain__man_bg_rightArm +
                            painClass.rightArm
                          }
                          onClick={() =>
                            openDiagnosisModal('rightArm', {
                              painIntensity: 'painIntensityRightArm',
                              painTime: 'painTimeRightArm',
                              painTime2: 'painTime2RightArm',
                              painMedicines: 'painMedicinesRighttArm',
                              painLimits: 'painLimitsRightArm',
                              painLength: 'painLengthRightArm',
                              painType: 'painTypeRightArm',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftHand"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftHand +
                            painClass.leftHand
                          }
                          onClick={() =>
                            openDiagnosisModal('leftHand', {
                              painIntensity: 'painIntensityLeftHand',
                              painTime: 'painTimeLeftHand',
                              painTime2: 'painTime2LeftHand',
                              painMedicines: 'painMedicinesLeftHand',
                              painLimits: 'painLimitsLeftHand',
                              painLength: 'painLengthLeftHand',
                              painType: 'painTypeLeftHand',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightHand"
                          role="button"
                          tabIndex={0}
                          aria-label="head"
                          className={
                            styles.diagnosisPain__man_bg_rightHand +
                            painClass.rightHand
                          }
                          onClick={() =>
                            openDiagnosisModal('rightHand', {
                              painIntensity: 'painIntensityRightHand',
                              painTime: 'painTimeRightHand',
                              painTime2: 'painTime2RightHand',
                              painMedicines: 'painMedicinesRightHand',
                              painLimits: 'painLimitsRightHand',
                              painLength: 'painLengthRightHand',
                              painType: 'painTypeRightHand',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="pelvis"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_pelvis +
                            painClass.pelvis
                          }
                          onClick={() =>
                            openDiagnosisModal('pelvis', {
                              painIntensity: 'painIntensityPelvis',
                              painTime: 'painTimePelvis',
                              painTime2: 'painTime2Pelvis',
                              painMedicines: 'painMedicinesPelvis',
                              painLimits: 'painLimitsPelvis',
                              painLength: 'painLengthPelvis',
                              painType: 'painTypePelvis',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftTibia"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftTibia +
                            painClass.leftTibia
                          }
                          onClick={() =>
                            openDiagnosisModal('leftTibia', {
                              painIntensity: 'painIntensityLeftTibia',
                              painTime: 'painTimeHeadLeftTibia',
                              painTime2: 'painTimeHead2LeftTibia',
                              painMedicines: 'painMedicinesLeftTibia',
                              painLimits: 'painLimitsLeftTibia',
                              painLength: 'painLengthLeftTibia',
                              painType: 'painTypeLeftTibia',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightTibia"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_rightTibia +
                            painClass.rightTibia
                          }
                          onClick={() =>
                            openDiagnosisModal('rightTibia', {
                              painIntensity: 'painIntensityRightTibia',
                              painTime: 'painTimeHeadRightTibia',
                              painTime2: 'painTime2HeadRightTibia',
                              painMedicines: 'painMedicinesRightTibia',
                              painLimits: 'painLimitsRightTibia',
                              painLength: 'painLengthRightTibia',
                              painType: 'painTypeRightTibia',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftThigh"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftThigh +
                            painClass.leftThigh
                          }
                          onClick={() =>
                            openDiagnosisModal('leftThigh', {
                              painIntensity: 'painIntensityLeftThigh',
                              painTime: 'painTimeHeadLeftThigh',
                              painTime2: 'painTime2HeadLeftThigh',
                              painMedicines: 'painMedicinesLeftThigh',
                              painLimits: 'painLimitsLeftThigh',
                              painLength: 'painLengthLeftThigh',
                              painType: 'painTypeLeftThigh',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightThigh"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_rightThigh +
                            painClass.rightThigh
                          }
                          onClick={() =>
                            openDiagnosisModal('rightThigh', {
                              painIntensity: 'painIntensityRightThigh',
                              painTime: 'painTimeHeadRightThigh',
                              painTime2: 'painTime2HeadRightThigh',
                              painMedicines: 'painMedicinesRightThigh',
                              painLimits: 'painLimitsRightThigh',
                              painLength: 'painLengthRightThigh',
                              painType: 'painTypeRightThigh',
                            })
                          }
                        >
                          &nbsp;
                        </div>{' '}
                      </div>
                    </div>
                    <div className={styles.diagnosisPain__man}>
                      <img src={manBack} alt="man_back" />
                      <div className={styles.diagnosisPain__man_bg}>
                        <div
                          id="headBack"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_headBack +
                            painClass.headBack
                          }
                          onClick={() =>
                            openDiagnosisModal('headBack', {
                              painIntensity: 'painIntensityHeadBack',
                              painTime: 'painTimeHeadBack',
                              painTime2: 'painTime2HeadBack',
                              painMedicines: 'painMedicinesHeadBack',
                              painLimits: 'painLimitsHeadBack',
                              painLength: 'painLengthHeadBack',
                              painType: 'painTypeHeadBack',
                            })
                          }
                        >
                          &nbsp;
                        </div>{' '}
                        <div
                          id="thoracic"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_thoracic +
                            painClass.thoracic
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="lumbar"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_lumbar +
                            painClass.lumbar
                          }
                          onClick={() =>
                            openDiagnosisModal('lumbar', {
                              painIntensity: 'painIntensityLumbar',
                              painTime: 'painTimeHeadLumbar',
                              painTime2: 'painTime2HeadLumbar',
                              painMedicines: 'painMedicinesLumbar',
                              painLimits: 'painLimitsLumbar',
                              painLength: 'painLengthLumbar',
                              painType: 'painTypeLumbar',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftPeace"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftPeace +
                            painClass.leftPeace
                          }
                          onClick={() =>
                            openDiagnosisModal('leftPeace', {
                              painIntensity: 'painIntensityLeftPeace',
                              painTime: 'painTimeHeadLeftPeace',
                              painTime2: 'painTimeHead2LeftPeace',
                              painMedicines: 'painMedicinesLeftPeace',
                              painLimits: 'painLimitsLeftPeace',
                              painLength: 'painLengthLeftPeace',
                              painType: 'painTypeLeftPeace',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightPeace"
                          aria-label="head"
                          role="button"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_rightPeace +
                            painClass.rightPeace
                          }
                          onClick={() =>
                            openDiagnosisModal('rightPeace', {
                              painIntensity: 'painIntensityRightPeace',
                              painTime: 'painTimeHeadRightPeace',
                              painTime2: 'painTime2HeadRightPeace',
                              painMedicines: 'painMedicinesRightPeace',
                              painLimits: 'painLimitsRightPeace',
                              painLength: 'painLengthRightPeace',
                              painType: 'painTypeRightPeace',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="leftFoot"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_leftFoot +
                            painClass.leftFoot
                          }
                          onClick={() =>
                            openDiagnosisModal('leftFoot', {
                              painIntensity: 'painIntensityLeftFoot',
                              painTime: 'painTimeHeadLeftFoot',
                              painTime2: 'painTime2HeadLeftFoot',
                              painMedicines: 'painMedicinesLeftFoot',
                              painLimits: 'painLimitsLeftFoot',
                              painLength: 'painLengthLeftFoot',
                              painType: 'painTypeLeftFoot',
                            })
                          }
                        >
                          &nbsp;
                        </div>
                        <div
                          id="rightFoot"
                          role="button"
                          aria-label="head"
                          tabIndex={0}
                          className={
                            styles.diagnosisPain__man_bg_rightFoot +
                            painClass.rightFoot
                          }
                          onClick={() =>
                            openDiagnosisModal('rightFoot', {
                              painIntensity: 'painIntensityRightFoot',
                              painTime: 'painTimeHeadRightFoot',
                              painTime2: 'painTime2HeadRightFoot',
                              painMedicines: 'painMedicinesRightFoot',
                              painLimits: 'painLimitsRightFoot',
                              painLength: 'painLengthRightFoot',
                              painType: 'painTypeRightFoot',
                            })
                          }
                        >
                          &nbsp;
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              <ThemeProvider theme={customButtonTheme}>
                <Button
                  variant="contained"
                  type="submit"
                  size="large"
                  disabled={
                    (!formik.values.sex ||
                      !formik.values.age ||
                      !formik.values.weight ||
                      !formik.values.height ||
                      !formik.values.waist ||
                      // !formik.values.hip ||
                      !formik.values.pulse ||
                      !formik.values.heart ||
                      !formik.values.dyspnoea ||
                      // !formik.values.efficiency ||
                      !formik.values.systolicPressure ||
                      !formik.values.diastolicPressure ||
                      !formik.values.functionality) &&
                    Object.keys(pain).length < 1
                  }
                >
                  Sprawdź wynik
                </Button>
              </ThemeProvider>
            </div>
            <Modal
              open={open}
              sx={modalStyle}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className={`${styles.painModal} mui-fixed `}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Wypełnij poniższe pola
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className={styles.form}>
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painIntensity">Natężenie bólu</InputLabel>
                      <Select
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painIntensity}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painIntensity]
                            : ''
                        }
                        name="painIntensity"
                        id="painIntensity"
                        label="Intensywność"
                      >
                        {Array.from(Array(11))
                          .map((e, i) => i)
                          .map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                              {item === 0 ? ' - brak bólu' : ''}
                              {item === 1 ? ' - ból minimalny' : ''}
                              {item === 10 ? ' - ból nie do zniesienia' : ''}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painTime">
                        Występowanie bólu w ciągu doby
                      </InputLabel>
                      <Select
                        name="painTime"
                        id="painTime"
                        label="Intensywność"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painTime}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painTime]
                            : ''
                        }
                      >
                        <MenuItem key="1" value="1">
                          w ciągu dnia
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          w nocy
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          dzień i noc
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={selectStyle} size="small">
                      <InputLabel id="painTime">
                        Występowanie bólu w trakcie aktywności
                      </InputLabel>
                      <Select
                        sx={selectStyle}
                        name="painTime"
                        id="painTime"
                        label="Intensywność"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painTime2}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painTime2]
                            : ''
                        }
                      >
                        <MenuItem key="1" value="1">
                          ból spoczynkowy, w czasie odpoczynku lub snu
                        </MenuItem>
                        <MenuItem key="2" value="2">
                          ból występuje podczas zwyczajnych czynności dnia
                          codziennego
                        </MenuItem>
                        <MenuItem key="3" value="3">
                          ból w trakcie aktywności fizycznej
                        </MenuItem>
                        <MenuItem key="4" value="4">
                          nie wiążę występowania bólu z aktywnością lub
                          odpoczynkiem
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painMedicines">
                        Ustępowanie po lekach
                      </InputLabel>
                      <Select
                        name="painMedicines"
                        id="painMedicines"
                        label="Intensywność"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painMedicines}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painMedicines]
                            : ''
                        }
                      >
                        <MenuItem key="Tak" value="1">
                          Tak
                        </MenuItem>
                        <MenuItem key="Nie" value="2">
                          Nie
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painType">
                        Ograniczenie funkcjonowania
                      </InputLabel>
                      <Select
                        name="painLimits"
                        id="painLimits"
                        label="Ograniczenie funkcjonowania"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painLimits}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painLimits]
                            : ''
                        }
                      >
                        <MenuItem
                          key="Nie mam ograniczeń, moja sprawność jest pełna"
                          value="1"
                        >
                          Nie mam ograniczeń, moja sprawność jest pełna
                        </MenuItem>
                        <MenuItem key="Mam nieznaczne ograniczenia" value="2">
                          Mam nieznaczne ograniczenia{' '}
                        </MenuItem>
                        <MenuItem key="Mam znaczne ograniczenia" value="3">
                          Mam znaczne ograniczenia
                        </MenuItem>
                        <MenuItem key="Wymagam pomocy osób trzecich" value="4">
                          Wymagam pomocy osób trzecich
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painType">
                        Czas występowania bólu
                      </InputLabel>
                      <Select
                        name="painLength"
                        id="painLength"
                        label="Czas występowania bólu"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painLength}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painLength]
                            : ''
                        }
                      >
                        <MenuItem key="Od dzisiaj" value="1">
                          Od dzisiaj
                        </MenuItem>
                        <MenuItem key="Od kilku dni" value="2">
                          Od kilku dni
                        </MenuItem>
                        <MenuItem key="Od kilku tygodni" value="3">
                          Od kilku tygodni
                        </MenuItem>
                        <MenuItem key="Od kilku miesięcy" value="4">
                          Od kilku miesięcy
                        </MenuItem>
                        <MenuItem key="Od kilku lat" value="5">
                          Od kilku lat
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <FormControl sx={{ minWidth: '100%' }} size="small">
                      <InputLabel id="painType">Rodzaj bólu</InputLabel>
                      <Select
                        name="painType"
                        id="painType"
                        label="Rodzaj bólu"
                        onChange={(value) =>
                          currentFormikPart &&
                          formik.setFieldValue(
                            `${currentFormikPart.painType}`,
                            value.target.value
                          )
                        }
                        value={
                          currentFormikPart
                            ? // @ts-ignore
                              formik.values[currentFormikPart.painType]
                            : ''
                        }
                      >
                        <MenuItem key="Ból samoistny" value="1">
                          Ból samoistny
                        </MenuItem>
                        <MenuItem key="Ból po urazie/przeciążeniu" value="2">
                          Ból po urazie/przeciążeniu
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    {currentFormikPart && (
                      <Button
                        variant="contained"
                        type="submit"
                        color="info"
                        onClick={() => {
                          const element = document.getElementById(`${part}`);
                          if (element) {
                            element.classList.add(
                              styles.DiagnosisQuestions_diagnosis_man_active
                            );
                          }
                          openModal(false);
                        }}
                        // disabled={
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painIntensity] ||
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painLength] ||
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painLimits] ||
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painMedicines] ||
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painTime] ||
                        //   // @ts-ignore
                        //   !formik.values[currentFormikPart.painType]
                        // }
                      >
                        Zapisz
                      </Button>
                    )}

                    <Button
                      size="small"
                      sx={{ marginLeft: '10px' }}
                      onClick={() => {
                        if (
                          currentFormikPart &&
                          currentFormikPart.painIntensity &&
                          currentFormikPart.painLength &&
                          currentFormikPart.painLimits &&
                          currentFormikPart.painMedicines &&
                          currentFormikPart.painTime &&
                          currentFormikPart.painType
                        ) {
                          formik.setValues({
                            ...formik.values,
                            [currentFormikPart.painIntensity]: '',
                            [currentFormikPart.painLength]: '',
                            [currentFormikPart.painLimits]: '',
                            [currentFormikPart.painMedicines]: '',
                            [currentFormikPart.painTime]: '',
                            [currentFormikPart.painType]: '',
                          });
                        }

                        closeDiagnosisModal(part);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Wyczyść
                    </Button>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </form>
        </div>
      </div>
    </>
  );
};
// make modal after clicking on man_front element

export default Diagnosis;
