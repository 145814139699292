import { TriangleIcon } from '../../assets/icons';
import styles from './HeaderWithTriangles.module.scss';

interface IProps {
  title: string;
  className?: string;
}

// TODO add props
const HeaderWithTriangles = (props: IProps) => {
  const { title, className } = props;
  return (
    <header className={`${styles.header} ${className}`}>
      <h1 className={styles.title}>
        <span>{title}</span>
        <TriangleIcon
          color="currentColor"
          inHeader={false}
          className={`${styles.titleTriangle} ${styles.titleTriangleBefore}`}
        />
        <TriangleIcon
          color="#164C8A"
          inHeader={false}
          className={`${styles.titleTriangle} ${styles.titleTriangleAfter}`}
        />
      </h1>
    </header>
  );
};

HeaderWithTriangles.defaultProps = {
  className: '',
};

export default HeaderWithTriangles;
