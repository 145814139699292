import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { NavLink } from 'react-router-dom';

import { customButtonTheme } from '../../styles/buttons.mui';
import styles from './LinkBoxWithImage.module.scss';
import { LinkBoxWithImageProps } from './LinkBoxWithImage.props';

const LinkBoxWithImage = ({
  text_up,
  text_down,
  path,
  image,
  bgColor,
  color,
}: LinkBoxWithImageProps) => {
  return (
    <NavLink to={path} className={styles.navLink} key={path}>
      <div className={styles.container}>
        <div>
          <img className={styles.image} src={image} alt={text_down} />
        </div>
        <div className={styles.textBox} style={{ background: bgColor }}>
          <div className={styles.text} style={{ color }}>
            {text_up}
            <span>{text_down}</span>
          </div>
          <div className={styles.goTo}>
            <ThemeProvider theme={customButtonTheme}>
              <Button variant="contained">Przejdź</Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default LinkBoxWithImage;
