export interface DiagnosisResultProp {
  result: string;
  advice: string;
  adviceForPhysio: string;
  type: DiagnosisResultType;
  needsDoctor?: boolean;
  id?: string;
}

export enum DiagnosisResultType {
  OK = 1,
  NOT_OK = 2,
}
