/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, ThemeProvider } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Helmet } from 'react-helmet';

import {
  getAdvancedSearchResult,
  getAllCategories,
} from '../../api/services/physiotherapist';
import { CustomLoading } from '../../components/CustomLoading';
import {
  customButtonTheme,
  customButtonThemeBig,
} from '../../styles/buttons.mui';
import globalStyles from '../../styles/global.module.scss';
import { AdvancedSearchResponse } from './mocks';
import styles from './PhysiotherapistBase.module.scss';

const PhysiotherapistBase = () => {
  const [results, setResults] = useState<AdvancedSearchResponse>();
  const [showPredictions, setShowPredictions] = useState<boolean>(true);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: 'AIzaSyBWWCnm48FW3wE0Ch4yHDnip69SmrtXwsA',
    options: {
      types: ['(cities)'],
      componentRestrictions: { country: 'pl' },
    },
  });

  const getData = async (values: any) => {
    const { specialization, city, placeId, latitude, longitude } = values;

    const allCategories = await getAllCategories();

    const findDisease = allCategories.data.Value.DiseaseCategories.find(
      (el) => el.Name.toLowerCase() === specialization.toLowerCase()
    );

    const findTherapy = allCategories.data.Value.Therapies.find(
      (el) => el.Name.toLowerCase() === specialization.toLowerCase()
    );

    // const defaultPlacesData = await getDefaultPlacesData();

    // const findPlace = defaultPlacesData.data.Value.find(
    //   (el) => el.name.toLowerCase() === city.toLowerCase()
    // );

    const advancedSearchResult = await getAdvancedSearchResult(
      values,
      {
        name: city,
        administrativeArea: '',
        id: placeId,
        placeId,
        useOnlyViewportForSearch: false,
        location: {
          lat: latitude,
          lng: longitude,
        },
        viewport: {
          northeast: {
            lat: latitude,
            lng: longitude,
          },
          southwest: {
            lat: latitude,
            lng: longitude,
          },
        },
      },
      findDisease,
      findTherapy
    );

    const result = advancedSearchResult.data;

    setResults(result);
  };

  const formik = useFormik({
    initialValues: {
      specialization: '',
      city: '',
      placeId: '',
      latitude: '',
      longitude: '',
    },
    onSubmit: (values) => {
      if (showPredictions && placePredictions.length) {
        placesService?.getDetails(
          {
            placeId: placePredictions[0].place_id,
          },
          (placeDetails: any) => {
            formik.setValues({
              ...formik.values,
              city: placeDetails.formatted_address,
              placeId: placeDetails.place_id,
              latitude: placeDetails.geometry.location.lat(),
              longitude: placeDetails.geometry.location.lng(),
            });
            getData({
              specialization: values.specialization,
              city: placeDetails.formatted_address,
              placeId: placeDetails.place_id,
              latitude: placeDetails.geometry.location.lat(),
              longitude: placeDetails.geometry.location.lng(),
            });
          }
        );
      } else {
        getData(values);
      }
    },
  });

  const setData = (item: any) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails: any) => {
        formik.setValues({
          ...formik.values,
          city: placeDetails.formatted_address,
          placeId: placeDetails.place_id,
          latitude: placeDetails.geometry.location.lat(),
          longitude: placeDetails.geometry.location.lng(),
        });
      }
    );
    setShowPredictions(false);
  };

  const openInNewTab = (id: string) => {
    window.open(
      `https://znajdzfizjoterapeute.pl/fizjoterapeuta/${id}`,
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <>
      {' '}
      <Helmet>
        <title>Fizjoterapeuci - Zdalne Konsultacje KIF</title>
      </Helmet>
      <div
        className={`${globalStyles.container} ${styles.container}`}
        style={{ textAlign: 'center' }}
      >
        <div className={styles.header}>Wyszukaj fizjoterapeutę</div>
        <div style={{ marginTop: '20px', fontSize: '15px' }}>
          Niniejsza wyszukiwarka pozwala na znalezienie wyłącznie
          fizjoterapeutów, którzy korzystają z portalu. Wykaz nie obejmuje
          wszystkich fizjoterapeutów. Szukając fizjoterapeutów poza wykazem
          sprawdź czy fizjoterapeuta posiada prawo do wykonywania zawodu
        </div>
      </div>
      <div className={`${globalStyles.container} ${styles.container}`}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.form}>
            <div className={styles.filters}>
              <FormControl variant="filled" className={styles.formControl}>
                <TextField
                  label="Schorzenie"
                  variant="filled"
                  id="specialization"
                  value={formik.values.specialization}
                  onChange={formik.handleChange}
                />
              </FormControl>

              <FormControl variant="filled" className={styles.formControl}>
                <TextField
                  label="Miasto"
                  variant="filled"
                  id="city"
                  value={formik.values.city}
                  autoComplete=""
                  onChange={(evt) => {
                    setShowPredictions(true);
                    formik.handleChange(evt);
                    getPlacePredictions({ input: evt.target.value });
                  }}
                />
                <div className={styles.cities}>
                  {isPlacePredictionsLoading ? (
                    <CustomLoading />
                  ) : (
                    showPredictions &&
                    placePredictions.map((item) => {
                      return (
                        <div
                          className={styles.city}
                          onClick={() => setData(item)}
                        >
                          {item.description}
                        </div>
                      );
                    })
                  )}
                </div>
              </FormControl>

              <ThemeProvider theme={customButtonThemeBig}>
                <Button variant="contained" type="submit">
                  Szukaj
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </form>
      </div>
      {results?.Value?.Result.map((person) => (
        <div
          key={person.BasicInfo.ProfessionNumber}
          className={`${globalStyles.container} ${styles.container} ${styles.person}`}
        >
          {person.BasicInfo.ProfileImgUrl ? (
            <img
              className={styles.photo}
              src={`https://znajdzfizjoterapeute.pl/${person.BasicInfo.ProfileImgUrl}`}
              alt={person.BasicInfo.LastName}
            />
          ) : (
            <div className={styles.photo} />
          )}
          <div className={styles.personData}>
            <div className={styles.personName}>
              {person.BasicInfo.FirstName} {person.BasicInfo.LastName}
            </div>
            <div className={styles.pwzfz}>
              PWZFz: {person.BasicInfo?.ProfessionNumber}
            </div>
            <div className={styles.specialization}>
              Specjalności: {person.BasicInfo?.Specialization}
            </div>
          </div>
          <div className={styles.booking}>
            <ThemeProvider theme={customButtonTheme}>
              <Button
                variant="contained"
                onClick={() => openInNewTab(person.BasicInfo.ProfessionNumber)}
              >
                Umów wizytę na ZnajdzFizjoterapeute.pl
              </Button>
            </ThemeProvider>
          </div>
        </div>
      ))}
    </>
  );
};

export default PhysiotherapistBase;
