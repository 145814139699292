import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import { ExercisesDto } from '../models/ExercisesDto';

export const getExercises = (): AxiosPromise<ExercisesDto[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/exercises`,
    method: 'GET',
  });
};
