/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Video = styled.video``;

const VideoCard = (props) => {
  const ref = useRef();
  const { peer } = props;

  useEffect(() => {
    peer.on('stream', (stream) => {
      ref.current.srcObject = stream;
    });
    peer.on('track', (track, stream) => {});
  }, [peer]);

  return <Video playsInline autoPlay ref={ref} />;
};

export default VideoCard;
