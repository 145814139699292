import Loading from 'react-loading';

const CustomLoading = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      margin: '100px auto',
    }}
  >
    <Loading type="spin" color="#76446B" height={100} width={100} />
  </div>
);

export default CustomLoading;
