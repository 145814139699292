import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import {
  TherapyExerciseUpdateDto,
  TherapyPlanDto,
  TherapyPlanResponse,
} from '../models/TherapyPlanDto';

export const createTherapyPlan = (
  therapy: TherapyPlanDto[]
): AxiosPromise<TherapyPlanResponse[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy-plan`,
    method: 'POST',
    data: therapy,
  });
};

export const getTherapyPlan = (
  therapyId: number
): AxiosPromise<TherapyPlanResponse[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy-plan/${therapyId}`,
    method: 'GET',
  });
};

export const updateTherapyExercise = (
  exercise: TherapyExerciseUpdateDto
): AxiosPromise<any> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, done, monitoring_answer, monitoring_pain } = exercise;

  return axiosInstance({
    url: `${baseAPIUrl}/therapy-plan/${id}`,
    method: 'PUT',
    data: { done, monitoring_answer, monitoring_pain },
  });
};
