const ArrowFullIcon = ({ color }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
    >
      <path
        d="M14.9282 0.5L8 12.5L1.0718 0.499999L14.9282 0.5Z"
        fill={color}
        stroke={color}
      />
    </svg>
  );
};

export default ArrowFullIcon;
