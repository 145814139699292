import {
  bmiNorms,
  diastolicPressureNorms,
  efficiencyNorms,
  pulseNorms,
  systolicPressureNorms,
  whtrNorms,
} from './norms';

export const checkPulse = (pulse: number, age: number, sex: number) => {
  const result = pulseNorms[sex === 1 ? 'women' : 'men'].results
    .filter((item) => item.ageFrom <= age && item.ageTo >= age)[0]
    .norms.filter((item) => pulse >= item.pulseFrom && pulse < item.pulseTo)[0];

  if (result) {
    return result;
  }

  return {
    result: 'average',
    name: 'Przeciętny',
  };
};

export const calculateBmi = (weight: number, height: number) => {
  return weight / (height / 100) ** 2;
};

export const checkBmi = (bmi: number) => {
  const result = bmiNorms.filter(
    (item) =>
      Math.round(bmi) >= item.range[0] && Math.round(bmi) <= item.range[1]
  );
  return result[0];
};

export const checkWhtr = (whtr: number, sex: number) => {
  const result = whtrNorms[sex === 1 ? 'women' : 'men'].filter(
    (item) => whtr >= item.range[0] && whtr <= item.range[1]
  );
  return result[0];
};

export const checkWhr = (waist: number, hip: number, sex: number) => {
  // women
  if (sex === 1) {
    if (waist / hip === 0.8) {
      return {
        result: 'good',
        name: 'Wynik prawidłowy',
      };
    }
    if (waist / hip > 0.8) {
      return {
        result: 'bad',
        name: 'Otyłość brzuszna',
      };
    }
    return {
      result: 'bad',
      name: 'Otyłość pośladkowo-udowa',
    };
  }
  if (waist / hip === 1) {
    return {
      result: 'good',
      name: 'Wynik prawidłowy',
    };
  }
  if (waist / hip > 1) {
    return {
      result: 'bad',
      name: 'Otyłość brzuszna',
    };
  }
  return {
    result: 'bad',
    name: 'Otyłość pośladkowo-udowa',
  };
};

export const checkEfficiencyNorms = (efficiency: number) => {
  const result = efficiencyNorms.filter(
    (item) => efficiency >= item.range[0] && efficiency <= item.range[1]
  );
  return result[0];
};

// @todo make one function
export const checkSystolicPressure = (pressure: number) => {
  const result = systolicPressureNorms.filter(
    (item) => pressure >= item.range[0] && pressure < item.range[1]
  );
  return result[0];
};

export const checkDiastolicPressure = (pressure: number) => {
  const result = diastolicPressureNorms.filter(
    (item) => pressure >= item.range[0] && pressure < item.range[1]
  );
  return result[0];
};
