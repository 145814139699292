import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { CustomLoading } from '../components/CustomLoading';
import {
  DiagnosisQuestions,
  DiagnosisResult,
  FindTherapyPlan,
  GetCode,
  Infopage,
  Patient,
  Physiotherapist,
  PhysiotherapistBase,
  PlanGenerated,
  PrepareTherapyPlan,
  SummaryOfPlan,
  TherapyPlan,
} from '../domains';
import { Main } from '../domains/Main';
import JoinRoom from '../domains/OnlineConsultations/JoinRoom/JoinRoom';
import Room from '../domains/OnlineConsultations/Room/Room';
import {
  ExercisesList,
  FindDiagnosisResult,
  PatientAnswers,
  PatientProgressStats,
} from '../domains/Physiotherapist';
import { Path } from './paths';
import PublicRoute from './PublicRoute';

const MainRouter = () => {
  const createRoutes = () => {
    return (
      <>
        <Route path={Path.ROOT} element={<PublicRoute component={Main} />} />
        <Route
          path={Path.PATIENT}
          element={<PublicRoute component={Patient} />}
        />
        <Route
          path={Path.PHYSIOTHERAPISTS_BASE}
          element={<PublicRoute component={PhysiotherapistBase} />}
        />
        <Route
          path={Path.INFOPAGE}
          element={<PublicRoute component={Infopage} />}
        />
        <Route
          path={Path.GETCODE}
          element={<PublicRoute component={GetCode} />}
        />
        <Route
          path={`${Path.THERAPY_PLAN}/:id`}
          element={<PublicRoute component={TherapyPlan} />}
        />
        <Route
          path={Path.DIAGNOSIS}
          element={<PublicRoute component={DiagnosisQuestions} />}
        />
        <Route
          path={`${Path.DIAGNOSIS_RESULT}/:resultId?`}
          element={<PublicRoute component={DiagnosisResult} />}
        />
        <Route
          path={Path.FIND_THERAPY_PLAN}
          element={<PublicRoute component={FindTherapyPlan} />}
        />

        <Route
          path={Path.PHYSIOTHERAPIST}
          element={<PublicRoute component={Physiotherapist} />}
        />
        <Route
          path={`${Path.PREPARE_THERAPY_PLAN}/:id?`}
          element={<PublicRoute component={PrepareTherapyPlan} />}
        />
        <Route
          path={Path.SUMMARY_OF_PLAN}
          element={<PublicRoute component={SummaryOfPlan} />}
        />
        <Route
          path={Path.PLAN_GENERATED}
          element={<PublicRoute component={PlanGenerated} />}
        />
        <Route
          path={Path.EXERCISES_LIST}
          element={<PublicRoute component={ExercisesList} />}
        />
        <Route
          path={Path.FIND_DIAGNOSIS_RESULT}
          element={<PublicRoute component={FindDiagnosisResult} />}
        />
        <Route
          path={`${Path.DIAGNOSIS_RESULT}/:resultId?`}
          element={<PublicRoute component={DiagnosisResult} />}
        />
        <Route
          path={Path.DIAGNOSIS_BY_PHYSIO}
          element={<PublicRoute component={DiagnosisQuestions} />}
        />
        <Route
          path={`${Path.PATIENT_ANSWERS}/:id/:status?`}
          element={<PublicRoute component={PatientAnswers} />}
        />
        <Route
          path={`${Path.PATIENT_PROGRESS_STATS}/:id`}
          element={<PublicRoute component={PatientProgressStats} />}
        />
        <Route
          path={`${Path.JOIN_VIDEO_CALL}/:roomId`}
          element={<PublicRoute component={JoinRoom} />}
        />
        <Route
          path={`${Path.VIDEO_CALL}/:roomId`}
          element={<PublicRoute component={Room} />}
        />
        <Route path="*" element={<Navigate to={`${Path.ROOT}`} replace />} />
      </>
    );
  };

  return (
    <Suspense fallback={<CustomLoading />}>
      <Routes>{createRoutes()}</Routes>
    </Suspense>
  );
};

export default MainRouter;
