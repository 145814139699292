/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MediaType } from '../../../api/models/ExercisesDto';
import { Path } from '../../../router/paths';
import { customButtonTheme } from '../../../styles/buttons.mui';
import globalStyles from '../../../styles/global.module.scss';
import { TherapyPlanDay } from '../../TherapyPlan/TherapyPlan.types';
import styles from './SummaryOfPlan.module.scss';

type Question = {
  id: number;
  title: string;
  answers: string[];
};

type Monitoring = {
  day: number;
  questions: Question[];
};

// const allExercises: TherapyPlanItem[] = [
//   {
//     id: 1,
//     done: false,
//     title: 'Wkręcanie żarówek',
//     repeats: 3,
//     minutes: 3,
//     notes: 'Lorem ipsum',
//     category: 1,
//     media: {
//       type: MediaType.VIDEO,
//       url: '',
//     },
//     extraText: 'Informacja jak wykonać ćwiczenie...',
//   },
//   {
//     id: 2,
//     done: false,
//     title: 'Rowerek',
//     repeats: 3,
//     minutes: 5,
//     notes: 'Lorem ipsum',
//     category: 1,
//     media: {
//       type: MediaType.VIDEO,
//       url: '',
//     },
//   },
//   {
//     id: 22,
//     done: false,
//     title: 'Jakieś ćwiczenia z kategorii drugiej',
//     repeats: 3,
//     minutes: 5,
//     notes: 'Lorem ipsum',
//     category: 2,
//     media: {
//       type: MediaType.VIDEO,
//       url: '',
//     },
//   },
// ];

const monitoring: Monitoring[] = [
  {
    day: 1,
    questions: [
      {
        id: 1,
        title: 'Czy gdy kręcisz głową to słyszysz strzykanie?',
        answers: ['Tak', 'Nie', 'Nie wiem', 'Nie słyszę myśli'],
      },
      {
        id: 2,
        title: 'Czy jak coś tam coś tam to coś tam coś tam?',
        answers: ['Tak', 'Nie', 'Nie wiem', 'Nie słyszę myśli'],
      },
    ],
  },
];

const SummaryOfPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [therapyPlan, setTherapyPlan] = useState<TherapyPlanDay[]>();

  useEffect(() => {
    setTherapyPlan(location.state.therapyPlan);
  }, []);

  const generatePlan = () => {
    navigate(`${Path.PLAN_GENERATED}`);
  };

  return (
    <>
      <div className={`${globalStyles.container} ${styles.container}`}>
        Podsumowanie planu
      </div>
      {therapyPlan?.map((day, index) => (
        <div>
          <div className={`${globalStyles.container} ${styles.container}`}>
            <div className={styles.header}>Dodaj</div>
            {day.map((el) => (
              <div className={styles.exercise}>
                <div>{el.id}</div>
                <div>
                  {el.media_type === MediaType.VIDEO && (
                    <div>
                      <video width="320" height="240" controls>
                        <source src={el.media_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {monitoring.some((el) => el.day === index + 1) && (
            <div className={`${globalStyles.container} ${styles.container}`}>
              <div className={styles.header}>Monitorowanie postępów</div>
              {monitoring
                .find((el) => el.day === index + 1)
                ?.questions.map((question) => (
                  <div>{question.title}</div>
                ))}
            </div>
          )}
        </div>
      ))}
      <div
        className={`${globalStyles.container} ${styles.container} ${styles.doneBox} `}
      >
        <div className={styles.isDone}>Gotowe?</div>
        <div className={styles.doneText}>
          Jeżeli wszystko się zgadza kliknij poniżej i wygeneruj linki do
          przygotowanej terapii
        </div>

        <ThemeProvider theme={customButtonTheme}>
          <Button variant="contained" onClick={generatePlan}>
            Generuj
          </Button>
        </ThemeProvider>
      </div>
    </>
  );
};

export default SummaryOfPlan;
