import styles from './ProsMainBox.module.scss';
import { ProsProps } from './ProsMainBox.types';

const ProsMainBox = ({ data, color }: ProsProps) => {
  return (
    <div className={styles.pros} style={{ borderColor: color }}>
      {data.map((el) => (
        <div
          className={styles.item}
          key={el.header}
          style={{ borderColor: color }}
        >
          <div className={styles.iconBox} style={{ backgroundColor: color }}>
            <img src={el.icon} alt="" className={styles.icon} />
          </div>

          <div className={styles.data}>
            <div className={styles.header} style={{ color }}>
              {el.header}
            </div>
            <div className={styles.description} style={{ color }}>
              {el.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProsMainBox;
