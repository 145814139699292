import { AxiosPromise } from 'axios';

import axiosInstance from '../axiosInstance';
import { baseAPIUrl } from '../config';
import {
  TherapyDto,
  TherapyResponse,
  TherapyUpdateDto,
} from '../models/TherapyDto';

export const createTherapy = (
  therapy: TherapyDto
): AxiosPromise<TherapyResponse[]> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy`,
    method: 'POST',
    data: therapy,
  });
};

export const getTherapyByDiagnosisId = (
  diagnosisId: string
): AxiosPromise<TherapyResponse> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy/diagnosis/${diagnosisId}`,
    method: 'GET',
  });
};

export const getTherapyByPatientUuid = (
  patientUuid: string
): AxiosPromise<TherapyResponse> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy/patient/${patientUuid}`,
    method: 'GET',
  });
};

export const getTherapyByPhysioUuid = (
  physioUuid: string
): AxiosPromise<TherapyResponse> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy/physio/${physioUuid}`,
    method: 'GET',
  });
};

export const updateTherapy = (
  therapy: TherapyUpdateDto
): AxiosPromise<TherapyResponse> => {
  return axiosInstance({
    url: `${baseAPIUrl}/therapy/${therapy.patient_link}`,
    method: 'PUT',
    data: therapy,
  });
};
