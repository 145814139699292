import { Path } from '../../router/paths';

export type NavigationItem = {
  path: Path | string;
  label: string;
  icon?: any;
};

export type NavigationType = NavigationItem[];

export const navItems: NavigationType = [
  {
    path: `${Path.PHYSIOTHERAPIST}`,
    label: 'Jestem Fizjoterapeutą',
  },
  {
    path: `${Path.PATIENT}`,
    label: 'Jestem Pacjentem',
  },
  {
    path: `${Path.INFOPAGE}`,
    label: 'O projekcie',
  },
];
