import io from 'socket.io-client';

const sockets = io(
  process.env.REACT_APP_IO_ADDRESS || 'http://localhost:3001',
  {
    autoConnect: true,
    forceNew: true,
  }
);
// const sockets = io('/');
export default sockets;
