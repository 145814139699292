import { useCookies } from 'react-cookie';

import styles from './ShowCookieAlert.module.scss';

const ShowCookieAlert = () => {
  const [cookies, setCookie] = useCookies(['phisio']);

  function changeCookie() {
    setCookie('phisio', 1);
  }

  return (
    <div className={styles.alert}>
      {' '}
      {!cookies.phisio && (
        <div>
          {' '}
          Ta część aplikacji dostępna jest jedynie dla fizjoterapeutów. <br />
          Klikając poniżej potwierdzasz, że posiadasz prawo do wykonywania
          zawodu fizjoterapeuty.
          <br />
          <br />
          <button type="button" onClick={changeCookie}>
            Potwierdzam
          </button>
        </div>
      )}
    </div>
  );
};

export default ShowCookieAlert;
