import { Helmet } from 'react-helmet';

import globalStyles from '../../../styles/global.module.scss';
import styles from './GetCode.module.scss';

const GetCode = () => {
  return (
    <>
      {' '}
      <Helmet>
        <title>Dla Fizjoterapeuty - kod weryfikacyjny</title>
      </Helmet>
      <div className={styles.container}>
        <div className={`${globalStyles.container} ${styles.linkBoxes}`}>
          <div className={styles.content}>
            <p>
              <br />
              Tworzenie planów ćwiczeń dostępne jest tylko dla fizjoterapeutów.
              <br />
              Do aktywacji stworzonego planu będzie konieczny kod z Portalu
              Fizjoterapeuty, który po zalogowaniu w Portalu pobierzesz tutaj:{' '}
              <br />
              <a
                href="https://portal.kif.info.pl/companies?section=userGenerateCode"
                target="_blank"
                rel="noreferrer"
              >
                portal.kif.info.pl/companies?section=userGenerateCode
              </a>
              <br />
              To będzie Twój unikalny kod weryfikacyjny, możesz go zapisać i
              użyć wielokrotnie. Nie udostępniaj go nikomu.
            </p>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default GetCode;
