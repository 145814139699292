import { ComponentType, FC } from 'react';

interface PublicRouteProps {
  component: ComponentType;
}

const PublicRoute: FC<PublicRouteProps> = ({ component: RouteComponent }) => {
  return <RouteComponent />;
};

export default PublicRoute;
