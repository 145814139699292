/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import ReactLoading from 'react-loading';

import { ExercisesDto, MediaType } from '../../../api/models/ExercisesDto';
import { getExercises } from '../../../api/services/exercises';
import { ShowCookieAlert } from '../../../components';
import globalStyles from '../../../styles/global.module.scss';
import styles from './ExercisesList.module.scss';

export type ExercisesByCat = {
  id: number;
  type: string;
  exercises: ExercisesDto[];
};

const ExercisesList = () => {
  const [exercisesByCat, setExercisesByCat] = useState<ExercisesByCat[]>();
  const [currentVideo, setCurrentVideo] = useState<number>();
  const [cookies] = useCookies(['phisio']);

  const { isLoading, error, data } = useQuery<ExercisesDto[], AxiosError>({
    queryKey: ['exercises'],
    queryFn: () => getExercises().then((res) => res.data),
  });

  useEffect(() => {
    if (data) {
      const getTypes = data.map((item) => item.type);
      const types = new Map();
      getTypes.forEach((type) => types.set(type.id, type.type));
      const exercises: ExercisesByCat[] = [];
      types.forEach((item, index) =>
        exercises.push({
          id: index,
          type: item,
          exercises: [...data.filter((d) => d.type.id === index)],
        })
      );
      setExercisesByCat(exercises);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: '100px auto',
        }}
      >
        <ReactLoading type="spin" color="#76446B" height={100} width={100} />
      </div>
    );
  }

  if (error) {
    return <div>Wystąpił błąd... {error.message} </div>;
  }

  if (!exercisesByCat?.length) {
    return <div>Brak ćwiczeń...</div>;
  }

  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <Helmet>
        <title>Lista ćwiczeń - Zdalne Konsultacje KIF</title>
      </Helmet>
      <div className={`${globalStyles.container} ${styles.container}`}>
        {!cookies.phisio && <ShowCookieAlert />}
        {exercisesByCat
          .filter(
            (category) =>
              category.type !== 'Ćwiczenia oddechowe w pozycji siedzącej'
          )
          .map((item) => (
            <div className={styles.group}>
              <div className={styles.categoryName}>{item.type}</div>
              <div className={styles.exercises}>
                {item.exercises.map((exercise) => (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {exercise?.description && (
                      <div className={styles.exercise}>
                        <div className={styles.exerciseId}>
                          <strong>
                            {exercise.name
                              ? exercise.name
                              : `Ćwiczenie nr. ${exercise.id}`}
                            &nbsp; [{exercise.id}]
                          </strong>
                        </div>
                        <div>
                          {exercise.media_type === MediaType.VIDEO && (
                            <div className={styles.videoContainer}>
                              {currentVideo === exercise.id ? (
                                <video
                                  width="280"
                                  height="280"
                                  autoPlay
                                  controls
                                  poster={
                                    // @ts-ignore
                                    exercise?.media_url?.match(/\d+/)[0]
                                      ? `${
                                          process.env.REACT_APP_API_HOST
                                        }/media/video/screens/video_${
                                          // @ts-ignore
                                          exercise.media_url.match(/\d+/)[0]
                                        }_1.jpg`
                                      : ''
                                  }
                                >
                                  <source
                                    src={`${exercise.media_url}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div
                                  className={styles.imgBox}
                                  onClick={() => setCurrentVideo(exercise.id)}
                                >
                                  <img
                                    className={styles.videoImg}
                                    src={
                                      // @ts-ignore
                                      exercise?.media_url?.match(/\d+/)[0]
                                        ? `${
                                            process.env.REACT_APP_API_HOST
                                          }/media/video/screens/video_${
                                            // @ts-ignore
                                            exercise.media_url.match(/\d+/)[0]
                                          }_1.jpg`
                                        : ''
                                    }
                                    alt=""
                                  />
                                  <PlayCircleIcon
                                    fontSize="large"
                                    className={styles.playButton}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        <div className={styles.exerciseDesc}>
                          {exercise.description}
                        </div>
                      </div>
                    )}
                  </>
                ))}
                <div className={styles.exerciseEmpty} />
                <div className={styles.exerciseEmpty} />
                <div className={styles.exerciseEmpty} />
              </div>
            </div>
          ))}
      </div>
    </CookiesProvider>
  );
};

export default ExercisesList;
