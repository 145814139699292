/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { TherapyPlanResponse } from '../../../api/models/TherapyPlanDto';
import { getTherapyByPhysioUuid } from '../../../api/services/therapy';
import { getTherapyPlan } from '../../../api/services/therapy_plan';
import { DoneIcon, InProgressIcon } from '../../../assets/icons';
import { CustomLoading } from '../../../components/CustomLoading';
import { Path } from '../../../router/paths';
import globalStyles from '../../../styles/global.module.scss';
import { formatDate } from '../../../utils/date';
import styles from './PatientProgressStats.module.scss';

const PatientProgressStats = () => {
  const navigate = useNavigate();
  const { id: physioUuid } = useParams();
  const [day, setDay] = useState(0);
  const [maxDay, setMaxDay] = useState(1);

  const {
    data: therapy,
    status,
    isLoading,
  } = useQuery(
    ['therapy', physioUuid],
    // @ts-ignore
    () => getTherapyByPhysioUuid(physioUuid).then((res) => res.data),
    { enabled: !!physioUuid }
  );

  const {
    data: therapyExercises,
    status: statusTherapyExercises,
    isLoading: isExercisesLoading,
  } = useQuery(
    ['therapy-plan', therapy],
    // @ts-ignore
    () => getTherapyPlan(therapy.id).then((res) => res.data),
    { enabled: !!therapy }
  );

  useEffect(() => {
    if (therapyExercises?.length) {
      const days = [
        ...new Set(therapyExercises.map((item) => item.day_number)),
      ];
      let currentDay = 1;
      days.forEach((d) => {
        if (
          therapyExercises
            .filter((item) => item.day_number === d)
            .map((i) => {
              return i;
            })
            .every((item) => {
              const today = new Date().toISOString().slice(0, 10);
              const doneDate = item.done?.toString().slice(0, 10)
                ? item.done?.toString().slice(0, 10)
                : today;
              return item.done && doneDate < today;
            })
        ) {
          if (days.includes(currentDay + 1)) {
            currentDay += 1;
          }
        }
      });
      setDay(currentDay);
      setMaxDay(Math.max(...days));
    }
  }, [therapyExercises]);

  const inProgressDay = (
    dayNumber: number,
    therapyPlan: TherapyPlanResponse[]
  ) => {
    const currentDayPlan = therapyPlan.filter(
      (i) => i.day_number === dayNumber
    );
    return (
      currentDayPlan.some((i) => i.done) && !currentDayPlan.every((i) => i.done)
    );
  };

  const doneDay = (dayNumber: number, therapyPlan: TherapyPlanResponse[]) => {
    const allDone = therapyPlan
      .filter((i) => i.day_number === dayNumber)
      .every((i) => i.done);

    return allDone;
  };

  if (isLoading || isExercisesLoading) {
    return <CustomLoading />;
  }

  if (
    statusTherapyExercises === 'error' ||
    status === 'error' ||
    !therapy ||
    !therapyExercises
  ) {
    return (
      <div className={`${globalStyles.container} ${styles.container}`}>
        Wystąpił problem z tym planem ćwiczeń, skontaktuj się z administratorem
      </div>
    );
  }

  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      <div className={styles.uuid}>{therapy?.physio_link}</div>
      <div
        className={styles.checkDiagnosis}
        onClick={() =>
          navigate(
            `${Path.PATIENT_ANSWERS}/${therapy?.diagnosis.unique_id}/${therapy?.physio_link}`
          )
        }
      >
        Sprawdź załączoną ankietę
      </div>
      <div className={styles.topText}>
        Data dodania: <b>{formatDate(therapy.creation_time.toString())}</b>
      </div>
      <div className={styles.topText}>
        Dni ćwiczeń:
        <b>
          {day}/{maxDay}
        </b>
      </div>
      <div>
        <Button
          size="small"
          variant="outlined"
          component={Link}
          to={`${Path.JOIN_VIDEO_CALL}/${therapy?.patient_link}`}
          color="error"
        >
          Kontakt z pacjentem
        </Button>
      </div>
      <div className={styles.exercisesStatus}>
        {[...new Set(therapyExercises.map((item) => item.day_number))].map(
          (curDay) => (
            <div
              className={`${styles.day} ${
                therapyExercises
                  .filter((i) => i.day_number === curDay)
                  .some((i) => i.done)
                  ? styles.dayInProgress
                  : ''
              } ${
                therapyExercises
                  .filter((i) => i.day_number === curDay)
                  .every((i) => i.done)
                  ? styles.dayDone
                  : ''
              }`}
            >
              {inProgressDay(curDay, therapyExercises) ? (
                <div className={styles.progressIcon}>
                  <InProgressIcon color="#164C8A" />
                </div>
              ) : null}

              {doneDay(curDay, therapyExercises) ? (
                <div className={styles.progressIcon}>
                  <DoneIcon color="#1DC96C" />
                </div>
              ) : null}

              <div className={styles.dayData}>Dzień {curDay}</div>
              {therapyExercises
                .filter((i) => i.day_number === curDay)
                .map((exercise) => (
                  <div
                    className={
                      exercise.monitoring
                        ? styles.dayMonitoring
                        : styles.dayExercise
                    }
                  >
                    {exercise?.done ? (
                      <div className={styles.exerciseDone}>
                        {formatDate(exercise.done.toString())}
                      </div>
                    ) : (
                      <div />
                    )}
                    {exercise.monitoring ? (
                      <>
                        <div className={styles.monitoring}>
                          Monitorowanie postępów {exercise?.monitoring_number}
                        </div>
                        <div className={styles.monitoringQuestion}>
                          Jak się czujesz w skali -2 do 2?
                          <div className={styles.monitoringAnswer}>
                            {exercise?.monitoring_answer !== null
                              ? exercise.monitoring_answer
                              : 'Brak odpowiedzi'}
                          </div>
                          Natężenie bólu w skali 0 do 10?
                          <div className={styles.monitoringAnswer}>
                            {exercise?.monitoring_pain !== null
                              ? exercise.monitoring_pain
                              : 'Brak odpowiedzi'}
                          </div>{' '}
                        </div>
                      </>
                    ) : (
                      <div className={styles.dayExerciseBox}>
                        <div className={styles.exeNumber}>
                          Ćwiczenie {exercise?.exercise_number}
                        </div>
                        <div>
                          <div>
                            {exercise?.exe?.minutes &&
                              `Minuty ${exercise?.exe?.minutes}`}
                          </div>
                          <div>
                            {exercise?.exe?.repeats &&
                              `Powtórzenia ${exercise?.exe?.repeats}`}
                          </div>
                        </div>
                        <Tooltip title={exercise.exe?.description}>
                          <div className={styles.exeDesc}>Zobacz opis</div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                ))}
              <div className={styles.dayExerciseEmpty} />
              <div className={styles.dayExerciseEmpty} />
              <div className={styles.dayExerciseEmpty} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PatientProgressStats;
