import { Helmet } from 'react-helmet';

import { calendarIcon, laptopIcon, personIcon } from '../../../assets/icons';
import { TherapyPlanImg } from '../../../assets/images/pics/therapy-plan';
import { TrainingsImg } from '../../../assets/images/pics/trainings';
import {
  InfoWithBigBackground,
  LinkBoxWithButton,
  LinkBoxWithImage,
  ProsMainBox,
} from '../../../components';
import { Version } from '../../../components/InfoWithBigBackground/InfoWithBigBackground.types';
import { Path } from '../../../router/paths';
import globalStyles from '../../../styles/global.module.scss';
import styles from './Patient.module.scss';

const mockedInfoWithBigBackgroundData = {
  littleHeader: 'Witamy w strefie pacjenta',
  header: 'Zdalna fizjoterapia',
  description:
    'Dzięki naszej aplikacji możesz przeprowadzić ankietę wydolności i otrzymać pakiet ćwiczeń do wykonywania w domu.<br/><span style="color:#cc0000; font-weight:bold">Zdalna fizjoterapia nie zastępuje wizyty u lekarza ani wizyty u fizjoterapeuty</span>',
};

const mockedProsData = [
  {
    icon: laptopIcon,
    header: 'Całkowicie online',
    description:
      'Ankieta wydolności, plan ćwiczeń i monitoring postępów i kontakt z pacjentem - wszystko z poziomu WebApp',
  },
  {
    icon: personIcon,
    header: 'Ćwiczenia bez fizjoterapeuty',
    description:
      'Ćwiczenia wraz z filmami i dokładnymi opisami zostały specjalnie wyselekcjonowane aby umożliwić ćwiczenie bez fizjoterapeuty.',
  },
  {
    icon: calendarIcon,
    header: 'Zdalny monitoring postępów',
    description:
      'Fizjoterapeuta w czasie rzeczywistym może monitorować Twoje postępy i w razie potrzeby zmodyfikować plan ćwiczeń.',
  },
];

const mockLinkBoxWithImage = [
  {
    text_up: 'Znajdź',
    text_down: 'fizjoterapeutę',
    path: '/baza-fizjoterapeutow',
    image: TrainingsImg,
    bgColor: '#E9E3E6',
    color: '#E65F5C',
  },
  {
    text_up: 'Twój',
    text_down: 'Plan ćwiczeń',
    path: '/wyszukaj-plan-terapii',
    image: TherapyPlanImg,
    bgColor: '#E9E3E6',
    color: '#E65F5C',
  },
];

const Patient = () => {
  return (
    <>
      {' '}
      <Helmet>
        <title>Dla Pacjenta - Zdalne Konsultacje KIF</title>
      </Helmet>
      <div className={styles.container}>
        <InfoWithBigBackground
          version={Version.PATIENT}
          littleHeader={mockedInfoWithBigBackgroundData.littleHeader}
          header={mockedInfoWithBigBackgroundData.header}
          description={mockedInfoWithBigBackgroundData.description}
        />

        <ProsMainBox data={mockedProsData} color="#E65F5C" />

        <div className={`${globalStyles.container} ${styles.linkBoxes}`}>
          <div className={styles.linkBox}>
            <LinkBoxWithButton
              bgColor="#E9E3E6"
              color="#E65F5C"
              header=""
              title="Ankieta wydolności"
              description="Rozwiąż wstępna ankietę wydolności"
              path={Path.DIAGNOSIS}
            />
          </div>
          <div className={styles.boxes}>
            {mockLinkBoxWithImage.map((props) => (
              /* eslint-disable-next-line react/prop-types */
              <LinkBoxWithImage {...props} key={props.text_up!} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Patient;
