import '../assets/jspdf-fonts/Lato-Bold';
import '../assets/jspdf-fonts/Lato-Regular';

import JSPDF from 'jspdf';

interface IProps {
  html: string;
  filename: string;
}
const htmlToPdf: (props: IProps) => Promise<void> = ({ html, filename }) => {
  return new Promise((resolve) => {
    const doc = new JSPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'a4', // [595.28,  841.89]
      putOnlyUsedFonts: true,
    });

    // Adding the fonts.
    doc.setFont('Lato', 'normal');
    doc.setFont('Lato', 'bold');

    const marginBlock = 30;
    const marginInline = 30;

    doc.html(
      `
        <div style="
          width: calc(595.28px - 2 * ${marginInline}px);
          font-family: Lato, sans-serif;
          font-size: 12px;
          line-height: 1.5;
        ">
          ${html}
        </div>
      `,
      {
        async callback(d) {
          await d.save(filename);

          resolve();
        },
        margin: [marginBlock, marginInline, marginBlock, marginInline],
        autoPaging: 'text',
      }
    );
  });
};

export default htmlToPdf;
