import { Helmet } from 'react-helmet';

import { Path } from '../../router/paths';
import globalStyles from '../../styles/global.module.scss';
import styles from './Main.module.scss';

const Main = () => {
  return (
    <>
      <Helmet>
        <title>Zdalne konsultacje KIF</title>
      </Helmet>
      <div>
        <div style={{ textAlign: 'center' }}>
          <div className={`${globalStyles.container} ${styles.infodemia}`}>
            <h1 className={styles.infodemiaHeader}>
              Czym są zdalne konsultacje?
            </h1>
            <div className={styles.infodemiaText}>
              Niniejsza platforma służy jako jedno z działań związanych z
              problematyką Infodemii poprzez promowanie i przekazywanie wiedzy
              na temat, postaw i zachowań właściwych dla podejmowania decyzji
              zdrowotnych w oparciu o dane i informacje naukowe. <br />
              Serwis stanowi mobilny system informacyjno-użytkowy. Skierowany
              jest do fizjoterapeutów oraz pacjentów podzielony na obszar
              informacyjny oraz obszar praktycznej pracy z pacjentem. Magazyn
              online zawierający skategoryzowane treści newsowe, informacyjne,
              publicystyczne i naukowe.
              <br />
              <span style={{ color: '#cc0000', fontSize: '1rem' }}>
                Moduł Zdalne konsultacje nie zastępuje wizyty u lekarza ani
                wizyty u fizjoterapeuty.
                <br />
                Każdy niepokojący objaw wymaga skonsultowania z lekarzem lub
                fizjoterapeutą
              </span>
            </div>
          </div>
        </div>
        <div className={`${globalStyles.container} ${styles.infodemia}`}>
          <h1 className={styles.patientHeader}>Jesteś pacjentem?</h1>
          <div className={styles.patientWrapper}>
            <div className={styles.patientWrapperBoxes}>
              <div className={styles.patientWrapperBoxLeft}>
                <a className={styles.patientWrapperBox} href={Path.DIAGNOSIS}>
                  <div className={styles.patientWrapperBoxHeader}>
                    ANKIETA
                    <h2>WYDOLNOŚCI</h2>
                    <span>Rozwiąż wstępna ankietę wydolności</span>
                  </div>
                </a>
              </div>

              <div className={styles.patientWrapperBoxRight}>
                <a
                  className={styles.patientWrapperBox}
                  href="/wyszukaj-plan-terapii"
                >
                  <div className={styles.patientWrapperBoxHeader}>
                    TWÓJ
                    <h2>PLAN ĆWICZEŃ</h2>
                  </div>
                </a>{' '}
                <a
                  className={styles.patientWrapperBox}
                  href="/baza-fizjoterapeutow"
                >
                  <div className={styles.patientWrapperBoxHeader}>
                    ZNAJDŹ
                    <h2>FIZJOTERAPEUTĘ</h2>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <div style={{ textAlign: 'right', marginTop: '50px' }}>
            {' '}
            <h1 className={styles.fizjoHeader}>Jesteś fizjoterapeutą?</h1>
          </div>

          <div className={styles.fizjoWrapper}>
            <div className={styles.fizjoWrapperBoxes}>
              <div className={styles.fizjoWrapperBoxLeft}>
                <a
                  className={styles.fizjoWrapperBox}
                  href={Path.PREPARE_THERAPY_PLAN}
                >
                  <div className={styles.fizjoWrapperBoxHeader}>
                    PRZYGOTUJ
                    <h2>PLAN ĆWICZEŃ ONLINE</h2>
                    <span>
                      Skorzystaj z naszego narzędzia i przygotuj zestaw ćwiczeń,
                      który przekażesz pacjentowi
                    </span>
                    <br />
                  </div>
                </a>
              </div>

              <div className={styles.fizjoWrapperBoxRight}>
                <a
                  className={styles.fizjoWrapperBox}
                  href="/sprawdz-plan-pacjenta"
                >
                  <div className={styles.fizjoWrapperBoxHeader}>
                    SPRAWDŹ
                    <h2>PLAN PACJENTA</h2>
                  </div>
                </a>{' '}
                <a className={styles.fizjoWrapperBox} href="/baza-cwiczen">
                  <div className={styles.fizjoWrapperBoxHeader}>
                    ZOBACZ
                    <h2>BAZĘ ĆWICZEŃ</h2>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Main;
