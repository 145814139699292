export enum Version {
  PATIENT = 'pacjent',
  PHYSIOTHERAPIST = 'fizjoterapeuta',
}

export interface InfoWithBigBackgroundProps {
  littleHeader: string;
  header: string;
  description: string;
  version: 'pacjent' | 'fizjoterapeuta';
}
