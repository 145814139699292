/* eslint-disable react/style-prop-object */
const TriangleIcon = ({
  className,
  color,
  inHeader,
}: {
  className?: string;
  color: string;
  inHeader: boolean;
}) => {
  return (
    <div
      style={inHeader ? { transform: 'scale(0.5) rotate(80deg)' } : {}}
      className={className}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="105"
        height="112"
        viewBox="0 0 105 112"
        fill="none"
      >
        <mask
          id="mask0_20_935"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="105"
          height="112"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105 112L-7.62939e-06 112L2.16196e-06 -1.55e-06L105 7.62939e-06L105 112Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_20_935)">
          <mask
            id="mask1_20_935"
            maskUnits="userSpaceOnUse"
            x="8"
            y="-1"
            width="97"
            height="113"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M105 56.1755L8.40314 -0.00122915L8.41116 112L105 56.1755Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_20_935)">
            <path
              d="M105.588 112.589L7.81418 112.589L7.81419 -0.589921L105.588 -0.589912L105.588 112.589Z"
              fill={color}
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

TriangleIcon.defaultProps = {
  className: '',
};

export default TriangleIcon;
