import { Close as CloseIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import {
  closeSnackbar,
  enqueueSnackbar,
  SnackbarKey,
  SnackbarProvider,
} from 'notistack';
import { useEffect, useState } from 'react';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const serviceWorkerEnabled =
  process.env.REACT_APP_ENV === 'production' && false;

if (serviceWorkerEnabled) {
  serviceWorkerRegistration.register({
    onSuccess() {
      enqueueSnackbar('Aplikacja gotowa by działać offline', {
        action: (id) => (
          <IconButton
            size="small"
            color="inherit"
            onClick={() => closeSnackbar(id)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ),
      });
    },
    onUpdate(registration) {
      window.addEventListener('beforeunload', () => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      });

      enqueueSnackbar('Dostępna jest nowa wersja strony', {
        persist: true,
        action: () => (
          <Button
            size="small"
            color="inherit"
            onClick={() => {
              registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
              window.location.reload();
            }}
          >
            Odśwież
          </Button>
        ),
      });
    },
  });
} else {
  serviceWorkerRegistration.unregister();
}

export const AppController = () => {
  if (serviceWorkerEnabled === false) {
    return null;
  }

  const [online, isOnline] = useState(navigator.onLine);
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey | null>(null);

  const setOnline = () => isOnline(true);
  const setOffline = () => isOnline(false);

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  useEffect(() => {
    if (snackbarKey) {
      closeSnackbar(snackbarKey);
    }

    if (!online) {
      setSnackbarKey(
        enqueueSnackbar('Aplikacja działa w trybie offline', {
          persist: true,
        })
      );
    }
  }, [online]);

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  );
};

export default AppController;
