import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { getTherapyByPatientUuid } from '../../api/services/therapy';
import { CustomTextField } from '../../components';
import { Path } from '../../router/paths';
import { customButtonTheme } from '../../styles/buttons.mui';
import globalStyles from '../../styles/global.module.scss';
import styles from './FindTherapyPlan.module.scss';

const FindTherapyPlan = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');

  const [patientUuid, setPatientUuid] = useState<string>('');
  const formik = useFormik({
    initialValues: {
      plan: '',
    },
    onSubmit: () => {},
  });

  const { data, status } = useQuery(
    ['therapy', patientUuid],
    () => getTherapyByPatientUuid(patientUuid).then((res) => res.data),
    {
      enabled: !!patientUuid,
      // refetchOnMount: false,
      retry: false,
      // refetchInterval: false,
      // refetchOnWindowFocus: false,
      onError: (err) => {
        setError(
          // @ts-ignore
          err.response?.data?.message || 'Wystąpił nieoczekiwany błąd'
        );
      },
    }
  );

  useEffect(() => {
    if (data?.patient_link) {
      navigate(`${Path.THERAPY_PLAN}\\${data.patient_link}`);
    }
  }, [data]);

  if (status === 'error') {
    if (error) {
      toast.error(error, {
        id: 'error',
      });
      setPatientUuid('');
    }
  }

  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      <div className={styles.text}>Wpisz poniżej ID</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.text}>
          <CustomTextField
            id="plan"
            label="Wpisz numer"
            variant="outlined"
            sx={{ margin: '0 0 10px 0' }}
            onChange={formik.handleChange}
            value={formik.values.plan}
          />
        </div>
        <div className={styles.submit}>
          <ThemeProvider theme={customButtonTheme}>
            <Button
              variant="contained"
              onClick={() => setPatientUuid(formik?.values?.plan)}
              disabled={formik?.values?.plan?.length < 36}
            >
              Przejdź do ćwiczeń
            </Button>
          </ThemeProvider>
        </div>
      </form>
    </div>
  );
};

export default FindTherapyPlan;
