import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const CustomTextField = styled(TextField)({
  '&': {
    '& label.Mui-focused': {
      color: '#76446B',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#76446B',
      },
      '&:hover fieldset': {
        borderColor: '#76446B',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#76446B',
      },
    },
  },
});

export default CustomTextField;
