/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { LiveHelpOutlined } from '@mui/icons-material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Button, ThemeProvider, Tooltip } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { DiagnosisDto } from '../../../api/models/DiagnosisDto';
import { ExercisesDto, MediaType } from '../../../api/models/ExercisesDto';
import { TherapyDto } from '../../../api/models/TherapyDto';
import { getDiagnosis } from '../../../api/services/diagnosis';
import { getExercises } from '../../../api/services/exercises';
import {
  createTherapy,
  getTherapyByDiagnosisId,
} from '../../../api/services/therapy';
import { createTherapyPlan } from '../../../api/services/therapy_plan';
import { removeIcon } from '../../../assets/icons';
import { CustomTextField } from '../../../components';
import { HeaderWithTriangles } from '../../../components/HeaderWithTriangles';
import { Path } from '../../../router/paths';
import {
  customButtonTheme,
  simpleButtonTheme,
} from '../../../styles/buttons.mui';
import globalStyles from '../../../styles/global.module.scss';
import { TherapyPlanDay } from '../../TherapyPlan/TherapyPlan.types';
import { ExercisesByCat } from '../ExercisesList/ExercisesList';
import { PatientAnswers } from '../PatientAnswers';
import ExerciseCategory from './components/ExerciseCategory';
import styles from './PrepareTherapyPlan.module.scss';

const PrepareTherapyPlan = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const { id: therapyId } = useParams();
  const [error, setError] = useState<string>();
  const [therapyExist, setTherapyExist] = useState<boolean>(false);
  const [answersPreview, setAnswersPreview] = useState<boolean>(true);
  const [diagnosisId, setDiagnosisId] = useState<number>();
  const [tempId, setTempId] = useState<string>();
  const [shouldSearchForDiagnosis, setShouldSearchForDiagnosis] =
    useState<boolean>(false);
  const [readyToSave, setReadyToSave] = useState<boolean>();
  const [therapyPlan, setTherapyPlan] = useState<TherapyPlanDay[]>([[]]);
  const [currentDay, setCurrentDay] = useState(0);
  const [currentVideo, setCurrentVideo] = useState<number>();
  const [canCheckIfExist, setCanCheckIfExist] = useState(false);
  const [exercisesByCat, setExercisesByCat] = useState<ExercisesByCat[]>();
  const exerciseScroll = useRef(null);

  const formik = useFormik({
    initialValues: {
      tempId: '',
      diagnosisId: '',
      code: '',
      email: '',
      name: '',
      pwzfz: '',
      phone: '',
      moreInfo: '',
    },
    // onSubmit: (values, { resetForm }) => {
    onSubmit: () => {
      const postValues: TherapyDto = {
        diagnosisId: formik.values.diagnosisId,
        phy_email: formik.values.email,
        phy_phone: formik.values.phone,
        phy_name: formik.values.name,
        phy_pwzfz: Number(formik.values.pwzfz),
        text_for_patient: formik.values.moreInfo,
        code: formik.values.code,
      };
      mutate({
        ...postValues,
      });
    },
  });

  const { mutate: mutateExercises } = useMutation({
    mutationFn: createTherapyPlan,
    onSuccess: (exercises: any) => {
      formik.resetForm();
      setCanCheckIfExist(false);
      setTempId('');
      console.log('posted2', exercises);
    },
  });

  const {
    error: errorCreateTherapy,
    reset,
    mutate,
  } = useMutation({
    mutationFn: createTherapy,
    onSuccess: (newTherapy: any) => {
      const list: any[] = [];
      let monitoringOrder = 0;
      let exerciseOrder = 0;
      therapyPlan.forEach((exercises, day) => {
        exercises.forEach((exercise, order) => {
          monitoringOrder = exercise.monitoring
            ? monitoringOrder + 1
            : monitoringOrder;
          exerciseOrder = exercise.monitoring
            ? exerciseOrder
            : exerciseOrder + 1;

          list.push({
            day_number: day + 1,
            therapyId: newTherapy.data.id,
            exe_count: exercise.repeats,
            exe_intensity: exercise.intensity,
            exe_minutes: exercise.minutes,
            exe_note: '',
            exeId: exercise.id,
            order_number: order + 1,
            monitoring_number: exercise.monitoring ? monitoringOrder : null,
            exercise_number: !exercise.monitoring ? exerciseOrder : null,
            monitoring: exercise.monitoring,
          });
        });
      });
      mutateExercises([...list]);

      navigate(`${Path.PLAN_GENERATED}`, {
        state: {
          physio_link: newTherapy.data.physio_link,
          patient_link: newTherapy.data.patient_link,
        },
      });
    },
  });

  useEffect(() => {
    if (therapyId) {
      formik.setFieldValue('tempId', therapyId);
    }
  }, []);

  const { data: exercisesList } = useQuery<ExercisesDto[], AxiosError>({
    queryKey: ['exercises'],
    queryFn: () => getExercises().then((res) => res.data),
    retry: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const { data: diagnosis, status: diagnosisStatus } = useQuery<
    DiagnosisDto,
    AxiosError
  >({
    queryKey: ['diagnosisPrepareTherapyPlan', tempId],
    // @ts-ignore
    queryFn: () => getDiagnosis(tempId).then((res) => res.data),
    onSuccess: (res) => {
      setCanCheckIfExist(true);
      setDiagnosisId(res?.id);
    },
    onError: (err) => {
      setError(
        // @ts-ignore
        err.response?.data?.message || 'Wystąpił nieoczekiwany błąd'
      );
    },
    enabled: shouldSearchForDiagnosis && !!tempId,
    retry: 0,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  const { status: therapyStatus } = useQuery(
    ['getTherapyByDiagnosisId'],
    () =>
      // @ts-ignore
      getTherapyByDiagnosisId(diagnosisId?.toString()).then((res) => res.data),
    {
      enabled: canCheckIfExist && !!diagnosisId,
      refetchOnMount: false,
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      onSuccess: () => setTherapyExist(true),
      onError: () => setTherapyExist(false),
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (exercisesList) {
      const getTypes = exercisesList.map((item) => item.type);
      const types = new Map();
      getTypes.forEach((type) => types.set(type.id, type.type));
      const exercises: ExercisesByCat[] = [];
      types.forEach((item, index) =>
        exercises.push({
          id: index,
          type: item,
          exercises: [...exercisesList.filter((d) => d.type.id === index)],
        })
      );
      setExercisesByCat(exercises);
    }
  }, [exercisesList]);

  const addExercise = (data: ExercisesDto) => {
    toast.success('Dodano ćwiczenie');
    setTherapyPlan((prev) => {
      const newExercise = [...prev];
      newExercise[currentDay].push(data);
      return newExercise;
    });
  };

  const addNextDay = () => {
    if (therapyPlan[currentDay].length > 0) {
      setCurrentDay((prev) => prev + 1);
      setTherapyPlan((prevState) => [...prevState, []]);
      toast.success('Zapisano dzień');
      setTimeout(function () {
        const scrollElement = document.getElementById('exerciseScroll');
        if (scrollElement) {
          scrollElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
    }
  };

  useEffect(() => {
    if (diagnosis && diagnosis?.id && diagnosis.id > 0) {
      formik.setFieldValue('diagnosisId', diagnosis.id);
      console.log('exist');
      // navigate(`${Path.PREPARE_THERAPY_PLAN}/${diagnosis.unique_id}`);
    }
  }, [diagnosis]);

  const removeExercise = (index: number) => {
    const newTherapyPlan = therapyPlan[currentDay].filter(
      (item, i) => i !== index
    );
    setTherapyPlan((prev) => {
      prev[currentDay] = newTherapyPlan;
      return [...prev];
    });
  };

  const addMonitoring = () => {
    toast.success('Dodano monitorowanie postępów');
    setTherapyPlan((prev) => {
      const newExercise = [...prev];
      // @ts-ignore
      newExercise[currentDay].push({ monitoring: 1 });
      return newExercise;
    });
  };

  const preparePlan = () => {
    setTempId(formik.values.tempId);
    setShouldSearchForDiagnosis(true);
    navigate(`${Path.PREPARE_THERAPY_PLAN}/${formik.values.tempId}`);
    setTimeout(function () {
      window.scrollTo(0, 0);
    }, 500);
  };

  if (errorCreateTherapy) {
    console.log('errorCreateTherapy', errorCreateTherapy);
    return (
      <div className={`${globalStyles.container} ${styles.container}`}>
        Wystąpił problem z utworzeniem planu.{' '}
        {(errorCreateTherapy as any)?.response?.data?.message}
        <Button
          variant="contained"
          onClick={() => {
            reset();
            setReadyToSave(false);
          }}
          style={{ margin: '10px 0 0 0' }}
        >
          Wróć do edycji
        </Button>
      </div>
    );
  }

  if (readyToSave) {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={`${globalStyles.container} ${styles.container}`}>
          <HeaderWithTriangles
            title="Zatwierdź plan ćwiczeń"
            className={styles.header}
          />
          <div className={styles.textCheck}>
            Poniżej możesz zobaczyc cały stworzony plan ćwiczeń. Przed
            akceptacją sprawdź czy wszystko się zgadza.
          </div>

          <div className={styles.headerNext}>Fizjoterapeuta</div>
          <div className={styles.confirmationMainData}>
            Imię i nazwisko: {formik.values.name} <br />
            PWZFz: {formik.values.pwzfz} <br />
            Numer telefonu: {formik.values.phone} <br />
            Adres email: {formik.values.email} <br />
          </div>

          <div className={styles.headerNext}>
            Wstępne informacje dla pacjenta
          </div>
          <div className={styles.confirmationMainData}>
            {formik.values.moreInfo} <br />
          </div>

          <div className={styles.headerNext}>
            Plan ćwiczeń i monitorowania postępów
          </div>
          {therapyPlan.map((day, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} style={{ margin: '0 0 40px 0' }}>
              <div
                className={styles.day}
                style={
                  currentDay + 1 === index + 1
                    ? { color: '#76446B' }
                    : { color: '#00B815' }
                }
              >
                Dzień {index + 1}
                <div
                  onClick={() => {
                    setReadyToSave(false);
                    setCurrentDay(index);
                  }}
                  className={styles.editModeText}
                  style={
                    currentDay + 1 === index + 1
                      ? { color: '#76446B' }
                      : { color: '#000' }
                  }
                >
                  Wróć do edycji
                </div>
              </div>

              {therapyPlan[index]?.map((item, eIndex) => (
                <div key={item.id}>
                  {item?.monitoring ? (
                    <>
                      <div className={styles.exerciseTop}>
                        <div className={styles.exerciseName}>
                          Monitorowanie postępów
                        </div>
                      </div>
                      <div className={styles.box}>
                        Pacjent określi jak się czuje
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.exerciseTop}>
                        <div className={styles.exerciseName}>
                          <strong>
                            {' '}
                            {item.name
                              ? item.name
                              : `Ćwiczenie nr. ${eIndex + 1}`}
                          </strong>
                        </div>
                      </div>
                      <div className={styles.box}>
                        <div>
                          <div className={styles.description}>
                            {item?.description}
                          </div>
                          Minuty: {item?.minutes} <br />
                          Powtórzenia: {item?.repeats} <br />
                          {/* Intensywność: {item?.intensity} <br /> */}
                        </div>
                        <div>
                          {item.media_type === MediaType.VIDEO && (
                            <div className={styles.videoContainer}>
                              {currentVideo === item.id ? (
                                <video
                                  width="280"
                                  height="280"
                                  autoPlay
                                  controls
                                  poster={
                                    // @ts-ignore
                                    item?.media_url?.match(/\d+/)[0]
                                      ? `${
                                          process.env.REACT_APP_API_HOST
                                        }/media/video/screens/video_${
                                          // @ts-ignore
                                          item.media_url.match(/\d+/)[0]
                                        }_1.jpg`
                                      : ''
                                  }
                                >
                                  <source
                                    src={`${item.media_url}`}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <div
                                  className={styles.imgBox}
                                  onClick={() => setCurrentVideo(item.id)}
                                >
                                  <img
                                    className={styles.videoImg}
                                    src={
                                      // @ts-ignore
                                      item?.media_url?.match(/\d+/)[0]
                                        ? `${
                                            process.env.REACT_APP_API_HOST
                                          }/media/video/screens/video_${
                                            // @ts-ignore
                                            item.media_url.match(/\d+/)[0]
                                          }_1.jpg`
                                        : ''
                                    }
                                    alt=""
                                  />
                                  <PlayCircleIcon
                                    fontSize="large"
                                    className={styles.playButton}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}

          <div className={styles.buttons}>
            <ThemeProvider theme={customButtonTheme}>
              <Button variant="contained" type="submit">
                Zapisz i aktywuj plan
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </form>
    );
  }

  // if (exercisesListStatus === 'error') {
  //   return (
  //     <div className={`${globalStyles.container} ${styles.container}`}>
  //       Wystąpił błąd podczas pobierania listy ćwiczeń
  //     </div>
  //   );
  // }

  if (diagnosisStatus === 'error') {
    if (error) {
      toast.error(error, {
        id: 'error',
      });
    }
  }

  if (therapyStatus === 'error' && canCheckIfExist && !!diagnosisId) {
    if (answersPreview) {
      return (
        <div className={styles.centered}>
          {therapyId && <PatientAnswers />}
          <ThemeProvider theme={customButtonTheme}>
            <Button
              variant="contained"
              onClick={() => {
                setAnswersPreview(false);
                window.scrollTo(0, 0);
              }}
            >
              Przejdź dalej
            </Button>
          </ThemeProvider>
        </div>
      );
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <div className={`${globalStyles.container} ${styles.container}`}>
          {' '}
          <ThemeProvider theme={simpleButtonTheme}>
            <Button variant="contained" onClick={() => setAnswersPreview(true)}>
              Zobacz odpowiedzi pacjenta
            </Button>
          </ThemeProvider>
          <h2 className={styles.header}>Dodaj plan ćwiczeń</h2>
          <div className={styles.mainInfo}>
            <div className={styles.mainInfoLeft}>
              Aplikacja daje Ci możliwość stworzenia online planu ćwiczeń dla
              Twojego pacjenta. Możesz wybrać dowolną ilość ćwiczeń z naszej
              bazy, dodać monitoring i obserwować zdalnie postępy...
              <br /> <br />
              Aby rozpocząć wypełnij wszystkie niezbędne pola z prawej strony (
              <strong>
                Kod Fizjoterapeuty możesz wygenerować{' '}
                <a href={`${Path.GETCODE}`} target="_blank" rel="noreferrer">
                  klikając tutaj
                </a>{' '}
              </strong>
              ), a następnie dodawaj dni i dedykowane im ćwiczenia oraz
              monitoringi. Po wszystkim kliknij zakończ i przekaż pacjentowi
              unikalny link/numer planu. Pamiętaj aby samemu także zapisać go w
              dokumentacji medycznej pacjenta.
            </div>
            <div className={styles.mainFormData}>
              <div className={styles.form}>
                <div className={styles.inputWithHelp}>
                  {' '}
                  <Tooltip enterTouchDelay={0} title="Twoje imię i nazwisko">
                    <div className={styles.helpIcon}>
                      <LiveHelpOutlined />
                    </div>
                  </Tooltip>{' '}
                  <CustomTextField
                    id="name"
                    size="small"
                    label="Imię i nazwisko"
                    variant="outlined"
                    required
                    sx={{ margin: '0 0 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
                <CustomTextField
                  id="pwzfz"
                  size="small"
                  label="PWZFZ"
                  variant="outlined"
                  required
                  sx={{ margin: '0 0 10px 0' }}
                  onChange={formik.handleChange}
                  value={formik.values.pwzfz}
                />
                <div className={styles.inputWithHelp}>
                  {' '}
                  <Tooltip
                    enterTouchDelay={0}
                    title="Tworzenie planu ćwiczeń dostępne jest jedynie dla fizjoterapeutów. Kod Fizjoterapeuty pobierzesz logując się do Portalu Fizjoterapeuty i klikając w link dostępny w stopce w zakłade TWÓJ KOD"
                  >
                    <div className={styles.helpIcon}>
                      <LiveHelpOutlined />
                    </div>
                  </Tooltip>
                  <CustomTextField
                    id="code"
                    size="small"
                    label="Kod Fizjoterapeuty"
                    variant="outlined"
                    required
                    sx={{ margin: '0 0 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.code}
                  />
                </div>
                <div className={styles.inputWithHelp}>
                  {' '}
                  <Tooltip
                    enterTouchDelay={0}
                    title="Twój numer telefonu - będzie dostępny dla pacjenta przy jego planie ćwiczeń"
                  >
                    <div className={styles.helpIcon}>
                      <LiveHelpOutlined />
                    </div>
                  </Tooltip>{' '}
                  <CustomTextField
                    id="phone"
                    size="small"
                    label="Numer telefonu"
                    variant="outlined"
                    sx={{ margin: '0 0 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.phone}
                  />
                </div>
                <div className={styles.inputWithHelp}>
                  {' '}
                  <Tooltip
                    enterTouchDelay={0}
                    title="Twój adres email - będzie dostępny dla pacjenta przy jego planie ćwiczeń"
                  >
                    <div className={styles.helpIcon}>
                      <LiveHelpOutlined />
                    </div>
                  </Tooltip>{' '}
                  <CustomTextField
                    id="email"
                    size="small"
                    label="Adres e-mail"
                    variant="outlined"
                    sx={{ margin: '0 0 10px 0' }}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.moreInfo}>
            <CustomTextField
              id="moreInfo"
              multiline
              rows={5}
              label="Wstępne informacja dla pacjenta"
              variant="outlined"
              sx={{ margin: '0 0 10px 0', width: '100%' }}
              onChange={formik.handleChange}
              value={formik.values.moreInfo}
            />
          </div>
          <div className={styles.selectedExercises}>
            {therapyPlan.map((day, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={styles.exerciseDayWrap}
                style={
                  currentDay + 1 === index + 1
                    ? { borderColor: '#76446B' }
                    : { borderColor: '#ccc' }
                }
              >
                <div
                  className={styles.day}
                  style={
                    currentDay + 1 === index + 1
                      ? { color: '#76446B' }
                      : { color: '#aaaaaa' }
                  }
                >
                  Dzień {index + 1}
                  <div
                    onClick={() => setCurrentDay(index)}
                    className={styles.editModeText}
                    style={
                      currentDay + 1 === index + 1
                        ? { color: '#76446Bxxx' }
                        : { color: '#000xxx' }
                    }
                  >
                    {currentDay + 1 === index + 1
                      ? 'W trakcie edycji'
                      : 'Wróć do edycji'}
                  </div>
                </div>

                {currentDay + 1 === index + 1 &&
                  therapyPlan[currentDay]?.map((item, exeIndex) => (
                    <div key={item.id}>
                      {item?.monitoring ? (
                        <>
                          <div className={styles.exerciseTop}>
                            <div className={styles.exerciseName}>
                              Monitorowanie postępów
                            </div>
                            <div className={styles.options}>
                              <img
                                src={removeIcon}
                                alt=""
                                className={styles.optionIcon}
                                onClick={() => removeExercise(exeIndex)}
                              />
                            </div>
                          </div>
                          <div className={styles.box}>
                            Pacjent określi jak się czuje
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={styles.exerciseTop}>
                            <div className={styles.exerciseName}>
                              Ćwiczenie {exeIndex + 1}
                            </div>
                            <div className={styles.options}>
                              <img
                                src={removeIcon}
                                alt=""
                                className={styles.optionIcon}
                                onClick={() => removeExercise(exeIndex)}
                              />
                            </div>
                          </div>
                          {console.log('item', item)}
                          <div className={styles.box}>
                            <div>
                              <div className={styles.description}>
                                {item?.description}
                              </div>
                              Minuty: {item?.minutes} <br />
                              Powtórzenia: {item?.repeats} <br />
                              {/* Intensywność: {item?.intensity} <br /> */}
                            </div>
                            <div>
                              {item.media_type === MediaType.VIDEO && (
                                <div className={styles.videoContainer}>
                                  {currentVideo === item.id ? (
                                    <video
                                      width="280"
                                      height="280"
                                      autoPlay
                                      controls
                                      poster={
                                        // @ts-ignore
                                        item?.media_url?.match(/\d+/)[0]
                                          ? `${
                                              process.env.REACT_APP_API_HOST
                                            }/media/video/screens/video_${
                                              // @ts-ignore
                                              item.media_url.match(/\d+/)[0]
                                            }_1.jpg`
                                          : ''
                                      }
                                    >
                                      <source
                                        src={`${item.media_url}`}
                                        type="video/mp4"
                                      />
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  ) : (
                                    <div
                                      className={styles.imgBox}
                                      onClick={() => setCurrentVideo(item.id)}
                                    >
                                      <img
                                        className={styles.videoImg}
                                        src={
                                          // @ts-ignore
                                          item?.media_url?.match(/\d+/)[0]
                                            ? `${
                                                process.env.REACT_APP_API_HOST
                                              }/media/video/screens/video_${
                                                // @ts-ignore
                                                item.media_url.match(/\d+/)[0]
                                              }_1.jpg`
                                            : ''
                                        }
                                        alt=""
                                      />
                                      <PlayCircleIcon
                                        fontSize="large"
                                        className={styles.playButton}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
          <div
            className={styles.exerciseBox}
            id="exerciseScroll"
            ref={exerciseScroll}
          >
            <div className={styles.days}>
              <div>
                <div className={styles.addExercise}>Dodaj ćwiczenie</div>
                <div>
                  {exercisesByCat
                    ?.filter(
                      (category) =>
                        category.type !==
                        'Ćwiczenia oddechowe w pozycji siedzącej'
                    )
                    .map((exercises) => (
                      <ExerciseCategory
                        key={exercises.id}
                        category={exercises}
                        onAddExercise={addExercise}
                      />
                    ))}
                </div>
                <div
                  className={styles.addExercise}
                  style={{ textAlign: 'center' }}
                >
                  <ThemeProvider theme={simpleButtonTheme}>
                    <Button variant="contained" onClick={addMonitoring}>
                      Dodaj monitorowanie postępów
                    </Button>
                  </ThemeProvider>
                </div>
                <div />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <ThemeProvider theme={customButtonTheme}>
              <Button
                variant="contained"
                onClick={addNextDay}
                style={{ margin: '0 20px 0 0' }}
                disabled={therapyPlan[currentDay].length === 0}
              >
                Zapisz dzień
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={customButtonTheme}>
              <Button
                variant="contained"
                onClick={() => {
                  setReadyToSave(true);
                  window.scrollTo(0, 0);
                }}
              >
                Zakończ plan
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </form>
    );
  }

  if (therapyExist) {
    return (
      <div className={`${globalStyles.container} ${styles.container}`}>
        <div className={styles.mainInfoLeft}>
          Dla tej ankiety już istnieje plan ćwiczeń. <br />
          Jeżeli chcesz go zobaczyć użyj kodu fizjoterapeuty.
          <br />
          Jeżeli chcesz stworzyć nową terapię - rozpocznij od wykonania ankiety
          z pacjentem.
          <div style={{ marginTop: '30px' }}>
            <ThemeProvider theme={customButtonTheme}>
              <Button
                variant="contained"
                style={{ alignSelf: 'center' }}
                component={Link}
                to={Path.DIAGNOSIS_BY_PHYSIO}
              >
                Ocena stanu zdrowia z pacjentem
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      <div className={styles.text}>
        {therapyId
          ? 'ID wypełnionej ankiety wydolności pacjenta:'
          : 'Wpisz poniżej unikalny numer id ankiety wydolności pacjenta'}
      </div>
      <form onSubmit={formik.handleSubmit} style={{ width: '60%' }}>
        <div className={styles.text}>
          <CustomTextField
            id="tempId"
            label={therapyId ? '' : 'Wpisz numer'}
            variant="outlined"
            size="medium"
            sx={{ margin: '0 0 10px 0', width: '100%' }}
            onChange={formik.handleChange}
            value={formik.values.tempId}
          />
        </div>
        <div className={styles.submit}>
          <ThemeProvider theme={customButtonTheme}>
            <Button
              variant="contained"
              disabled={formik?.values?.tempId?.length < 36}
              onClick={preparePlan}
            >
              Przejdź do tworzenia planu
            </Button>
          </ThemeProvider>
        </div>
      </form>
      <div className={therapyId ? styles.createNewSml : styles.createNew}>
        Chcesz stworzyć nową ankietę wydolności?
      </div>
      <div>
        <ThemeProvider
          theme={therapyId ? simpleButtonTheme : customButtonTheme}
        >
          <Button
            variant="contained"
            component={Link}
            to={Path.DIAGNOSIS_BY_PHYSIO}
          >
            Przeprowadź ankietę wydolności
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default PrepareTherapyPlan;
