const ArrowEmptyIcon = ({ color }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
    >
      <path d="M1 1.5718L13 8.5L0.999999 15.4282L1 1.5718Z" stroke={color} />
    </svg>
  );
};

export default ArrowEmptyIcon;
