import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getTherapyByPhysioUuid } from '../../../api/services/therapy';
import { CustomTextField } from '../../../components';
import { Path } from '../../../router/paths';
import { customButtonTheme } from '../../../styles/buttons.mui';
import globalStyles from '../../../styles/global.module.scss';
import styles from './FindDiagnosisResult.module.scss';

const FindDiagnosisResult = () => {
  const navigate = useNavigate();

  const [physioUuid, setPhysioUuid] = useState<string>('');
  const formik = useFormik({
    initialValues: {
      plan: '',
    },
    onSubmit: () => {},
  });

  const { data } = useQuery(
    ['diagnosis', physioUuid],
    () => getTherapyByPhysioUuid(physioUuid).then((res) => res.data),
    { enabled: !!physioUuid || false }
  );

  useEffect(() => {
    if (data?.physio_link) {
      navigate(`${Path.PATIENT_PROGRESS_STATS}\\${data.physio_link}`);
    }
  }, [data]);

  return (
    <div className={`${globalStyles.container} ${styles.container}`}>
      <div className={styles.text}>
        Wpisz poniżej unikalny numer id ankiety wydolności pacjenta
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.text}>
          <CustomTextField
            id="plan"
            label="Wpisz numer"
            variant="outlined"
            sx={{ margin: '0 0 10px 0' }}
            onChange={formik.handleChange}
            value={formik.values.plan}
          />
        </div>
        <div className={styles.submit}>
          <ThemeProvider theme={customButtonTheme}>
            <Button
              variant="contained"
              onClick={() => setPhysioUuid(formik?.values?.plan)}
              disabled={formik?.values?.plan?.length < 36}
            >
              Zobacz wyniki
            </Button>
          </ThemeProvider>
        </div>
      </form>
    </div>
  );
};

export default FindDiagnosisResult;
