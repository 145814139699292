import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useState } from 'react';

import { ExercisesDto } from '../../../../api/models/ExercisesDto';
import { CustomTextField } from '../../../../components';

interface AddExerciseProps {
  exercise: ExercisesDto;
  onAddExercise: any;
  onCloseModal: any;
}

const AddExercise = ({
  exercise,
  onAddExercise,
  onCloseModal,
}: AddExerciseProps) => {
  const [readOnly, setReadOnly] = useState(true);

  // const [open, setOpen] = useState(false);

  const editData = () => {
    setReadOnly(false);
  };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const formik = useFormik({
    initialValues: { ...exercise },
    onSubmit: (values) => {
      onAddExercise(values);
      onCloseModal();
    },
  });

  const addNewExercise = () => {
    formik.submitForm();
  };

  return (
    <div>
      <Dialog open onClose={onCloseModal}>
        <DialogTitle>
          <strong>
            {' '}
            {exercise.name ? exercise.name : `Ćwiczenie nr. ${exercise.id}`}
          </strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Przed dodaniem ćwiczenia możesz edytować jego parametry
          </DialogContentText>
          <CustomTextField
            autoFocus
            margin="dense"
            id="repeats"
            label="Powtórzenia"
            onChange={formik.handleChange}
            value={formik.values.repeats}
            type="number"
            variant="standard"
            InputProps={{ readOnly }}
          />
          <CustomTextField
            autoFocus
            margin="dense"
            id="minutes"
            label="Czas (w minutach)"
            onChange={formik.handleChange}
            value={formik.values.minutes}
            type="number"
            variant="standard"
            InputProps={{ readOnly }}
          />
          {/* <CustomTextField
            autoFocus
            margin="dense"
            id="intensity"
            label="Intensywność"
            onChange={formik.handleChange}
            value={formik.values.intensity}
            type="number"
            variant="standard"
            InputProps={{ readOnly }}
          /> */}
          <CustomTextField
            autoFocus
            margin="dense"
            id="description"
            label="Dodatkowe info"
            onChange={formik.handleChange}
            value={formik.values.description}
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={5}
            InputProps={{ readOnly }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Anuluj</Button>
          {readOnly && <Button onClick={editData}>Edytuj</Button>}
          <Button onClick={addNewExercise}>Dodaj</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExercise;
