import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/system';
import { Link } from 'react-router-dom';

import { Path } from '../../router/paths';
import { customButtonTheme } from '../../styles/buttons.mui';
import styles from './LinkBoxWithButton.module.scss';

interface LinkBOxWithButtonProps {
  header: string;
  title: string;
  description: string;
  path: Path;
  bgColor: string;
  color: string;
}

// TODO add props
const LinkBoxWithButton = (props: LinkBOxWithButtonProps) => {
  const { header, title, description, path, bgColor, color } = props;
  return (
    <div className={styles.container} style={{ backgroundColor: bgColor }}>
      <div className={styles.littleHeader} style={{ color }}>
        {header}
      </div>
      <div className={styles.header} style={{ color }}>
        {title}
      </div>
      <div className={styles.description} style={{ color }}>
        {description}
      </div>
      <div>
        <ThemeProvider theme={customButtonTheme}>
          <Button variant="contained" component={Link} to={path}>
            Rozpocznij
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default LinkBoxWithButton;
