/* eslint-disable jsx-a11y/no-static-element-interactions */
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';

import { ExercisesDto } from '../../../../api/models/ExercisesDto';
import {
  AddCircleIcon,
  ArrowEmptyIcon,
  ArrowFullIcon,
} from '../../../../assets/icons';
import { ExercisesByCat } from '../../ExercisesList/ExercisesList';
import AddExercise from './AddExercise';
import DialogPreview from './DialogPreview';
import styles from './ExerciseCategory.module.scss';

interface ExerciseCategoryProps {
  category: ExercisesByCat;
  onAddExercise: any;
}

const ExerciseCategory = ({
  category,
  onAddExercise,
}: ExerciseCategoryProps) => {
  const [showSublist, setShowSublist] = useState(false);
  const [newExercise, setNewExercise] = useState<ExercisesDto | undefined>();
  const [preview, setPreview] = useState<ExercisesDto | undefined>();
  const { type, exercises } = category;

  const showPreview = (exercise: ExercisesDto) => {
    setPreview(exercise);
  };

  const addExercise = (exercise: ExercisesDto) => {
    setNewExercise(exercise);
  };

  const onCloseModal = () => {
    setPreview(undefined);
  };

  const onCloseExerciseModal = () => {
    setNewExercise(undefined);
  };

  return (
    <div className={styles.box}>
      <div
        className={styles.category}
        style={showSublist ? { background: '#D7CBD0' } : {}}
        onClick={() => setShowSublist(!showSublist)}
      >
        {type}
        {showSublist ? (
          <ArrowFullIcon color="#76446B" className={styles.expandIcon} />
        ) : (
          <ArrowEmptyIcon color="#76446B" className={styles.expandIcon} />
        )}
      </div>
      {showSublist &&
        exercises.map((exercise) => (
          <div className={styles.exercise} key={exercise.id}>
            <strong>
              {' '}
              {exercise.name ? exercise.name : `Ćwiczenie nr. ${exercise.id}`}
            </strong>{' '}
            <div className={styles.icons}>
              <VisibilityIcon
                className={styles.visibilityIcon}
                onClick={() => showPreview(exercise)}
              />
              {/* <DialogPreview {...exercise} /> */}
              <div
                className={styles.addIcon}
                onClick={() => addExercise(exercise)}
              >
                <AddCircleIcon color="#168A52" />
              </div>
            </div>
          </div>
        ))}
      {preview && (
        <DialogPreview exercise={preview} onCloseModal={onCloseModal} />
      )}
      {newExercise && (
        <AddExercise
          exercise={newExercise}
          onAddExercise={onAddExercise}
          onCloseModal={onCloseExerciseModal}
        />
      )}
    </div>
  );
};

export default ExerciseCategory;
