const DoneIcon = ({ color }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="33"
      viewBox="0 0 44 33"
      fill="none"
    >
      <path
        d="M14.9444 32.3519L0.644338 17.9696C-0.214779 17.1056 -0.214779 15.7046 0.644338 14.8405L3.75554 11.7113C4.61465 10.8472 6.0077 10.8472 6.86682 11.7113L16.5 21.3998L37.1332 0.648043C37.9923 -0.216014 39.3853 -0.216014 40.2445 0.648043L43.3557 3.77722C44.2148 4.64127 44.2148 6.04224 43.3557 6.90639L18.0556 32.352C17.1964 33.216 15.8035 33.216 14.9444 32.3519Z"
        fill={color}
      />
    </svg>
  );
};

export default DoneIcon;
