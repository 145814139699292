export const pulseNorms = {
  men: {
    results: [
      {
        ageFrom: 18,
        ageTo: 25,
        norms: [
          {
            pulseFrom: 55,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 69,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 69,
            pulseTo: 73,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 73,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 82,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 26,
        ageTo: 35,
        norms: [
          {
            pulseFrom: 55,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 70,
            pulseTo: 74,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 74,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 83,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 36,
        ageTo: 45,
        norms: [
          {
            pulseFrom: 57,
            pulseTo: 62,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 62,
            pulseTo: 66,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 66,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 71,
            pulseTo: 75,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 75,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 82,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 46,
        ageTo: 55,
        norms: [
          {
            pulseFrom: 58,
            pulseTo: 63,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 63,
            pulseTo: 67,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 67,
            pulseTo: 71,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 71,
            pulseTo: 76,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 76,
            pulseTo: 83,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 83,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 56,
        ageTo: 65,
        norms: [
          {
            pulseFrom: 57,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 69,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 69,
            pulseTo: 76,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 76,
            pulseTo: 79,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 79,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 66,
        ageTo: 100,
        norms: [
          {
            pulseFrom: 55,
            pulseTo: 65,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 68,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 68,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 70,
            pulseTo: 73,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 73,
            pulseTo: 80,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 80,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
    ],
  },
  women: {
    results: [
      {
        ageFrom: 18,
        ageTo: 25,
        norms: [
          {
            pulseFrom: 55,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 69,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 69,
            pulseTo: 73,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 73,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 82,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 26,
        ageTo: 35,
        norms: [
          {
            pulseFrom: 54,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 70,
            pulseTo: 74,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 74,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 82,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 36,
        ageTo: 45,
        norms: [
          {
            pulseFrom: 56,
            pulseTo: 62,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 62,
            pulseTo: 66,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 66,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 70,
            pulseTo: 75,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 75,
            pulseTo: 82,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 82,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 46,
        ageTo: 55,
        norms: [
          {
            pulseFrom: 57,
            pulseTo: 63,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 63,
            pulseTo: 67,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 67,
            pulseTo: 71,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 71,
            pulseTo: 76,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 76,
            pulseTo: 83,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 83,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 56,
        ageTo: 65,
        norms: [
          {
            pulseFrom: 57,
            pulseTo: 61,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 61,
            pulseTo: 65,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 69,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 69,
            pulseTo: 76,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 76,
            pulseTo: 79,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 79,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
      {
        ageFrom: 66,
        ageTo: 100,
        norms: [
          {
            pulseFrom: 55,
            pulseTo: 65,
            name: 'Bardzo dobry',
            result: 'good',
          },
          {
            pulseFrom: 65,
            pulseTo: 68,
            name: 'Dobry',
            result: 'good',
          },
          {
            pulseFrom: 68,
            pulseTo: 70,
            name: 'Powyżej przeciętnej',
            result: 'good',
          },
          {
            pulseFrom: 70,
            pulseTo: 73,
            name: 'Przeciętny',
            result: 'average',
          },
          {
            pulseFrom: 73,
            pulseTo: 80,
            name: 'Poniżej przeciętnej',
            result: 'bad',
          },
          {
            pulseFrom: 80,
            pulseTo: 200,
            name: 'Słaby',
            result: 'bad',
          },
        ],
      },
    ],
  },
};

export const bmiNorms = [
  {
    name: 'Znaczna niedowaga',
    range: [0, 16],
    result: 'bad',
  },
  {
    name: 'Niedowaga',
    range: [16, 18.5],
    result: 'average',
  },
  {
    name: 'Norma',
    range: [18.5, 25],
    result: 'good',
  },
  {
    name: 'Nadwaga',
    range: [25, 30],
    result: 'average',
  },
  {
    name: 'Otyłość I stopnia',
    range: [30, 35],
    result: 'bad',
  },
  {
    name: 'Otyłość II stopnia',
    range: [35, 40],
    result: 'bad',
  },
  {
    name: 'Otyłość III stopnia',
    range: [40, 1000],
    result: 'bad',
  },
];

export const whtrNorms = {
  men: [
    {
      name: 'Znaczna niedowaga',
      range: [0, 0.35],
      result: 'bad',
    },
    {
      name: 'Niedowaga',
      range: [0.35, 0.43],
      result: 'average',
    },
    {
      name: 'Norma',
      range: [0.43, 0.52],
      result: 'good',
    },
    {
      name: 'Nadwaga',
      range: [0.52, 0.57],
      result: 'average',
    },
    {
      name: 'Znaczna nadwaga',
      range: [0.57, 0.62],
      result: 'bad',
    },
    {
      name: 'Nadwaga krytyczna',
      range: [0.62, 2],
      result: 'bad',
    },
  ],
  women: [
    {
      name: 'Znaczna niedowaga',
      range: [0, 0.35],
      result: 'bad',
    },
    {
      name: 'Niedowaga',
      range: [0.35, 0.41],
      result: 'average',
    },
    {
      name: 'Norma',
      range: [0.41, 0.49],
      result: 'good',
    },
    {
      name: 'Nadwaga',
      range: [0.49, 0.53],
      result: 'average',
    },
    {
      name: 'Znaczna nadwaga',
      range: [0.53, 0.57],
      result: 'bad',
    },
    {
      name: 'Nadwaga krytyczna',
      range: [0.57, 2],
      result: 'bad',
    },
  ],
};

export const efficiencyNorms = [
  {
    name: 'norma, sprawność funkcjonalna prawidłowa ; (małe ryzyko upadków',
    range: [0, 10],
    result: 'good',
  },
  {
    name: 'badany może samodzielnie wychodzić na zewnątrz, nie potrzebuje sprzętu pomocniczego do chodzenia, samodzielny w większości czynności dnia codziennego, wskazana pogłębiona ocena ryzyka upadków;( średnie ryzyko upadków )',
    range: [10, 19],
    result: 'average',
  },
  {
    name: `znacznie ograniczona sprawność funkcjonalna , nie może sam wychodzić na zewnątrz, zalecany sprzęt pomocniczy do chodzenia ,(duże ryzyko upadków`,
    range: [19, 200],
    result: 'bad',
  },
];

export const systolicPressureNorms = [
  {
    name: 'Niedociśnienie',
    range: [0, 100],
    result: 'bad',
  },
  {
    name: 'Optymalne',
    range: [100, 120],
    result: 'good',
  },
  {
    name: 'Prawidłowe',
    range: [120, 131],
    result: 'good',
  },
  {
    name: 'Wysokie prawidłowe',
    range: [131, 141],
    result: 'average',
  },
  {
    name: 'Nadciśnienie I stopnia',
    range: [141, 161],
    result: 'bad',
  },
  {
    name: 'Nadciśnienie II stopnia',
    range: [161, 180],
    result: 'bad',
  },
  {
    name: 'Nadciśnienie III stopnia',
    range: [180, 1000],
    result: 'bad',
  },
];

export const diastolicPressureNorms = [
  {
    name: 'Niedociśnienie',
    range: [0, 59],
    result: 'bad',
  },
  {
    name: 'Optymalne',
    range: [60, 80],
    result: 'good',
  },
  {
    name: 'Prawidłowe',
    range: [80, 86],
    result: 'good',
  },
  {
    name: 'Wysokie prawidłowe',
    range: [86, 91],
    result: 'average',
  },
  {
    name: 'Nadciśnienie I stopnia',
    range: [91, 101],
    result: 'bad',
  },
  {
    name: 'Nadciśnienie II stopnia',
    range: [101, 110],
    result: 'bad',
  },
  {
    name: 'Nadciśnienie III stopnia',
    range: [110, 1000],
    result: 'bad',
  },
];
