import { ReactNode } from 'react';
/* eslint-disable-next-line simple-import-sort/imports */
import { NavLink } from 'react-router-dom';

import { Logo } from '../../assets/images/logo';
import fe_ue from '../../assets/images/pics/layout/fe_ue.png';
import { Path } from '../../router/paths';
import { Navigation } from '../Navigation';
import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.mainBody}>
      <div className={styles.navPanel}>
        <div className={styles.logo}>
          <NavLink to={`${Path.ROOT}`} target="_self" className={styles.link}>
            <img src={Logo} alt="logo" />
          </NavLink>
        </div>
        <div className={styles.navbar}>
          <Navigation />
        </div>
      </div>

      <div className={styles.mainPanel}>
        <div className={styles.mainUe}>
          <div className={styles.mainUeBox}>
            <img src={fe_ue} alt="Loga Ue" />
            <br />
            Sfinansowano w ramach reakcji Unii na pandemię COVID-19.
          </div>
        </div>
        {children}
      </div>

      <footer className={styles.footer}>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '13px',
            marginBottom: '50px',
            fontWeight: 'bold',
          }}
        >
          Materiały edukacyjne dla pacjentów zamieszone na stronie nie zastępują
          porady fizjoterapeutycznej
          <br />
          <br />
          <a href="zdalne_konsultacje_regulamin.pdf" target="_blank">
            Regulamin
          </a>{' '}
          |{' '}
          <a href="zdalne_konsultacje_polityka_prywatnosci.pdf" target="_blank">
            Polityka Prywatności
          </a>{' '}
          |{' '}
          <a href={`${Path.GETCODE}`} target="_blank" rel="noreferrer">
            Twój kod
          </a>{' '}
          <br />
          <br />
          <a href="https://kif.info.pl" target="_blank" rel="noreferrer">
            Krajowa Izba Fizjoterapeutów
          </a>{' '}
          <br />
          <a href="mailto:serwis@kif.info.pl">serwis@kif.info.pl</a>
        </div>
        {/* <NavLink className={styles.footerLogo} to={Path.ROOT} /> */}
      </footer>
    </div>
  );
};

export default Layout;
