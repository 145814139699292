import { HeaderWithTriangles } from '../../components/HeaderWithTriangles';
import globalStyles from '../../styles/global.module.scss';
import styles from './Infopage.module.scss';

const Infopage = () => {
  const modifier = 'main';
  const title = 'O projekcie';

  return (
    <div
      className={`${globalStyles.container} ${styles.container}`}
      data-app-type={modifier}
    >
      <HeaderWithTriangles title={title} className={styles.header} />

      <div className={styles.content}>
        <p>
          Celem jest wzmocnienie kompetencji fizjoterapeutów w zakresie
          przeciwdziałania zjawisku infodemii. Zadanie obejmuje przygotowanie
          fizjoterapeutów do prowadzenia edukacji pacjentów w zakresie wdrażania
          wiedzy, postaw i zachowań właściwych dla podejmowania decyzji
          zdrowotnych w oparciu o dane i informacje naukowe oraz
          przeciwstawianie się poglądom, postawom i decyzjom opartym na
          fałszywych i szkodliwych informacjach. Zarówno w zakresie rozszerzenia
          i pogłębienia wiedzy merytorycznej, w tym w szczególności dotyczącej
          postępowania w kontekście COVID-19, jak i sposobów, metod, narzędzi
          upowszechniania tej wiedzy
          <br />
          <br />
          Niniejsza platforma służy jako jedno z działań związanych z
          problematyką związaną z Infodemią - , promując/przekazując wiedzę na
          temat, postaw i zachowań właściwych dla podejmowania decyzji
          zdrowotnych w oparciu o dane i informacje naukowe. Serwis stanowi
          mobilny system informacyjno-użytkowy. Skierowany do fizjoterapeutów
          oraz pacjentów. Podzielona na obszar informacyjny oraz obszar
          praktycznej pracy z pacjentem. Magazyn online zawierający
          skategoryzowane treści newsowe, informacyjne, publicystyczne i
          naukowe.
          <br />
          <br />
          Obszar informacyjny: treści opracowanie przez ekspertów pracujących w
          ramach zadania „fizjoterapeuta edukator – przeciwdziałanie zjawisku
          infodemii”. Także: aktualności zawodowe, informacje nt. sytuacji
          epidemiologicznej, na temat szczepień, epidemiologii zakaźnej,
          immunologii, obowiązujących przepisach sanitarnych - zwłaszcza w
          kontekście wiedzy opartej na dowodach naukowych.
          <br />
          <br />
          Obszar praktycznej pracy z pacjentem: umożliwienie prowadzenia zdalnej
          diagnostyki i terapii fizjoterapeutycznej z wykorzystaniem cyfrowych
          narzędzi udostępnianych przez sieć web. Fizjoterapeuta może
          przeprowadzić konsultację zdalną. Prowadzenie leczenia w nowatorski
          sposób z wykorzystaniem gotowych zestawów ćwiczeń prezentowanych przy
          pomocy video, zdjęć oraz opisów. W trakcie prowadzone wizyty
          fizjoterapeuta będzie miał dostęp do EBP i EBM powiązanej z bazą
          Cochrane, która jest przetworzona na potrzeby projektu Infodemia do
          codziennej praktyki fizjoterapeutycznej.
        </p>
      </div>
    </div>
  );
};

export default Infopage;
