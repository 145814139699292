export const heartAnswer = (answer: number) => {
  switch (Number(answer)) {
    case 1:
      return 'Pełna wydolność fizyczna';
    case 2:
      return 'Niewielkie ograniczenie aktywności fizycznej';
    case 3:
      return 'Średnie ograniczenie aktywności fizycznej';
    case 4:
      return 'Znaczne ograniczenie aktywności fizycznej';
    default:
      return '';
  }
};

export const dyspnoeaAnswer = (answer: number) => {
  switch (Number(answer)) {
    case 1:
      return 'brak uczucia duszności';
    case 2:
      return 'Duszność występuje jedynie podczas dużego wysiłku fizycznego';
    case 3:
      return 'Duszność występuje podczas szybkiego marszu po płaskim terenie lub wchodzenia na niewielkie wzniesienie';
    case 4:
      return 'Z powodu duszności wymuszone jest wolniejsze chodzenie w porównaniu do rówieśników, lub idąc we własnym tempie po płaskim terenie musimy się zatrzymać i nabrać tchu';
    case 5:
      return 'Po przejściu około 100 m lub po kilku minutach marszu po płaskim terenie konieczne jest zatrzymanie się aby nabrać tchu';
    case 6:
      return 'Duszność uniemożliwia opuszczenie domu lub występuje również podczas ubierania się lub rozbierania';
    default:
      return '';
  }
};

export const functionAnswer = (answer: number) => {
  switch (Number(answer)) {
    case 1:
      return 'Nie mam ograniczeń, moja sprawność jest pełna';
    case 2:
      return 'Mam nieznaczne ograniczenia';
    case 3:
      return 'Mam znaczne ograniczenia';
    case 4:
      return 'Wymagam pomocy osób trzecich';
    default:
      return '';
  }
};

export const sexAnswer = (answer: number) => {
  switch (Number(answer)) {
    case 1:
      return 'Kobieta';
    case 2:
      return 'Mężczyzna';
    default:
      return '';
  }
};
